module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":false,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-iubenda-cookie-footer/gatsby-browser.js'),
      options: {"plugins":[],"iubendaOptions":{"askConsentAtCookiePolicyUpdate":true,"enableGdpr":false,"enableUspr":true,"floatingPreferencesButtonDisplay":false,"lang":"en","siteId":"3152285","usprApplies":true,"cookiePolicyId":"49654047","privacyPolicyUrl":"https://www.iubenda.com/privacy-policy/81980202","privacyPolicyNoticeAtCollectionUrl":"https://www.iubenda.com/terms-and-conditions/81980202","banner":{"backgroundColor":"#FFFFFF","closeButtonDisplay":false,"fontSizeBody":"14px","logo":null,"linksColor":"#1425CEBA","position":"bottom","textColor":"#0000008A"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-mixpanel/gatsby-browser.js'),
      options: {"plugins":[],"apiToken":"3547c07aca1df4ddb7d369af76dc347f","enableOnDevMode":false,"pageViews":"all","mixpanelConfig":{"opt_out_tracking_by_default":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://enjoygoodhealth.com/"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Enjoy Good Health","short_name":"EGH","start_url":"/","background_color":"#FFFFFF","theme_color":"rgb(255, 255, 255)","display":"minimal-ui","icon":"/vercel/path0/static/favicon.svg","icons":[{"src":"icons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"icons/icon-70x70.png","sizes":"70x70","type":"image/png"},{"src":"icons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"icons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"icons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"icons/icon-150x150.png","sizes":"150x150","type":"image/png"},{"src":"icons/apple-touch-icon.png","sizes":"180x180","type":"image/png"},{"src":"icons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"icons/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"icons/icon-310x310.png","sizes":"310x310","type":"image/png"},{"src":"icons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"icons/icon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0a3451e959f53e54fa3659740d271313"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
