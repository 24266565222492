import { Text } from 'fatcat-ui-library/components/Atoms/Text';
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { Input } from 'fatcat-ui-library/components/Atoms/Input';
import { Button } from 'fatcat-ui-library/components/Atoms/Button';

import styled from 'styled-components';

export const SubscribeWrapper = styled(Flex)`
    border-radius: 80px;
    background: #D4CEE4;

    margin-top: 56px;
    padding: 16px 16px 16px 32px;

    ${Text} {
        color: #2A2939;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
    }

    ${props => props.theme.media.tablet} {
        margin: 56px -32px -48px -32px;
        width: calc(100% + 64px);
        border-radius: 0;
        padding: 40px 16px 40px 32px;
    }
`;

export const SubscribeInput = styled(Input)`
    margin-bottom: 0;
    border-radius: 40px;
    border-color: ${props => props.theme.color.primary};
    height: 60px;
    ~ label {
        line-height: 26px;
    }

    ~ span {
        font-size: 12px !important;
        top: 58px;
        left: 4px;
        font-weight: normal !important;
        color: #CF3E3E !important;
    }
`;

export const SubscribeButton = styled(Button)`
    position: absolute;
    right: 4px;
    top: 4px;
    padding: 16px 32px;
    height: 52px;
    line-height: 16px;
    font-size: 16px;
    min-width: 150px;

     ${props => props.theme.media.tablet} {
        position: relative;
        display: inline-block;
        width: min-content;
        margin-top: 24px;
    }
`;
