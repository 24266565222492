import React from 'react';
import { css } from 'styled-components';
import { useCart } from 'react-use-cart';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useLocation } from '@reach/router';
import { useAppContext } from 'src/context/appContext';
// Atoms
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { Button } from 'fatcat-ui-library/components/Atoms/Button';
import { Text } from 'fatcat-ui-library/components/Atoms/Text';
import { Icon } from 'fatcat-ui-library/components/Atoms/Icon';
import { CartItemsWrapper } from './Cart.atoms';

// Molecules
import CartItem from 'src/components/Molecules/CartItem/CartItem';
import { Spinner } from 'src/components/Molecules/Loaders/Spinner';
import PopupMessage from 'src/components/Molecules/PopupMessage/PopupMessage';

// Assets
import { LuX } from 'react-icons/lu';

import { checkoutCart, checkoutErrorHandler } from 'src/utils/helpers';

// Utils
import { itemsFormat, trackAnalytic } from 'src/utils/gtag';
import { useGetAvailableQuantity } from 'src/utils/hooks/useGetAvailableQuantity';
import { useMixpanel } from 'gatsby-plugin-mixpanel';
import { DetailsList } from 'src/components/Molecules/ProductView/ProductView.atoms';
import { Wrapper } from 'fatcat-ui-library/components/Atoms/Wrapper';

const Cart: React.FC = (props) => {
	const mixpanel = useMixpanel();
	const [hasError, setHasError] = React.useState(false);
	const [errorMessage, setErrorMessage] = React.useState('');
	const { items, cartTotal } = useCart();
	const { origin } = useLocation();
	const [isLoading, setIsLoading] = React.useState(false);
	const { closeCart, isCartOpen } = useAppContext();
	const { getAllProductsOutOfStockLast, getAvailableQuantity } = useGetAvailableQuantity();
	const allProducts = getAllProductsOutOfStockLast();

	const isUnavailable = (id: string) => getAvailableQuantity(id) <= 0;

	const handleCheckout = async () => {
		setIsLoading(true);
		trackAnalytic('begin_checkout', {
			items: itemsFormat(items),
		});
		mixpanel.track('View Checkout', {
			items,
		});

		checkoutCart(
			[...items],
			origin,
			() => setIsLoading(false),
			error => checkoutErrorHandler(error, allProducts, (errorMsg) => {
				setHasError(true);
				setErrorMessage(errorMsg);
			}),
		);
	};

	const clearError = (value: boolean) => {
		setHasError(value);
		setErrorMessage('');
	};

	return (
		<>
			<Flex
				position="fixed"
				borderRadius="20px 0 0 0"
				zIndex={5}
				backgroundColor="white"
				w="540px"
				top="128px"
				right="0"
				direction="column"
				justifyContent="space-between"
				transition="transform 0.3s ease-in-out"
				transform={isCartOpen ? 'translateX(0%)' : 'translateX(150%)'}
				pointerEvents={isCartOpen ? 'auto' : 'none'}
				tablet={[{
					w: '100%',
					maxW: '480px',
				}]}
				mobile={[{
					borderRadius: 'unset',
					top: '0',
				}]}
				styled={css`
					height: calc(100vh - 128px);

					@supports (height: 100dvh) {
						height: calc(100dvh - 128px);
					}

					${props => props.theme.media.mobile} {
						z-index: 10;

						height: 100vh;

						@supports (height: 100dvh) {
							height: 100dvh;
						}
					}
				`}
			>
				<Flex
					padding={['t16', 'l32']}
					direction="column"
					mobile={[{ padding: ['l16'] }]}
					overflow="auto"
					flexGrow={1}
				>
					<Flex
						paddingBottom="s16"
						justifyContent="space-between"
						alignItems="center"
						paddingRight="s32"
						mobile={[{ padding: ['r16'] }]}
					>
						<Text fontWeight="medium">{`Cart (${items.length} items)`}</Text>
						<Button
							variant="noStyle"
							aria-label="close cart"
							onClick={closeCart}
						>
							<Icon
								cursor="pointer"
								fontSize="s24"
								as={LuX}
							/>
						</Button>
					</Flex>
					<Flex
						overflowY="scroll"
						overflowX="hidden"
						paddingLeft="s8"
						direction="column"
					>
						<CartItemsWrapper as={TransitionGroup}>
							{items.map((item: any) => (
								<CSSTransition
									timeout={300}
									classNames="fade"
									key={item.id}
								>
									<CartItem product={item} unavailable={isUnavailable(item.id)} />
								</CSSTransition>
							))}
						</CartItemsWrapper>
					</Flex>
				</Flex>
				<Flex
					padding="s32"
					borderRadius="20px 0 0 0"
					boxShadow="0px -12px 24px 10px rgb(132 105 105 / 10%)"
					direction="column"
					gap="24px"
					mobile={[{ borderRadius: '20px 20px 0 0', padding: 's16', gap: '8px' }]}
					flexShrink={1}
				>
					<Flex
						justifyContent="space-between"
						alignItems="center"
						gap="24px"
					>
						<Text fontWeight="medium">
							Subtotal:
						</Text>
						<Text fontWeight="bold" fontSize="s24">
							{`$${cartTotal?.toFixed(2).replace('-0', '0')}`}
						</Text>
					</Flex>
					{cartTotal > 0 && cartTotal < 35 && cartTotal !== 0 && !items.some(item => item.mode === 'subscribe') && (
						<Wrapper
							borderRadius="20px"
							padding="s16"
							styled={css`
								background-color: #fbe362;
							`}
						>
							You are $
							{(35 - cartTotal).toFixed(2).replace('-0', '0')}
							{' '}
							away from free shipping. Add more items to your cart to qualify.
						</Wrapper>
					)}
					{(cartTotal >= 35 || items.some(item => item.mode === 'subscribe')) && (
						<Wrapper
							borderRadius="20px"
							padding="s16"
							styled={css`
								background-color: #81d87d;
							`}
						>
							You qualify for free shipping!
						</Wrapper>
					)}
					<Button
						h="46px"
						size="small"
						variant="primary"
						disabled={items.length === 0 || isLoading}
						aria-disabled={items.length === 0 ? true : undefined}
						onClick={handleCheckout}
					>
						{isLoading ? <Spinner size="1rem" /> : 'Checkout'}
					</Button>

					<DetailsList className="condensed">
						<li>Regular shipping fee of $4.99 will be added to your total at checkout</li>
						<li>Expected delivery time: 5-7 working days</li>
						<li>FREE Shipping for orders over $35</li>
						<li>Upgrade to expedited shipping at checkout</li>
					</DetailsList>
				</Flex>
			</Flex>
			<Flex
				position="fixed"
				zIndex={0}
				top="0"
				right="0"
				left="0"
				bottom="0"
				opacity={isCartOpen ? '1' : '0'}
				pointerEvents={isCartOpen ? 'auto' : 'none'}
				transition="opacity 0.3s ease-in-out"
				background="rgb(0, 0, 0, 0.2)"
				onClick={closeCart}
			/>

			{hasError && (
				<PopupMessage
					title="Product not available"
					description={errorMessage}
					setIsActive={clearError}
				/>
			)}
		</>
	);
};

export default Cart;
