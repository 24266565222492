import React, { useState } from 'react';
import { css } from 'styled-components';
import { useStaticQuery, type PageProps, graphql } from 'gatsby';
import { CartProvider } from 'react-use-cart';

// Themes
import { UIThemeProvider } from 'fatcat-ui-library/theme/theme';
import { newTheme } from '../theme';

// Atoms
import GlobalStyle from 'fatcat-ui-library/components/Atoms/GlobalStyle';
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
// import CookieConsent from 'fatcat-ui-library/components/Organisms/CookieBanner';
import { HTMLStyle } from './Layout.atoms';

// Organisms
import Header from '../components/Organisms/Header/Header';
import Footer from '../components/Organisms/Footer/Footer';
import SearchProductNavigation from '../components/Organisms/SearchProductNavigation/SearchProductNavigation';
import AppProvider from 'src/context/AppProvider';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const Index: React.FC<PageProps> = (props) => {
	const { children, location } = props;
	const [isSearchOpen, setIsSearchOpen] = useState(false);
	const appPage = [
		'/app-privacy-policy/',
		'/app-return-policy/',
		'/app-terms-and-conditions/',
	].includes(location.pathname);

	const isProductPage = location.pathname.includes('/products/') && location.pathname !== '/products/';

	const { promoBannerSection } = useStaticQuery(graphql`
        query PromoBannerQuery {
            promoBannerSection: contentfulSection(identifier: {eq: "Free shipping (Top) promo banner"}) {
				...SectionFields
			}		
        }
	`);

	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_SITE_KEY || ''}
			language="en"
		>
			<CartProvider>
				<AppProvider>
					<UIThemeProvider theme={newTheme}>
						{/* <CookieConsent /> */}
						<SearchProductNavigation isOpen={isSearchOpen} setIsOpen={setIsSearchOpen} />
						<GlobalStyle />
						<HTMLStyle />
						<Flex
							minH="100vh"
							direction="column"
							styled={css`main { flex-grow: 1; }`}
						>
							{!appPage && <Header setIsSearchOpen={setIsSearchOpen} promoBannerSection={promoBannerSection} />}
							{children}
							{!appPage && <Footer isProductPage={isProductPage} />}
						</Flex>
					</UIThemeProvider>
				</AppProvider>
			</CartProvider>
		</GoogleReCaptchaProvider>
	);
};

export default Index;
