import { Center } from 'fatcat-ui-library/components/Atoms/Center';
import styled from 'styled-components';

export const SliderWrapper = styled(Center)`
    position: relative;
	flex-direction: column;

	.swiper {
		width: 100%;
	}

	.swiper-slide {
		padding: 0 10px;
		box-sizing: border-box;
		display: flex;
	}

	.swiper-slide h3::before {
		content: "";
		display: block;
		height: 4px;
		width: 80px;
		background: ${props => props.theme.color.primary};
		margin-bottom: 16px;
	}

	.nav-slider-button:after {
		content: none;
	}

	.swiper-button-testimonial-next,
	.swiper-button-testimonial-prev {
		position: absolute;
		top: 50px;
	}

	.swiper-button-testimonial-next {
		right: -76px;
	}
	.swiper-button-testimonial-prev {
		left: -76px;
	}

	${props => props.theme.media.desktop} {
		.swiper-button-testimonial-next {
			right: 0;
		}
		.swiper-button-testimonial-prev {
			left: 0;
		}
	}

`;
