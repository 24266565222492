export const COLOR = {
	primary: '#1A6B50',
	primaryHover: '#15523D',
	textColor: '#2A2939',
	textColor2: '#777777',

	bgDark: '#D5CBBB',
	bgTexture: '#F6F3EF',
	bgLight: '#FAF8F7',

	orange: '#FDC19A',
	purple: '#D4CEE4',
	green: '#AFE0DA',
	lines: '#CEC2AB',
	lightBeige: '#F5F2EE',
	darkBeige: '#EDE6DE',
	linesLight: '#F4F1EF',

	mintGreen: '#D8E0C8',
	warning: '#CF3E3E',

	gray: '#EDEDED',
	lightGray: '#8D8D8D',
	darkGray: '#A0A3AF',

	white: '#FFFFFF',
	black: '#000000',

	bone: '#E2D5C0',

	disabledBg: '#969696',

	discountedPrice: '#FF794F',

	//* Keys that can be overwritten */
	primaryLight: '#2db98a',
	// black: '#000000',
	// dark: '#2A2939',
	// blackHigh: '#030303',
	// blackLow: '#121212',
	// blackLight: '#171717',
	// blackLighter: '#1D1D1E',
	// white10: 'rgba(255, 255, 255, 0.1)',
	// white20: 'rgba(255, 255, 255, 0.2)',
	// white50: 'rgba(255, 255, 255, 0.5)',
	// darkestGray: '#121212',
	// grey: '#989898',
	// grey200: '#DDDDDD',
	// greyLight: '#67727B',
	// darkerGrey: '#6B6B6B',
	// lightGrey: '#DFDFDF',
	// purple: '#5E1A6F',
	// purpleLight: '#755DB9',
	// darkerPurple: '#50145F',
	// darkestPurple: '#470F55',
	// yellow: '#FFB82D',
	// yellow10: 'rgba(255,184,45, 0.1)',
	// green: '#00FF00',
	// transparent: 'transparent',
};

export const LINK_COLOR = {
	primary: {
		default: COLOR.primary,
		hover: COLOR.primaryHover,
	},
	navigation: {
		default: COLOR.textColor,
		hover: COLOR.primary,
	},
	secondary: {
		default: COLOR.textColor,
		hover: COLOR.primary,
	},
	ghostly: {
		default: COLOR.primary,
		hover: COLOR.primaryHover,
	},
	underlineLight: {
		default: COLOR.textColor2,
		hover: COLOR.textColor,
	},
	withArrow: {
		default: COLOR.primary,
		hover: COLOR.primaryHover,
	},
};

export const BUTTON_COLOR = {
	primary: {
		default: COLOR.primary,
		text: COLOR.white,
		hover: COLOR.primaryHover,
		hoverText: COLOR.white,
	},
	disabled: {
		default: COLOR.disabledBg,
		text: COLOR.white,
	},
	secondary: {
		default: COLOR.primary,
		text: COLOR.primary,
		hover: COLOR.primaryHover,
		hoverText: COLOR.primaryHover,
	},
	dark: {
		default: COLOR.textColor,
		text: COLOR.white,
		hover: COLOR.primary,
		hoverText: COLOR.white,
	},
};

export const TEXT_COLOR = {
	primary: COLOR.textColor,
	secondary: COLOR.textColor2,
	linkColor: COLOR.primary,
	green: COLOR.primary,
	darkGray: COLOR.darkGray,
	warrning: COLOR.warning,
};

export const BACKGROUND_COLOR = {
	primary: COLOR.primary,
	dark: COLOR.bgDark,
	black: COLOR.black,
	texture: COLOR.bgTexture,
	light: COLOR.bgLight,
	lightBeige: COLOR.lightBeige,
	purple: COLOR.purple,
	textColor: COLOR.textColor,
};
