import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { Link } from 'fatcat-ui-library/components/Atoms/Link';
import { Wrapper, TWrapper } from 'fatcat-ui-library/components/Atoms/Wrapper';
import LogoIcon from 'assets/images/svg-inline/logo.svg';
import styled, { keyframes } from 'styled-components';

type TLogo = TWrapper;

const puls = keyframes`
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(0.5);
	}
	100% {
		transform: scale(1);
	}
`;

export const LogoWrapper = styled(Wrapper)`
	display: flex;
	align-items: center;
	.heart {
		fill:#232323;
		transform-origin: 40% 80%;
	}
	svg {
		width: 100%;
		height: auto;
	}
	:hover {
		.heart {
			transition: all 0.2s ease-in-out;
			fill: #1A6B50;
			animation: ${puls} 1.2s ease-in-out infinite;
		}
	}
`;

const Logo: React.FC<TLogo> = (props) => {
	return (
		<Link as={GatsbyLink} to="/" title="Home">
			<LogoWrapper {...props}>
				<LogoIcon />
			</LogoWrapper>
		</Link>
	);
};

export default Logo;
