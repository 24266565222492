import React from 'react';
import { css } from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';

import { GatsbyImage } from 'gatsby-plugin-image';
import { useAppContext } from 'src/context/appContext';
import { useCart } from 'react-use-cart';

// Atoms
import { Wrapper } from 'fatcat-ui-library/components/Atoms/Wrapper';
import { Text } from 'fatcat-ui-library/components/Atoms/Text';
import { Center } from 'fatcat-ui-library/components/Atoms/Center';
import {
	ProductCardWrapper,
	ProductCardContentWrapper,
	NewWrapper,
} from './ProductCardV2.atoms';

// Helpers
import { formatPrice, formatProductForCart, getCartItemQuantity } from 'src/utils/helpers';

// Types
import { TProductGatsbyImage } from 'src/components/Sections/Product';

// Utils

import { itemsFormat, trackAnalytic, trackFB } from 'src/utils/gtag';
import { useMixpanel } from 'gatsby-plugin-mixpanel';
import { Button } from 'fatcat-ui-library/components/Atoms/Button';
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import Ratings from 'fatcat-ui-library/components/Molecules/Ratings';

type TProductCard = {
	filter?: {
		name: string;
		url: string;
		healthConcern: string;
	}
	haveAddButton: boolean
	inSwiper: boolean
} & TProductGatsbyImage;

const ProductCard: React.FC<TProductCard> = (props) => {
	const mixpanel = useMixpanel();
	const { getAvailableQuantity, toggleCart } = useAppContext();
	const { items, addItem } = useCart();
	const {
		filter,
		id,
		images,
		name,
		subTitle,
		slug,
		variants,
		isNew,
		haveAddButton,
		inSwiper,
		...restProps
	} = props;
	const product = {
		...restProps,
		id,
		images,
		name,
		subTitle,
		slug,
		variants,
	};
	const {
		id: variantId,
	} = variants[0];
	const formattedPrice = formatPrice(props);
	const currentInCart = getCartItemQuantity(items, variantId);
	const isOutOfStock = getAvailableQuantity(variantId) - currentInCart <= 0;
	const imagesToShow = images[0];

	const handleAddToCart = (e) => {
		e.preventDefault();
		trackAnalytic('add_to_cart', {
			items: itemsFormat(items),
			item_added: {
				id: variantId,
				name,
			},
		});
		trackFB('AddToCart', {
			content_type: 'product',
			contents: [
				{
					id: variantId,
					name,
					quantity: 1,
				},
			],
			value: variants[0].discountedPrice.toFixed(2),
			currency: 'USD',
		});
		mixpanel.track('Add to Cart', {
			'Product Name': name,
			'Product ID': variantId,
			'Product Price': product.variants[0].discountedPrice.toFixed(2),
			'Product Quantity': 1,
		});
		addItem(formatProductForCart(product), 1);
		toggleCart();
	};

	return (
		<ProductCardWrapper
			as={GatsbyLink}
			inSwiper={inSwiper}
			state={{ filter }}
			onClick={() => trackAnalytic('view_item', {
				item_id: id,
				item_name: name,
			})}
			to={`/products/${slug}`}
			transition="all 0.2s ease-in-out"
			hover={[{ boxShadow: '0 0 20px 0px rgba(212,206,228)', zoom: '1.01' }]}
		>
			<ProductCardContentWrapper>
				{isNew && (
					<NewWrapper>
						<Text lineHeight="s100" padding={['t4', 'b4', 'l12', 'r12']} backgroundColor="purple" borderRadius="100px" textColor="white">New</Text>
					</NewWrapper>
				)}
				<Wrapper>
					<GatsbyImage image={imagesToShow.childImageSharp.gatsbyImageData} alt={name} key={imagesToShow.id} />
				</Wrapper>
				<Center
					padding={['t16', 'r20', 'l20']}
					direction="column"
					w="auto"
					styled={css`
						flex: 1;
					`}
				>
					<Text
						fontWeight="bold"
						textAlign="center"
						display="block"
						fontSize="s18"
						styled={css`
							display: -webkit-box;
							-webkit-box-orient: vertical;
							overflow: hidden;
							text-overflow: ellipsis;
						`}
					>
						{name}
					</Text>
					<Text
						fontSize="s16"
						textAlign="center"
						display="flex"
						styled={css`
							flex: 1;
						`}
					>
						{subTitle}
					</Text>
					<Flex direction="row" alignItems="center" justifyContent="center" marginTop="s8">
						{product.reviews.count > 0 && (
							<>
								<Ratings
									rating={product.reviews.rating}
									size="12px"
									maxRating={5}
									gap="4px"
								/>

								<Text marginRight="s8" marginLeft="s8">
									{product.reviews.rating.toFixed(1)}
								</Text>

								(
								{product.reviews.count}
								)
							</>
						)}

						{product.reviews.count === 0 && 'No reviews'}

					</Flex>
					<Text
						fontWeight="semiBold"
						fontSize="s24"
						padding={['t8', 'b8']}
					>
						{formattedPrice}
					</Text>
				</Center>
			</ProductCardContentWrapper>
			{haveAddButton && (
				<Center>
					{isOutOfStock ? (
						<Button
							paddingBottom="s0"
							paddingTop="s0"
							h="46px"
							w="fit-content"
							disabled
							size="small"
						>
							Out of stock
						</Button>
					) : (
						<Flex
							w="fit-content"
							position="relative"
						>
							<Button
								paddingBottom="s0"
								paddingTop="s0"
								h="46px"
								w="fit-content"
								size="small"
								variant="primary"
								onClick={handleAddToCart}
								fontWeight="semiBold"
							>
								Add to cart
							</Button>
						</Flex>

					)}
				</Center>
			)}
		</ProductCardWrapper>
	);
};

export default ProductCard;

ProductCard.defaultProps = {
	filter: undefined,
};
