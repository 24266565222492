import styled, { css } from 'styled-components';
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';

export const DropdownItemsContainer = styled(Flex)<{firstItem: boolean}>`
	position: absolute;
	width: auto;
	bottom: 0;
	${props => props.firstItem ? css`
		left: 0;
		transform: translate(-25%, 100%);
	` : css`
		left: 50%;
		transform: translate(-50%, 100%);
	`}
	visibility: hidden;
	opacity: 0;
	padding-top: 24px;
	transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
`;

export const DropdownItemsWrapper = styled(Flex)`
	position: relative;
	margin-top: 8px;
	background-color: ${props => props.theme.color.white};
	min-width: 200px;
	padding: 24px 32px;
	border-radius: 20px;
	box-shadow: 0px 4px 20px 0 rgb(68, 40, 40, 0.08);
`;

export const DropdownWrapper = styled.div<{closeDropdown:boolean}>`
	position: relative;

	a {
		white-space: nowrap;
	}
	span, svg {
		transition: all 0.2s ease-in-out;
	}

	&:hover {

		& > div {
			color: ${props => props.theme.linkColor.navigation.hover};
		}

		& > div > * {
			color: ${props => props.theme.linkColor.navigation.hover};
		}
		${DropdownItemsContainer} {
			visibility: ${props => props.closeDropdown ? 'hidden' : 'visible'};
			opacity: ${props => props.closeDropdown ? 0 : 1};
		}
	}
`;
