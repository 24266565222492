import React, { useMemo } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useCart } from 'react-use-cart';

import { getCartItemQuantity, TFormattedCartItem } from 'src/utils/helpers';

// Organisms
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { Button } from 'fatcat-ui-library/components/Atoms/Button';
import { Text } from 'fatcat-ui-library/components/Atoms/Text';
import { Center } from 'fatcat-ui-library/components/Atoms/Center';
import { Icon } from 'fatcat-ui-library/components/Atoms/Icon';
import Counter from '../Counter';
import { useAppContext } from 'src/context/appContext';

import { LuTrash2, LuX } from 'react-icons/lu';

// Utils
import { itemsFormat, trackAnalytic } from 'src/utils/gtag';
import { useMixpanel } from 'gatsby-plugin-mixpanel';
import { css, useTheme } from 'styled-components';

const CartItem: React.FC<{ product: TFormattedCartItem } & {unavailable: boolean}> = (props) => {
	const mixpanel = useMixpanel();
	const { product, unavailable } = props;
	const { removeItem, updateItemQuantity, items } = useCart();
	const image = product.images[0];
	const variantId = product.id;
	const currentInCart = getCartItemQuantity(items, variantId);
	const formattedPrice = `$${product.price.toFixed(2)}`;
	const { getAvailableQuantity } = useAppContext();
	const availableQuantity = useMemo(() => getAvailableQuantity(variantId) - currentInCart, [currentInCart, getAvailableQuantity, variantId]);
	const incrementDisabled = availableQuantity <= 0;
	const theme = useTheme();
	const increment = () => {
		if (product.quantity > 0) {
			updateItemQuantity(product.id, product.quantity + 1);
		}
	};

	const decrement = () => {
		if (product.quantity > 1) {
			updateItemQuantity(product.id, product.quantity - 1);
		}
	};

	const handleRemoveItem = () => {
		trackAnalytic('remove_from_cart', {
			items: itemsFormat(items),
			product_id: product.id,
			product_name: product.name,
		});
		removeItem(product.id);
		mixpanel.track('Remove from cart', {
			'Product Name': product.name,
			'Product ID': product.id,
		});
	};
	return (
		<Flex
			direction="column"
			flexGrow={1}
			gap="8px"
			borderBottom={`1px solid ${theme.color.linesLight}`}
			marginBottom="s8"
			paddingBottom="s16"
			styled={css`
				&:last-child {
					border-bottom: none;
				}
			`}
		>
			<Flex>

				<Flex
					alignItems="flex-start"
					gap="24px"
					paddingY="s16"
					justifyContent="flex-start"
				>
					<Center
						minW="120px"
						w="fit-content"
						h="140px"
						borderRadius="4px"
						transform="translateZ(0)"
						overflow="hidden"
						boxShadow="0px 0px 12px 0px rgba(79,72,72, 0.1)"
						mobile={[{ minW: '80px', h: '100px' }]}
					>
						<Flex
							w="100px"
							h="100px"
							mobile={[{ w: '60px', h: '60px' }]}
						>
							<GatsbyImage image={image.childImageSharp.gatsbyImageData} alt={product.name} />
						</Flex>
					</Center>
					<Flex
						direction="column"
						flexGrow={1}
						gap="8px"
					>
						<Flex
							justifyContent="space-between"
							alignItems="flex-start"
						>
							<Text fontWeight="bold" fontSize="s18">{product.name}</Text>
							<Button
								variant="noStyle"
								onClick={handleRemoveItem}
								textAlign="right"
								marginLeft="s16"
							>
								<Icon
									cursor="pointer"
									as={LuTrash2}
									fontSize="s16"
								/>
							</Button>
						</Flex>
						<Text fontWeight="normal" fontSize="s14">{product.subTitle}</Text>
						{unavailable && (
							<Flex
								alignItems="center"
								justifyContent="flex-end"
							>
								<Text
									fontWeight="medium"
									textColor="warning"
									fontSize="s16"
									marginRight="s8"
									whiteSpace="nowrap"
								>
									Out of stock
								</Text>
							</Flex>
						)}
						{!unavailable && (
							<>
								<Flex
									direction="row"
									gap="8px"
									justifyContent="space-between"
									marginTop="s8"
									mobile={[{ display: 'none' }]}
								>

									<Counter
										count={product.quantity || 1}
										increment={increment}
										decrement={decrement}
										incrementDisabled={incrementDisabled}
										disabled={unavailable}
										setCount={(value) => {
											if (value > 0 && value <= availableQuantity) {
												updateItemQuantity(product.id, value);
											}
										}}
									/>

									<Flex
										flexGrow={1}
										justifyContent="space-between"
										alignItems="center"
									>
										<Text
											fontSize="s18"
											styled={css`
												line-height: normal;
												svg {
													padding-top: 4px;
												}
											`}
										>
											<LuX />
											{' '}
											<Text fontWeight="semiBold">{formattedPrice}</Text>
										</Text>
									</Flex>
								</Flex>
								{product.mode === 'subscribe' && (
									<Flex
										flexGrow={1}
										justifyContent="space-between"
										alignItems="center"
										mobile={[{ display: 'none' }]}
									>
										<Text
											fontSize="s14"
											styled={css`
												color: ${theme.color.darkGray};
											`}
										>
											Delivered every
											{' '}
											{product.interval}
											{' '}
											days
										</Text>
									</Flex>
								)}
							</>
						)}
					</Flex>

				</Flex>
			</Flex>
			{!unavailable && (
				<>
					<Flex
						direction="row"
						gap="8px"
						justifyContent="space-between"
						display="none"
						mobile={[{ display: 'flex' }]}
					>
						<Counter
							count={product.quantity || 1}
							increment={increment}
							decrement={decrement}
							incrementDisabled={incrementDisabled}
							disabled={unavailable}
							setCount={(value) => {
								if (value > 0 && value <= availableQuantity) {
									updateItemQuantity(product.id, value);
								}
							}}
						/>

						<Flex
							flexGrow={1}
							justifyContent="space-between"
							alignItems="center"
						>
							<Text
								fontSize="s18"
								styled={css`
									line-height: normal;
									svg {
										padding-top: 4px;
									}
								`}
							>
								<LuX />
								{' '}
								<Text fontWeight="semiBold">{formattedPrice}</Text>
							</Text>
						</Flex>

					</Flex>
					{product.mode === 'subscribe' && (
						<Flex
							flexGrow={1}
							justifyContent="space-between"
							alignItems="center"
							display="none"
							mobile={[{ display: 'flex' }]}
						>
							<Text
								fontSize="s14"
								styled={css`
												color: ${theme.color.darkGray};
											`}
							>
								Delivered every
								{' '}
								{product.interval}
								{' '}
								days
							</Text>
						</Flex>
					)}
				</>
			)}

		</Flex>
	);
};

export default CartItem;
