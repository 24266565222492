import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import styled from 'styled-components';
import { EmbedWrapper } from '.';

export const EmbedVideoWrapper = styled(Flex)<EmbedWrapper>`
    position: relative;
    width: ${props => props.fluid ? '100%' : 'fit-content'};
    max-width: 795px;

    &:hover .play-button {
        transform: scale(1.1);
    }
`;

export const ThumbnailWrapper = styled.button<{ isPlaying: boolean }>`
    overflow: hidden;
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    position: relative;
    transition: all 0.3s ease-in-out;
    transform: ${props => props.isPlaying ? 'translateY(-100%)' : 'translateY(0)'};
    opacity: ${props => props.isPlaying ? 0 : 1};
    z-index: 4;
    cursor: pointer;

    .play-button {
		transition: transform 0.2s ease-in-out;
        width: auto;
    }


    .gatsby-image-wrapper {
        height: 236px;
        width: 100%;
        height: 100%;
    }

    & .gatsby-image-wrapper img  {
        position: absolute;
        z-index:4;
        width: 100%;
        height: 100%;
        filter: brightness(0.6);
    }
`;
