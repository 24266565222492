import React, { ReactElement } from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import { Wrapper, TWrapper } from 'fatcat-ui-library/components/Atoms/Wrapper';

type TVideoPlayer =
	{
		src: string
		loop?: boolean
		muted?: boolean
		playsinline?: boolean
		playing?: boolean
		fallback?: ReactElement | null
	}
	& TWrapper;

const VideoWrapper = styled(Wrapper) <TWrapper>`
		.react-player {
			width: 100% !important;
			height: 100% !important;
			position: absolute;
			z-index: 1;

			video {
				object-fit: cover;
			}
		}
	`;

const VideoPlayer: React.FC<TVideoPlayer> = (props) => {
	const {
		src,
		loop,
		muted,
		playsinline,
		playing,
		fallback,
		...restProps
	} = props;

	const videoProps = {
		url: src,
		loop,
		muted,
		playing,
		playsinline,
	};
	return (
		<VideoWrapper
			h="100%"
			position="relative"
			{...restProps}
		>
			<ReactPlayer
				className="react-player"
				{...videoProps}
			/>
			{fallback}
		</VideoWrapper>
	);
};

VideoPlayer.defaultProps = {
	loop: false,
	muted: false,
	playsinline: false,
	playing: true,
	fallback: null,
};

export default VideoPlayer;
