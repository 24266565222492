import styled from 'styled-components';

// simple spinner component
export const Spinner = styled.div<{size: string}>`
    display: inline-block;
	position: relative;
    width: ${props => props.size};
    height: ${props => props.size};

    &:after {
        content: "";
        display: block;
		position: absolute;
        width: ${props => props.size};
        height: ${props => props.size};
        border-radius: 50%;
        border: 6px solid #fff;
        border-color: #fff transparent #fff transparent;
        animation: lds-dual-ring 1.2s linear infinite;
    }
    @keyframes lds-dual-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

export const SpinnerCircle = styled.div<{size: string}>`
    display: inline-block;
    position: relative;
    width: ${props => props.size};
    height: ${props => props.size};
    border: 2px solid rgba(255,255,255,.5);
    border-radius: 50%;
    &:after {
        top: -2px;
        left: -2px;
        content: "";
        display: block;
        position: absolute;
        width: ${props => props.size};
        height: ${props => props.size};
        border-radius: 50%;
        border: 2px solid #fff;
        border-color: transparent transparent #fff transparent;
        animation: ring 1.2s linear infinite;
    }

    @keyframes ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;
