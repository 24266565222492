import React, { useMemo, useState } from 'react';

// Types
import { TContentfulComponentList } from '../../utils/types';

// Atoms
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { Text } from 'fatcat-ui-library/components/Atoms/Text';
import { Center } from 'fatcat-ui-library/components/Atoms/Center';
import { SubscribeButton, SubscribeInput, SubscribeWrapper } from './newsletter.atoms';

import NewsletterCard from 'src/components/Molecules/Cards/NewsletterCard';
import { SpinnerCircle } from 'src/components/Molecules/Loaders/Spinner';

// Local managers
import { graphql, useStaticQuery } from 'gatsby';

import ContentfulLink from '../ContentfulLink';
import { LuCheck } from 'react-icons/lu';

const ContentfulComponentListItemsNewsletter = (props: TContentfulComponentList) => {
	const {
		direction,
		alignItems,
		justifyContent,
		gap,
		wrap,
	} = props;

	const [currentEmail, setCurrentEmail] = useState<string>('');
	const [subscribeStatus, setSubscribeStatus] = useState<string>('');
	const [subscribeError, setSubscribeError] = useState(null);

	const subscribeHandler = async (email:string) => {
		setSubscribeStatus('loading');
		setSubscribeError(null);
		try {
			await fetch('/api/newsletter-subscribe', {
				method: 'POST',
				body: JSON.stringify({
					email,
				}),

			})
				.then(async (response) => {
					if (response.ok) {
						return response.text();
					}
					throw new Error(await response.text());
				});
			setSubscribeStatus('success');
		} catch (error:any) {
			setSubscribeStatus('error');
			setSubscribeError(error.message || 'Something went wrong');
		}
	};

	const rawData = useStaticQuery(graphql`
		query NewsletterQuery {
			allNewsletterPosts(
				limit: 2,
				sort: {order: DESC, fields: publish_date},
			) {
				nodes {
					...NewsletterPostFields
				}
			}
			allFile(filter: {name: {regex: "/newsletter-thumbnail/" }}) {
				nodes {
					id
					name
					childImageSharp {
						gatsbyImageData
					}
				}
			}
		}
	`);

	const data = useMemo(
		() => {
			return {
				allNewsletterPosts: {
					nodes: rawData.allNewsletterPosts.nodes.map((np) => {
						return {
							...np,
							thumbnail: rawData.allFile.nodes.find(f => f.name === `newsletter-thumbnail-${np.id}`)?.childImageSharp,
						};
					}),
				},
			};
		},
		[rawData.allFile, rawData.allNewsletterPosts]
	);

	const renderList = () => {
		const flexWrap: any = {};

		if (wrap && wrap !== 'always') {
			flexWrap[wrap] = ['wrap'];
		}

		return (
			<Flex
				direction="column"
			>
				<Flex
					direction={direction}
					alignItems={alignItems}
					justifyContent={justifyContent}
					gap={gap}
					{...flexWrap}
					wrap={wrap === 'always'}
				>
					{(data?.allNewsletterPosts?.nodes || []).map((card, index) => (
						<NewsletterCard
							key={card.slug}
							{...card}
						/>
					))}
				</Flex>

				<Center marginTop="s32">
					<ContentfulLink
						page={
							{
								slug: 'newsletter',
								__typename: 'ContentfulPage',
							}
						}
						title="See all posts"
						linkVariant="withArrow"
						linkColor="primary"
						url=""
						openInNewTab={false}
					/>
				</Center>
				<SubscribeWrapper>
					<Flex direction="row" justifyContent="center" gap="s24" position="relative" tablet={['column', { alignItems: 'center' }]}>
						<Flex alignItems="center" tablet={[{ justifyContent: 'center' }, { paddingBottom: 's24' }]}>
							<Text>
								Subscribe to our newsletter
							</Text>
						</Flex>
						<SubscribeInput
							placeholder="Enter your email"
							errorMsg={subscribeError || undefined}
							onChange={e => setCurrentEmail(e.target.value)}
							onKeyUp={(e) => {
								if (e.key === 'Enter') {
									subscribeHandler(currentEmail);
								}
							}}
						/>
						<SubscribeButton
							onClick={() => subscribeHandler(currentEmail)}
						>
							{subscribeStatus === 'loading' && <SpinnerCircle size="1rem" />}
							{subscribeStatus === 'success' && <LuCheck />}
							{(subscribeStatus === 'error' || subscribeStatus === '') && 'Subscribe'}

						</SubscribeButton>
					</Flex>
				</SubscribeWrapper>
			</Flex>
		);
	};
	return renderList();
};

export default ContentfulComponentListItemsNewsletter;
