import styled from 'styled-components';

export const PopupMessageAnimation = styled.div`
  width: auto;
  height: auto;
  animation-name: animation;

  @keyframes animation {
    from {opacity: 0;}
    to {opacity: 1;}
  }
`;
