import React, { useState } from 'react';
import { css, useTheme } from 'styled-components';
// Types
import { TSectionHeroVideo } from '../utils/types';

// Contentful
import VideoPlayer from 'src/components/Molecules/VideoPlayer/VideoPlayer';
import { Wrapper } from 'fatcat-ui-library/components/Atoms/Wrapper';
import { Center } from 'fatcat-ui-library/components/Atoms/Center';
import Section from 'fatcat-ui-library/components/Molecules/Section';
import { GatsbyImage } from 'gatsby-plugin-image';
import SectionRichText from './RichText/SectionRichText';
import { Waypoint } from 'react-waypoint';

const SectionHeroVideoBuilder: React.FC<TSectionHeroVideo> = (props) => {
	const theme = useTheme();
	const [animated, setAnimated] = useState(false);
	const {
		desktopVideo,
		tabletVideo,
		mobileVideo,
		heroVideoContent,
		descriptionTextColor,
	} = props;
	return (
		<Wrapper
			position="relative"
		>
			<VideoPlayer
				src={desktopVideo.videoFile.file.url}
				muted
				playing
				loop
				hide="largeTablet"
				fallback={(
					<GatsbyImage image={desktopVideo.videoPlaceholder.gatsbyImageData} alt="" objectFit="cover" />
				)}
			/>
			<VideoPlayer
				src={tabletVideo.videoFile.file.url}
				muted
				playing
				loop
				show="largeTablet"
				hide="mobile"
				fallback={(
					<GatsbyImage image={tabletVideo.videoPlaceholder.gatsbyImageData} alt="" objectFit="cover" />
				)}
			/>
			<VideoPlayer
				src={mobileVideo.videoFile.file.url}
				muted
				playing
				loop
				show="mobile"
				fallback={(
					<GatsbyImage image={mobileVideo.videoPlaceholder.gatsbyImageData} alt="" objectFit="cover" />
				)}
			/>
			<Waypoint
				onEnter={() => {
					setAnimated(true);
				}}
			>
				<Wrapper
					position="absolute"
					top="0"
					right="0"
					left="0"
					bottom="0"
					zIndex={1}
				>
					<Section styled="height: 100%;" backgroundGradient="linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.56) 100%)">
						<Center
							direction="column"
							animation={animated ? ['fadeInFromBottom', '1s ease-in-out forwards'] : undefined}
							opacity="0"
							h="100%"
							styled={css`
							p {
								color: ${theme.textColor[descriptionTextColor || 'primary']};
								margin-bottom: 40px;
							}
							a {
								width: fit-content;
								display: block;
							}
						`}
						>
							{SectionRichText(heroVideoContent)}
						</Center>
					</Section>
				</Wrapper>
			</Waypoint>
		</Wrapper>
	);
};

export default SectionHeroVideoBuilder;
