import { css } from 'styled-components';

export const TEXT_VARIANT = {
	h2: css`
		${props => props.theme.textSize.h2};
		font-weight: ${props => props.theme.fontWeight.bold};

		${props => props.theme.media.tablet} {
			${props => props.theme.textSize.h2Tablet};
		}
	`,
	h4: css`
		${props => props.theme.textSize.h4};
		font-weight: ${props => props.theme.fontWeight.semiBold};

		${props => props.theme.media.tablet} {
			${props => props.theme.textSize.h4Tablet};
		}
	`,
	h5: css`
		${props => props.theme.textSize.h5};
		font-weight: ${props => props.theme.fontWeight.medium};
	`,
	h6: css`
		${props => props.theme.textSize.h6};
		font-weight: ${props => props.theme.fontWeight.medium};
	`,
	paragraph: {
		small: css`
			${props => props.theme.textSize.paragraphSmall};
			font-weight: ${props => props.theme.fontWeight.medium};
		`,
		large: css`
			${props => props.theme.textSize.paragraph};
			font-weight: ${props => props.theme.fontWeight.medium};

			${props => props.theme.media.tablet} {
				${props => props.theme.textSize.paragraphTablet};
			}
		`,
	},
};
