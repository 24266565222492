// eslint-disable-file no-restricted-globals

import { Item } from 'react-use-cart';

// eslint-disable-next-line max-len
export type GoogleActions = 'add_to_cart'
	| 'begin_checkout'
	| 'checkout_progress'
	| 'conversion'
	| 'cta-button-click'
	| 'purchase'
	| 'remove_from_cart'
	| 'select_item'
	| 'share'
	| 'symptom_selected'
	| 'video_played'
	| 'view_item'
	| 'view_item_list';

export type FBAction = 'AddPaymentInfo'
	| 'AddToCart'
	| 'CompleteRegistration'
	| 'Contact'
	| 'InitiateCheckout'
	| 'Lead'
	| 'Purchase'
	| 'Search'
	| 'ViewContent';

function gtagEvent(
	action: GoogleActions,
	value: any
): void {
	// @ts-ignore
	if (typeof window !== 'undefined' && typeof window.gtag === 'function') {
		// @ts-ignore
		window.gtag('event', action, value);
	}
}

export function trackAnalytic(
	action: GoogleActions,
	value: any
): void {
	gtagEvent(action, {
		location: getUrl(),
		...value,
	});
}

function getUrl() {
	if (typeof window !== 'undefined') {
		return window.location.href;
	}
	return '';
}

export function itemsFormat(items: Item[]) {
	return items.map(prod => ({
		id: prod.id,
		name: prod.name,
		quantity: prod.quantity,
		price: prod.price,
	}));
}

export const trackAction = (actionText: string, actionUrl: string) => {
	trackAnalytic('cta-button-click', {
		actionText,
		actionUrl,
	});
};

export const trackFB = (action: FBAction, value: any) => {
	// @ts-ignore
	if (typeof window !== 'undefined' && typeof window.fbq === 'function') {
		// @ts-ignore
		window.fbq('track', action, value);
	}
};
