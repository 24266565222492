import React, { useState } from 'react';
import { useGetAvailableQuantity } from 'src/utils/hooks/useGetAvailableQuantity';
import { AppProvider as AppContextProvider } from 'src/context/appContext';
import { useMixpanel } from 'gatsby-plugin-mixpanel';

const AppProvider: React.FC<{ children: React.ReactNode }> = (props) => {
	const mixpanel = useMixpanel();
	const { children } = props;
	const [isCartOpen, setIsCartOpen] = useState(false);
	const [isSearchOpen, setIsSearchOpen] = useState(false);
	const [navOpen, setNavOpen] = useState(false);
	const [itemAddedToCart, setItemAddedToCart] = useState('');
	const { getAvailableQuantity } = useGetAvailableQuantity();

	const closeCart = () => {
		setIsCartOpen(false);
	};

	const openCart = () => {
		mixpanel.track('Cart Viewed');
		setIsCartOpen(true);
	};

	const toggleCart = () => {
		if (!isCartOpen) {
			mixpanel.track('Cart Viewed');
		}
		setIsCartOpen(prev => !prev);
	};

	const clearItemAddedToCart = () => {
		setItemAddedToCart('');
	};

	const context = {
		isCartOpen,
		openCart,
		closeCart,
		toggleCart,
		getAvailableQuantity,
		itemAddedToCart,
		clearItemAddedToCart,
		isSearchOpen,
		setIsSearchOpen,
		navOpen,
		setNavOpen,
	};

	return (
		<AppContextProvider value={context}>
			{children}
		</AppContextProvider>
	);
};

export default AppProvider;
