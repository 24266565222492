import React, { useState, useId } from 'react';
import { DefaultTheme, CSSProp } from 'styled-components';

// Icons
import { LuPlusCircle, LuMinusCircle } from 'react-icons/lu';

// Atoms
import { Paragraph } from 'fatcat-ui-library/components/Atoms/Paragraph/Paragraph';
import {
	AccordionTitle,
	Plus,
	AccordionContent,
	AccordionWrapper,
	Arrow,
	AccordionCircles,
	AccordionLines,
} from './Accordion.atoms';
import { Heading } from 'fatcat-ui-library/components/Atoms/Heading';

export type TAccordion = {
	titleAs: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
	title: string | React.ReactElement
	titleColor?: keyof DefaultTheme['textColor']
	content?: string | React.ReactElement
	subtitle?: string | React.ReactElement
	contentSize?: 'small' | 'large'
	contentColor?: keyof DefaultTheme['textColor']
	useArrow?: boolean
	styled?: CSSProp
	isTimeline?: boolean
	hoverColor: keyof DefaultTheme['color']
	hideLine?: boolean
}

const Accordion: React.FC<TAccordion> = (props) => {
	const {
		titleAs,
		title,
		subtitle,
		titleColor,
		content,
		contentSize,
		contentColor,
		useArrow,
		styled,
		isTimeline,
		hoverColor,
		hideLine,
	} = props;
	const id = useId();
	const [isOpen, setIsOpen] = useState(false);
	return (
		<AccordionWrapper styled={styled} margin={['b16']} maxW={isTimeline ? 'calc(100% - 64px)' : ''} marginLeft={isTimeline ? 's64' : 's0'} className="accordion-item">
			{isTimeline && !hideLine && <AccordionLines className="accordion-line" />}
			<AccordionTitle
				as={titleAs}
				textColor={titleColor}
				isOpen={isOpen}
				hoverColor={hoverColor}
			>
				{isTimeline && <AccordionCircles />}
				<button
					id={`accordion-title-${id}`}
					aria-controls={`accordion-content-${id}`}
					type="button"
					aria-expanded={isOpen}
					onClick={() => setIsOpen(!isOpen)}
				>
					<Heading as="h4">{title}</Heading>
					{useArrow ? (
						<Arrow
							isOpen={isOpen}
							xmlns="http://www.w3.org/2000/svg"
							width="9"
							height="6"
							viewBox="0 0 9 6"
							fill="#2A2939"
							focusable="false"
						>
							<path
								d="M7.97267 0.431484C8.20411 0.188471 8.57935 0.188471 8.81079 0.431484C9.04224 0.674497 9.04224 1.0685 8.81079
								 1.31151L5.25494 5.04516C5.03058 5.28074 4.66932 5.28898 4.43542 5.06385L0.879566 1.64135C0.63829 1.40912
								  0.621986 1.01549 0.843155 0.762149C1.06432 0.508809 1.43921 0.491695 1.68049 0.723923L4.81805 3.74383L7.97267 0.431484Z"
							/>
						</Arrow>
					) : isOpen ? <Plus as={LuMinusCircle} /> : <Plus as={LuPlusCircle} />}
				</button>
			</AccordionTitle>
			{subtitle &&
				(
					<Paragraph
						fontSize="s14"
						padding={['r8', 'l16', 't16']}
						maxW="800px"
						paddingBottom={!isOpen ? 's16' : 's0'}
						mobile={[{
							padding: ['r8', 'l8', 't8'],
						}]}
					>
						{subtitle}
					</Paragraph>
				)}
			<AccordionContent
				id={`accordion-content-${id}`}
				aria-labelledby={`accordion-title-${id}`}
				isOpen={isOpen}
			>
				<Paragraph
					padding={['r8', 'l16', 'b16']}
					textColor={contentColor}
					size={contentSize}
					paddingTop={!subtitle && isOpen ? 's16' : 's0'}
					mobile={[{
						padding: ['r8', 'l8', 't8'],
					}]}
				>
					{content}
				</Paragraph>
			</AccordionContent>
		</AccordionWrapper>
	);
};

Accordion.defaultProps = {
	useArrow: false,
	titleColor: 'primary',
	contentColor: 'primary',
	contentSize: 'large',
	styled: undefined,
	isTimeline: false,
	subtitle: undefined,
	content: undefined,
	hideLine: false,
};

export default Accordion;
