import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';

// Atoms
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import HealthConcernItem from './HealthConcernItem';
import { TBrowseHealthConcerns } from 'src/utils/types';

// Assets
import { css } from 'styled-components';
import { Grid } from 'fatcat-ui-library/components/Atoms/Grid';
import ContentfulLink from '../ContentfulLink';
import { Button } from 'fatcat-ui-library/components/Atoms/Button';

// Utils
import { trackAction } from 'src/utils/gtag';
import { Icon } from 'fatcat-ui-library/components/Atoms/Icon';
import { LuChevronLeft, LuChevronRight } from 'react-icons/lu';

const ContentfulHealthConcerns: React.FC<TBrowseHealthConcerns> = (props) => {
	const { healthConcerns, link } = props;
	return (
		<Flex
			direction="column"
			paddingTop="s24"
			alignItems="center"
			position="relative"
			largeTablet={['clearTopSideSpacing']}
			styled={css`
				.swiper-button-disabled {
					cursor: auto;
					opacity: 0.35;
					pointer-events: none;
				}
			`}
		>
			<Flex hide="largeTablet" justifyContent="space-between">
				<Button
					position="relative"
					className="swiper-button-prev-hc"
					aria-labelledby="Previous slide"
					variant="noStyle"
					h="fit-content"
					marginTop="s0"
					top="82px"
					hover={[{ opacity: '0.7' }]}
				>
					<Icon as={LuChevronLeft} w="25px" h="auto" />
				</Button>
				<Flex
					position="relative"
					justifyContent="center"
					styled={css`
						width: calc(100% - 120px);
					`}
				>
					<Swiper
						className="health-concerns"
						slidesPerView={4}
						spaceBetween={24}
						allowTouchMove={false}
						navigation={{
							enabled: true,
							prevEl: '.swiper-button-prev-hc',
							nextEl: '.swiper-button-next-hc',
						}}
						modules={[Autoplay, Navigation]}
						loop
						speed={500}
						autoplay={{
							delay: 3000,
							pauseOnMouseEnter: true,
							disableOnInteraction: false,
						}}
					>
						{healthConcerns.map(healthConcern => (
							<SwiperSlide key={healthConcern.contentful_id}>
								<HealthConcernItem {...healthConcern} />
							</SwiperSlide>
						))}
					</Swiper>
				</Flex>
				<Button
					justifyContent="right"
					position="relative"
					className="swiper-button-next-hc"
					h="fit-content"
					aria-labelledby="Next slide"
					variant="noStyle"
					top="82px"
					hover={[{ opacity: '0.7' }]}
				>
					<Icon as={LuChevronRight} w="25px" h="auto" />
				</Button>
			</Flex>
			<Grid
				gridTemplateColumns="repeat(2, 1fr)"
				justifyItems="center"
				show="largeTablet"
				largeTablet={[{ maxW: '520px' }]}
			>
				{healthConcerns.map(healthConcern => (
					<HealthConcernItem key={healthConcern.contentful_id} {...healthConcern} />
				))}
			</Grid>
			<Flex
				alignItems="center"
				w="auto"
				paddingTop="s56"
			>
				<ContentfulLink {...link} onChange={() => trackAction(link.title, link.url)} />
			</Flex>
		</Flex>
	);
};

export default ContentfulHealthConcerns;
