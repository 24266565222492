import React from 'react';
import { css, useTheme } from 'styled-components';

import { parseContentfulLink } from 'fatcat-ui-library/utils/contentful';

// Types
import { TContentfulCard, TGatsbyImage } from '../../../utils/types';

// Atoms
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { Paragraph } from 'fatcat-ui-library/components/Atoms/Paragraph';
import { Link } from 'fatcat-ui-library/components/Atoms/Link';

import ContentfulImage from '../../../contentful/ContentfulImage';

import { Icon } from 'fatcat-ui-library/components/Atoms/Icon';

type TIconTitle = Partial<TContentfulCard & { isLastCard: boolean }>

const IconTitle: React.FC<TIconTitle> = (props) => {
	const theme = useTheme();
	const {
		image,
		description,
		link,
	} = props;

	const border = `1px solid ${theme.color.linesLight}`;

	return (
		<Flex
			as="article"
			minW="200px"
			tablet={[{ minW: '100%' }]}
			styled={css`
				:last-child > div {
					border: none;
				}
			`}
		>
			<Flex
				direction="column"
				alignItems="center"
				borderRight={border}
				gap="24px"
				tablet={[
					{ paddingY: 's16' }, { marginX: 's24' },
					{ borderBottom: border },
					{ borderRight: 'none' },
				]}
			>
				<Flex
					justifyContent="center"
					mobile={[{ maxH: '76px' }]}
				>
					<ContentfulImage {...image as TGatsbyImage} tablet={[{ h: '32px' }]} />
				</Flex>
				{description && (
					<Paragraph
						textAlign="center"
						fontWeight="semiBold"
					>
						{description?.description}
					</Paragraph>
				)}
				{link?.title && (
					<Link
						{...parseContentfulLink(link)}
						variant="noStyle"
						display="inline-flex"
						w="fit-content"
						fontSize="s20"
						fontWeight="semiBold"
						tablet={[{ fontSize: 's16' }]}
						styled={css`
							gap: 10px;
							align-items: center;
						`}
					>
						{link.title}
						<Icon as={LuArrowRight} w="25px" h="auto" />
					</Link>
				)}
			</Flex>
		</Flex>
	);
};

export default IconTitle;
