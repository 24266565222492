import React from 'react';
import styled, { css } from 'styled-components';

// Component
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { Button } from 'fatcat-ui-library/components/Atoms/Button';
import Section from 'fatcat-ui-library/components/Molecules/Section/Section';
import SearchProductInput from 'src/components/Molecules/SearchProductInput/SearchProductInput';

// Asset
import { LuX } from 'react-icons/lu';
import { Icon } from 'fatcat-ui-library/components/Atoms/Icon';

type TSearchProductNavigation = {
	isOpen: boolean
	setIsOpen: (e: boolean) => void
};

export const SearchWrapper = styled(Flex) <{ isOpen: boolean }>`
	pointer-events: none;
	opacity: 0;
	visibility: 0;
	transition: all 0.3s ease-in-out;
	overflow: hidden;
	section {
		transform: translateY(-100%);
		transition: all 0.3s ease-in-out;
		transition-delay: 0.2s;
	}

	${({ isOpen }) => isOpen && css`
		pointer-events: auto;
		opacity: 1;
		visibility: 1;
		section {
			transform: translateY(0);
		}
	`}
`;

const SearchProductNavigation: React.FC<TSearchProductNavigation> = ({ isOpen, setIsOpen }) => {
	return (
		<SearchWrapper
			isOpen={isOpen}
			position="fixed"
			h="100vh"
			w="100%"
			background="rgb(0, 0, 0, 0.2)"
			zIndex={1000}
		>
			<Section
				backgroundColor="white"
				styled={css`
					height: fit-content;
				`}
				paddingY="s16"
			>
				<Flex
					gap="16px"
					mobile={['columnReverse', 'alignItemsEnd']}
				>
					<SearchProductInput setIsOpen={setIsOpen} />
					<Button
						aria-label="Search"
						title="Search"
						onClick={() => { setIsOpen(false); }}
						variant="noStyle"
					>
						<Icon as={LuX} w="24px" h="auto" />
					</Button>
				</Flex>
			</Section>
		</SearchWrapper>
	);
};

export default SearchProductNavigation;
