import React from 'react';

// Utils
import { parseContentfulOptions } from 'fatcat-ui-library/utils/contentful';

// Components
import { RenderWrapper } from './RichText/RichText.atom';
import Section from 'fatcat-ui-library/components/Molecules/Section';
import SectionHeroVideoBuilder from './SectionHeroVideoBuilder';

// Contentful Related components
import { CONTENTFUL_OPTIONS } from 'fatcat-ui-library/utils/constants';
import { TContentfulSection, TSectionHeroVideo } from '../utils/types';
import SectionRichText from './RichText/SectionRichText';
import { css } from 'styled-components';
import { SectionInnerWrapper } from 'fatcat-ui-library/components/Molecules/Section/Section.atoms';
import { rgba } from 'polished';

const SectionBuilder = (sections: (TContentfulSection | TSectionHeroVideo)[]) => {
	return sections?.map((section) => {
		if ((section as TSectionHeroVideo).__typename === 'ContentfulHeroVideoSection') { // eslint-disable-line no-underscore-dangle
			return <SectionHeroVideoBuilder key={section.identifier} {...section as TSectionHeroVideo} />;
		}
		const {
			scale,
			width,
			edge,
			show,
			hide,
			background,
			content,
			options: sectionOptions,
			customCss,
		} = section as TContentfulSection;
		const options = parseContentfulOptions(sectionOptions);
		// Unique sections with custom settings that won't be managed through contentful
		const {
			backgroundImage,
			backgroundColor,
			backgroundSize,
			backgroundRepeat,
			backgroundGradient,
			backgroundPosition,
			backgroundColorOpacity,
		} = background || {};
		return (
			<Section
				key={section.identifier}
				scale={scale}
				padding={options[CONTENTFUL_OPTIONS.PADDING]}
				paddingY={options[CONTENTFUL_OPTIONS.PADDING_Y]}
				paddingX={options[CONTENTFUL_OPTIONS.PADDING_X]}
				margin={options[CONTENTFUL_OPTIONS.MARGIN]}
				marginY={options[CONTENTFUL_OPTIONS.MARGIN_Y]}
				marginX={options[CONTENTFUL_OPTIONS.MARGIN_X]}
				desktop={options[CONTENTFUL_OPTIONS.DESKTOP]}
				largeTablet={options[CONTENTFUL_OPTIONS.LARGE_TABLET]}
				tablet={options[CONTENTFUL_OPTIONS.TABLET]}
				mobile={options[CONTENTFUL_OPTIONS.MOBILE]}
				w={width || 'normal'}
				backgroundImage={backgroundImage?.file.url}
				backgroundColor={backgroundColor}
				backgroundColorOpacity={backgroundColorOpacity && (backgroundColorOpacity / 100)}
				backgroundPosition={backgroundPosition}
				backgroundRepeat={backgroundRepeat}
				backgroundSize={backgroundSize || 'cover'}
				backgroundGradient={backgroundGradient}
				overflow={options[CONTENTFUL_OPTIONS.OVERFLOW]}
				edge={edge}
				show={show}
				hide={hide}
				styled={css`
					${customCss}
					${SectionInnerWrapper} {
						display: flex;
					}
				`}
			>
				<RenderWrapper
					justifyContent="center"
					alignItems="center"
					direction="column"
					h="100%"
					styled={
						css`
				${props => ['largeDesktop', 'standardDesktop', 'desktop', 'largeTablet', 'tablet', 'mobile']
				.map((item, index) => {
					const backgroundItem = (section as any)[`background${item.charAt(0).toUpperCase() + item.slice(1)}`];
					if (!backgroundItem) {
						return '';
					}
					return css`
						${backgroundItem && (props.theme.media as any)[item]} {
						${backgroundItem.background && `background: ${(props.theme.backgroundColor as any)[backgroundItem.background]
				? (props.theme.backgroundColor as any)[backgroundItem.background]
				: backgroundItem.background};`}
						${backgroundItem.backgroundColor && (props.theme.backgroundColor as any)[backgroundItem.backgroundColor] && `background-color: ${backgroundItem.backgroundColorOpacity
				? rgba((props.theme.backgroundColor as any)[backgroundItem.backgroundColor], backgroundItem.backgroundColorOpacity)
				: (props.theme.backgroundColor as any)[backgroundItem.backgroundColor]};`}
						${backgroundItem.backgroundColorHex && `background-color: ${backgroundItem.backgroundColorHex};`}
						${backgroundItem.backgroundImage && `background-image: url(${backgroundItem.backgroundImage.file.url});`}
						${backgroundItem.backgroundPosition && `background-position: ${backgroundItem.backgroundPosition}`};
						${backgroundItem.backgroundSize && `background-size: ${backgroundItem.backgroundSize}`};
						${backgroundItem.backgroundRepeat && `background-repeat: ${backgroundItem.backgroundRepeat}`};
						${backgroundItem.backgroundGradient && `background: ${backgroundItem.backgroundGradient}`};
						${backgroundItem.backgroundBlur && `backdrop-filter: blur(${backgroundItem.backgroundBlur})`};
						${backgroundItem.backgroundAttachment && `background-attachment: ${backgroundItem.backgroundAttachment}`};
						${backgroundItem.backgroundBlendMode && `background-blend-mode: ${backgroundItem.backgroundBlendMode}`};
						${backgroundItem.backdropFilter && `backdrop-filter: ${backgroundItem.backdropFilter}`};
						${backgroundItem.filter && `filter: ${backgroundItem.filter}`};
					}`;
				})}	
					`
					}
				>
					{SectionRichText(content)}
				</RenderWrapper>
			</Section>
		);
	});
};

export default SectionBuilder;
