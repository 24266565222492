import React, { useRef } from 'react';

// Types
import { TContentfulMarquee } from '../../utils/types';

// Components
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { Image } from 'fatcat-ui-library/components/Atoms/Image';
import { Text } from 'fatcat-ui-library/components/Atoms/Text';
import { Wrapper } from 'fatcat-ui-library/components/Atoms/Wrapper';
import bannerSeparator from 'src/assets/images/icons/banner-separator.svg';
import { css } from 'styled-components';

const ContentfulMarquee: React.FC<TContentfulMarquee> = (props) => {
	const { marqueeItems, textColor } = props;
	const itemRef = useRef<HTMLDivElement>(null);

	const renderChildren = () => {
		return (
			<Flex ref={itemRef} w="auto">
				<Image
					loading="lazy"
					src={bannerSeparator}
					alt=" · "
					marginRight="s20"
					mobile={[{ display: 'none' }]}
				/>
				{marqueeItems.map((item, index) => (
					<React.Fragment key={index}>
						<Text
							whiteSpace="nowrap"
							marginRight="s20"
							mobile={[{ marginRight: 's0' }]}
							styled={css`
							display: inline-block;

						`}
						>
							{item}
						</Text>
						<Image
							loading="lazy"
							src={bannerSeparator}
							alt=" · "
							marginRight={index === marqueeItems.length - 1 ? 's0' : 's20'}
							mobile={[{ display: 'none' }]}

						/>
					</React.Fragment>
				))}
			</Flex>
		);
	};

	return (
		<Wrapper
			overflow="hidden"
			paddingY="s10"
		>
			<Flex
				w="auto"
				willChange="transform"
				textColor={textColor}
				fontWeight="semiBold"
				justifyContent="center"
			>
				{renderChildren()}
			</Flex>
		</Wrapper>
	);
};

export default ContentfulMarquee;
