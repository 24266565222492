import styled from 'styled-components';
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';

export const RenderWrapper = styled(Flex)`
	& > *:first-child {
		margin-top: 0;
	}
	& > *:last-child {
		margin-bottom: 0;
	}
	& > br:last-child {
		display: none;
	}

	h2 + h3 {
		margin-top: -8px;
	}
`;
