import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import styled from 'styled-components';

export const FloatBannerWrapper = styled(Flex)<{mobileTop:string}>`
    align-items: center;
    gap: 32px;
    position: relative;
    top: ${props => props.top};
    background-color: white;
    padding-top: 24px;
    padding-bottom: 24px;
    border-radius: 1000px;
    box-shadow: 0 0 17px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: ${props => props.top}!important;

    ${props => props.theme.media.tablet} {
        padding-top: 40px;
        padding-bottom: 40px;
		max-width: 350px;
		border-radius: 220px;
    }

    ${props => props.theme.media.mobile} {
        top: ${props => props.mobileTop};
        margin-bottom: ${props => props.mobileTop}!important;
    }
`;
