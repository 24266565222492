import React from 'react';
import { keyframes, css } from 'styled-components';
// Types
import { TContentfulHeading, TContentfulText } from '../../utils/types';

// Atoms
import { Text } from 'fatcat-ui-library/components/Atoms/Text';
import { Heading } from 'fatcat-ui-library/components/Atoms/Heading';
import { BR } from 'fatcat-ui-library/components/Atoms/Divider';

import curley from 'src/assets/images/icons/curly.svg';

const keyframe = keyframes`
	from {
		-webkit-transform: translateX(-16px);
		transform: translateX(-16px);
	}
	to {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
`;

export const generateText = (array: TContentfulText[]) => {
	const mainColor = array && array[0] ? array[0].textColor : 'black100';
	return array ? array.map((item, index) => {
		if (item.text === '<br preserved />') {
			return <BR key={index} />; // eslint-disable-line
		}
		if (item.text === '<br />') {
			return <BR key={index} hide="tablet" />; // eslint-disable-line
		}
		if (item.textColor !== mainColor || item.haveUnderline) {
			return (
				<Text
					key={`${item.text}-${index}`} // eslint-disable-line
					textColor={item.textColor}
					position={item.haveUnderline ? 'relative' : undefined}
				>
					{item.text}
					{item.haveUnderline && (
						<Text
							w="100%"
							h="7px"
							overflow="hidden"
							position="absolute"
							bottom="-0.2em"
							left="0"
						>
							<Text
								w="200%"
								h="7px"
								display="block"
								backgroundImage={curley}
								backgroundSize="contain"
								backgroundRepeat="repeat-x"
								transform="translateX(-16px)"
								styled={css`
									animation: ${keyframe} 0.75s linear infinite forwards;
								`}
							/>
						</Text>
					)}
				</Text>
			);
		}
		return item.text;
	}) : null;
};

const ContentfulHeading: React.FC<TContentfulHeading> = (props) => {
	const {
		headingSize,
		headingText,
		textAlign,
		textAlignTablet,
		textAlignMobile,
		margin,
		eyebrow,
	} = props;

	return (
		<>
			{eyebrow && (
				<Text margin={['b8']} textColor={eyebrow.textColor} fontWeight="bold">
					{eyebrow.text}
				</Text>
			)}
			<Heading
				w="100%"
				margin={margin || ['b32']}
				textAlign={textAlign || 'center'}
				textColor={headingText && headingText[0] ? headingText[0].textColor : 'black'}
				{...props}
				as={headingSize}
				tablet={textAlignTablet && ([{ textAlign: textAlignTablet }])}
				mobile={textAlignMobile && ([{ textAlign: textAlignMobile }])}
				styled={css`
					line-height: normal;
					span {
						line-height: normal;
					}
				`}
			>
				{generateText(headingText)}
			</Heading>
		</>
	);
};
export default ContentfulHeading;
