import React from 'react';
import { useTheme, css } from 'styled-components';

// Types
import { TContentfulCard } from '../../../utils/types';

// Atoms
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { Paragraph } from 'fatcat-ui-library/components/Atoms/Paragraph';

type TBrandReviewCard = Partial<TContentfulCard>

const BrandReviewCard: React.FC<TBrandReviewCard> = (props) => {
	const theme = useTheme();

	const {
		title,
		description,
	} = props;

	return (
		<Flex
			as="article"
			direction="column"
			position="relative"
			alignItems="center"
			paddingBottom="s24"
			padding="s8"
			maxW="364px"
			minW="200px"
			gap="24px"
			borderRight={`1px solid ${theme.color.linesLight}`}
			tablet={['borderNone', { borderBottom: `1px solid ${theme.color.linesLight}` }]}
			styled={css`
				&:after {
					content: '';
					position: absolute;
					width: 105px;
					right: 34%;
					top: -26px;
					height: 4px;
					background-color: ${theme.color.mintGreen};
					border-radius: 24px;
				}
				${theme.media.largeTablet} {
					&:after {
						right: 29%;
					}
				}
				${theme.media.tablet} {
					&:after {
						top: -14px;
						right: 35%;
					}
					:not(:first-of-type) {
						&:after {
							top: -3px;
						}
					}
				}
				:last-child {
					border: none;
				}
			`}
		>
			<Paragraph
				textAlign="center"
				fontWeight="semiBold"
				maxW="247px"
				padding={['t24', 'b16']}
			>
				{description?.description}
			</Paragraph>
			<Flex
				direction="column"
				gap="8px"
				alignItems="center"
			>
				<Paragraph
					size="small"
					textAlign="center"
				>
					{title}
				</Paragraph>
				<Paragraph size="small" textColor="secondary" fontWeight="medium">Customer</Paragraph>
			</Flex>
		</Flex>
	);
};

export default BrandReviewCard;
