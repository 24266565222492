import { TAppContext } from 'src/utils/types';
import createCtx from './context';

const initState = {
	getAvailableQuantity: id => 0,
	closeCart: () => null,
	openCart: () => null,
	toggleCart: () => null,
	isCartOpen: false,
	itemAddedToCart: '',
	clearItemAddedToCart: () => null,
	isSearchOpen: false,
	setIsSearchOpen: () => null,
	navOpen: false,
	setNavOpen: () => null,
} as TAppContext;

const [useAppContext, AppProvider, AppContext] = createCtx<TAppContext>('AppContext', initState);

export {
	useAppContext, AppProvider, AppContext,
};
