import React, { ReactNode, ReactElement } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { css, useTheme } from 'styled-components';

import {
	BLOCKS,
	Block,
	INLINES,
	Inline,
	MARKS,
} from '@contentful/rich-text-types';

import { RichTextData, TGatsbyImage } from '../../utils/types';

import { Text } from 'fatcat-ui-library/components/Atoms/Text';
import { Heading } from 'fatcat-ui-library/components/Atoms/Heading';
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { Paragraph } from 'fatcat-ui-library/components/Atoms/Paragraph';
import { OlList } from 'fatcat-ui-library/components/Atoms/OlList';
import { UlList } from 'fatcat-ui-library/components/Atoms/UlList';

import { Link as UILink } from 'fatcat-ui-library/components/Atoms/Link';
import ContentfulImage from '../ContentfulImage';

// Helpers
import SplitText from '../SplitText/SplitText';

// Widget manager
import ComponentManager from '../ComponentManager';

type Node = Block | Inline;

const options = {
	renderText: (text: string): ReactNode => SplitText(text),
	renderMark: {
		[MARKS.BOLD]: (text: ReactNode): ReactNode => <Text fontWeight="bold">{text as ReactElement}</Text>,
		[MARKS.ITALIC]: (text: ReactNode): ReactNode => <Text fontStyle="italic">{text as ReactElement}</Text>,
		[MARKS.UNDERLINE]: (text: ReactNode): ReactNode => <Text textDecoration="underline">{text as ReactElement}</Text>,
		[MARKS.CODE]: (text: ReactNode): ReactNode => <Text as="code">{text as ReactElement}</Text>,
	},
	renderNode: {
		[BLOCKS.PARAGRAPH]: (node: Node, children: ReactNode): ReactNode => {
			// removing empty paragraph if section only need to render components
			if (Array.isArray(children) && children.length === 1 && Array.isArray(children[0]) && !children[0][0]) return null; // eslint-disable-line
			return (
				<Paragraph margin={['b24']}>
					{children as ReactElement[]}
				</Paragraph>
			);
		},
		[BLOCKS.HEADING_1]: (node: Node, children: ReactNode): ReactNode => {
			const { content } = node;
			const parsedId = content[0].value.replaceAll(' ', '-');
			return (
				<Heading
					as="h1"
					id={parsedId}
					margin={['b32']}
				>
					{children as ReactElement}
				</Heading>
			);
		},
		[BLOCKS.HEADING_2]: (node: Node, children: ReactNode): ReactNode => {
			const { content } = node;
			const parsedId = content[0].value.replaceAll(' ', '-');

			return (
				<Heading
					as="h2"
					id={parsedId}
					margin={['b32']}
				>
					{children as ReactElement}
				</Heading>
			);
		},
		[BLOCKS.HEADING_3]: (node: Node, children: ReactNode): ReactNode => {
			const { content } = node;
			const parsedId = content[0].value.replaceAll(' ', '-');

			return (
				<Heading
					as="h3"
					id={parsedId}
					margin={['b32']}
				>
					{children as ReactElement}
				</Heading>
			);
		},
		[BLOCKS.HEADING_4]: (node: Node, children: ReactNode): ReactNode => {
			const { content } = node;
			const parsedId = content[0].value.replaceAll(' ', '-');

			return (
				<Heading
					as="h4"
					id={parsedId}
					margin={['b32']}
				>
					{children as ReactElement}
				</Heading>
			);
		},
		[BLOCKS.HEADING_5]: (node: Node, children: ReactNode): ReactNode => {
			const { content } = node;
			const parsedId = content[0].value.replaceAll(' ', '-');

			return (
				<Heading
					as="h5"
					id={parsedId}
					margin={['b32']}
				>
					{children as ReactElement}
				</Heading>
			);
		},
		[BLOCKS.HEADING_6]: (node: Node, children: ReactNode): ReactNode => {
			const { content } = node;
			const parsedId = content[0].value.replaceAll(' ', '-');

			return (
				<Heading
					as="h6"
					id={parsedId}
					margin={['b32']}
				>
					{children as ReactElement}
				</Heading>
			);
		},

		[BLOCKS.OL_LIST]: (node: Node, children: ReactNode) => <OlList bulletColor="primary" paddingLeft="s0">{children}</OlList>,
		[BLOCKS.UL_LIST]: (node: Node, children: ReactNode) => <UlList bulletColor="primary" paddingLeft="s0">{children}</UlList>,
		[BLOCKS.LIST_ITEM]: (node: Node, children: ReactNode) => <Paragraph as="li">{children}</Paragraph>,
		[BLOCKS.EMBEDDED_ASSET]: (node: Node) => {
			const { data } = node;
			return (
				<Flex
					w="fit-content"
					alignSelf="center"
					margin={['b24']}
					borderRadius="4px"
					overflow="hidden"
				>
					<ContentfulImage {...data.target as TGatsbyImage} />
				</Flex>
			);
		},
		[BLOCKS.EMBEDDED_ENTRY]: (node: Node) => {
			const { data } = node;
			// ignore empty block
			if (!data.target) {
				return null;
			}

			return ComponentManager(data.target);
		},
		[BLOCKS.QUOTE]: (node: Node, children: ReactNode) => {
			const theme = useTheme();
			return (
				<Flex>
					<Flex
						direction="column"
						borderLeft={`2px solid ${theme.color.lines}`}
						margin={['b32']}
						gap="12px"
						styled={css`
							p {
								padding-left: 24px;
								font-style: italic;
								margin-bottom: 0px;
								color: ${theme.textColor.secondary}
							}
						`}
					>
						{children as ReactElement}
					</Flex>
				</Flex>
			);
		},
		[INLINES.HYPERLINK]: (node: Node, children: ReactNode): ReactNode => {
			const { data } = node;
			const newTab = data.uri.startsWith('http') || data.uri.startsWith('www');
			return (
				<UILink
					as={!newTab ? GatsbyLink : undefined}
					to={data.uri}
					margin={['b2']}
					target={newTab ? '_blank' : '_self'}
					w="fit-content"
				>
					{children as ReactElement}
				</UILink>
			);
		},
	},
};

const RichText = (content: RichTextData) => {
	return renderRichText(content, options);
};

export default RichText;
