import { CSSProp } from 'styled-components';
import { FatCatTheme } from 'fatcat-ui-library/theme/theme';

import {
	BUTTON_VARIANT,
	BUTTON_COLOR,
	COLOR,
	FONT_FAMILY,
	LINK_VARIANT,
	SECTION_WIDTH,
	SPACE,
	TEXT_COLOR,
	TEXT_SIZE,
	TEXT_VARIANT,
	MEDIA,
	INPUT_VARIANT,
	ULLIST_VARIANT,
	OLLIST_VARIANT,
	LINK_COLOR,
	BACKGROUND_COLOR,
	BUTTON_SIZE,
	RESPONSIVE,
	ANIMATIONS,
	LINE_HEIGHT,
} from './styles';

import { SideSpace } from './types';

declare module 'styled-components' {
	export interface DefaultTheme extends FatCatTheme {
		animation: FatCatTheme['animation'] & typeof ANIMATIONS
		backgroundColor: FatCatTheme['backgroundColor'] & typeof BACKGROUND_COLOR
		buttonColor: FatCatTheme['buttonColor'] & typeof BUTTON_COLOR
		buttonSize: FatCatTheme['buttonSize'] & typeof BUTTON_SIZE
		buttonVariant: FatCatTheme['buttonVariant'] & typeof BUTTON_VARIANT
		color: FatCatTheme['color'] & typeof COLOR
		fontFamily: typeof FONT_FAMILY
		fontSize: FatCatTheme['fontSize']
		fontWeight: FatCatTheme['fontWeight']
		hover: FatCatTheme['hover']
		inputVariant: FatCatTheme['inputVariant'] & typeof INPUT_VARIANT
		lineHeight: FatCatTheme['lineHeight'] & typeof LINE_HEIGHT
		linkColor: FatCatTheme['linkColor'] & typeof LINK_COLOR
		linkVariant: FatCatTheme['linkVariant'] & typeof LINK_VARIANT
		media: FatCatTheme['media'] & typeof MEDIA
		mediaMobile: FatCatTheme['mediaMobile']
		ollistVariant: FatCatTheme['ollistVariant'] & typeof OLLIST_VARIANT
		responsive: FatCatTheme['responsive'] & typeof RESPONSIVE
		screenRatio: FatCatTheme['screenRatio']
		sectionLayout: CSSProp
		sectionWidth: FatCatTheme['sectionWidth'] & typeof SECTION_WIDTH
		sideSpace: FatCatTheme['sideSpace'] | SideSpace
		space: FatCatTheme['space'] & typeof SPACE
		tableVariant: FatCatTheme['tableVariant']
		textColor: FatCatTheme['textColor'] & typeof TEXT_COLOR
		textSize: FatCatTheme['textSize'] & typeof TEXT_SIZE
		textVariant: FatCatTheme['textVariant'] & typeof TEXT_VARIANT
		ullistVariant: FatCatTheme['ullistVariant'] & typeof ULLIST_VARIANT
		useMobileFirst: FatCatTheme['useMobileFirst']
	}
}

export const newTheme = {
	animation: ANIMATIONS,
	color: COLOR,
	buttonColor: BUTTON_COLOR,
	buttonSize: BUTTON_SIZE,
	buttonVariant: BUTTON_VARIANT,
	backgroundColor: BACKGROUND_COLOR,
	textColor: TEXT_COLOR,
	space: SPACE,
	sectionWidth: SECTION_WIDTH,
	textSize: TEXT_SIZE,
	lineHeight: LINE_HEIGHT,
	textVariant: TEXT_VARIANT,
	linkColor: LINK_COLOR,
	linkVariant: LINK_VARIANT,
	media: MEDIA,
	ollistVariant: OLLIST_VARIANT,
	ullistVariant: ULLIST_VARIANT,
	fontFamily: FONT_FAMILY,
	responsive: RESPONSIVE,
};

export * from './styles';
export * from './types';
