import React, { useState, useEffect } from 'react';
import { useTheme, css } from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

// Types
import { TContentfulLink } from '../../../utils/types';

// Atoms
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { Wrapper } from 'fatcat-ui-library/components/Atoms/Wrapper';
import { Text } from 'fatcat-ui-library/components/Atoms/Text';

// Molecules
import Section from 'fatcat-ui-library/components/Molecules/Section';
import SocialMedia from 'fatcat-ui-library/components/Molecules/SocialMedia';
import { Link } from 'fatcat-ui-library/components/Atoms/Link';
import Logo from 'src/components/Molecules/Logo/Logo';
import ContentfulLink from '../../../contentful/ContentfulLink';

type Props = {
	isProductPage: boolean;
};

const Footer = ({ isProductPage }:Props) => {
	const theme = useTheme();
	const [initialized, setInitialized] = useState(false);
	useEffect(() => {
		setInitialized(true);
	}, []);
	const currentYear = initialized ? new Date().getFullYear() : '';
	const { contentfulNavigation: data } = useStaticQuery(graphql`
		query FooterMenuQuery {
			contentfulNavigation(identifier: {eq: "Main Navigation"}) {
				socialLinks {
					title
					url
				}
				footerLinks {
					...LinkWithItemsFields
				}
				footerStatment
			}
		}
	`);
	const {
		socialLinks,
		footerLinks,
		footerStatment,
	} = data;

	//* format Social links from ContentfulLink */
	const social = socialLinks.reduce((acc: { [k: string]: string }, social: TContentfulLink) => {
		const key = social.title.toLowerCase();
		acc[key] = social.url;
		return acc;
	}, {});

	return (
		<Wrapper
			as="footer"
			mobile={isProductPage ? [
				{ paddingBottom: 's200' },
			] : []}
		>
			<Section as="div">
				<Flex
					largeTablet={['column']}
				>
					<Flex
						padding={['t48', 'b24']}
						justifyContent="space-between"
						gap="20px"
						largeTablet={[
							'column',
							'alignItemsCenter',
							{ gap: '32px' },
						]}
					>
						<Flex
							direction="column"
							gap="32px"
							justifyContent="space-between"
							maxW="245px"
							largeTablet={[
								{ margin: ['b16'] },
							]}
						>
							<Flex
								direction="column"
								gap="32px"
								largeTablet={['alignItemsCenter']}
								flex={1}
							>
								<Logo w="120px" />
								<SocialMedia
									socials={social}
									color="primary"
									hover={[{ zoom: '1.2', textColor: 'primary' }]}
									size="25px"
									styled={css`
										margin-left: -4px;
										${theme.media.largeTablet} {
											margin-left: 0;
										}
									`}
								/>
							</Flex>
							<Flex hide="largeTablet">
								<Text fontSize="s14" textColor="secondary">
									{footerStatment}
								</Text>
							</Flex>
						</Flex>
						<Flex
							gap="40px"
							justifyContent="space-between"
							largeTablet={[
								'column',
								'alignItemsCenter',
								{ gap: '40px' },
							]}
							fontWeight="medium"
							w="100%"
						>
							{footerLinks.map((link: TContentfulLink, groupIndex) => {
								if (link.items) {
									return (
										<Flex
											key={link.title}
											direction="column"
											gap="10px"
											largeTablet={[
												'alignItemsCenter',
											]}
											flex={groupIndex ? 1.5 : 2}
										>
											<Text margin={['b8']} textColor="secondary" fontWeight="semiBold">{link.title}</Text>
											{link.items.map(item => <ContentfulLink key={item.title} {...item} />)}
										</Flex>
									);
								}
								return <ContentfulLink key={link.title} {...link} />;
							})}
						</Flex>
						<Flex show="largeTablet" maxW="335px">
							<Text
								textColor="secondary"
								fontSize="s14"
								textAlign="center"
							>
								{footerStatment}
							</Text>
						</Flex>
					</Flex>
				</Flex>
				<Flex
					borderTop={`1px solid ${theme.color.lines}`}
					padding={['t24', 'b36']}
					justifyContent="space-between"
					largeTablet={[
						'columnReverse',
						'alignItemsCenter',
						{ gap: '32px' },
					]}
				>
					<Text textColor="secondary">{`Enjoy Good Health LLC, ${currentYear}. All rights Reserved.`}</Text>
					<Flex
						gap="40px"
						w="fit-content"
						largeTablet={[
							'column',
							'alignItemsCenter',
							{ gap: '20px' },
						]}
						fontWeight="medium"
					>
						<Link
							variant="underlineLight"
							to="https://www.iubenda.com/privacy-policy/81980202"
							target="_blank"
						>
							Privacy Policy
						</Link>
						<Link
							variant="underlineLight"
							to="https://www.iubenda.com/terms-and-conditions/81980202"
							target="_blank"
						>
							Terms and Conditions
						</Link>
					</Flex>
				</Flex>
			</Section>
		</Wrapper>
	);
};

export default Footer;
