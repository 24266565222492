import styled, { css } from 'styled-components';
import { Button } from 'fatcat-ui-library/components/Atoms/Button';
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { Center } from 'fatcat-ui-library/components/Atoms/Center';
import { Text } from 'fatcat-ui-library/components/Atoms/Text';

export const ProductCardWrapper = styled(Flex) <{ inSwiper: boolean }>`
    flex-direction: column;
    width: 249px;
    border-radius: 20px;
    overflow: hidden;
	padding: 26px 0 26px;
	box-shadow: 0px 0px 12px 0px rgba(79,72,72, 0.1);
	.gatsby-image-wrapper {
		height: 240px;
	}

	${props => props.theme.media.mobile} {
		width: 100%;
	}
`;

export const NewWrapper = styled.div`
	position: absolute;
	top: 8px;
	right: 8px;
	z-index: 1;
	font-weight: 600;
`;

export const ProductCardContentWrapper = styled(Flex)`
	flex: 1;
	flex-direction: column;
`;

export const CheckmarkWrapper = styled(Center)`
	position: absolute;
	bottom: 0;
	align-items: center;
	justify-content: center;
	height: 100%;
	transition: opacity 0.5s ease-in-out;
	opacity: 0;
`;

export const AddToCartInfo = styled(Text) <{ showAdded: boolean }>`
	svg {
		margin-right: 8px;
	}
	opacity: 0;
	transition: opacity 0.5 ease-in-out;
	${props => props.showAdded && css`
		opacity: 1;
	`}
`;

export const AddToCartButtonWrapper = styled(Button) <{ showAdded: boolean }>`
    position: relative;
	display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border: none;
	overflow: hidden;
	height: 26px;
	transition: opacity 0.5 ease-in-out;

	&:focus-visible {
		outline: none;
	}

	&:hover {
		color: ${props => props.theme.color.textColor};
	}

    &:disabled {
        cursor: default;
        color: ${props => props.theme.color.lightGray};
    }
	.stock-wrapper {
		transition-delay: 0.2s;
	}

	${props => props.showAdded && css`
	.stock-wrapper {
			opacity: 0;
			transition: none;
			transition-delay: 0;
		}
		${CheckmarkWrapper} {
			opacity: 1;
		}
	`}
`;
