import React from 'react';
// Types
import { TContentfulSpacer } from '../../utils/types';

import { Wrapper } from 'fatcat-ui-library/components/Atoms/Wrapper';

const ContentfulSpacer: React.FC<TContentfulSpacer> = ({
	size,
	scale,
	show,
	hide,
}) => {
	return (
		<Wrapper
			as="hr"
			paddingTop={size}
			border="none"
			margin="s0"
			scale={scale}
			show={show}
			hide={hide}
		/>
	);
};
export default ContentfulSpacer;
