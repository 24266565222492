import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import styled, { DefaultTheme } from 'styled-components';

export const HealthWrapper = styled(Flex) <{bgColor: keyof DefaultTheme['color']}>`
	::before {
		content: '';
		position: absolute;
		left: 50%;
		transform: translate(-50%, -50%);
		top: 94px;
		width: 140px;
		height: 140px;
		border-radius: 50%;
		background-color: ${props => props.theme.color.bgLight};
		will-change: top, width, height;
		transition: all 0.3s ease-in-out;
	}
	:hover {
		::before {
			top: 50%;
			width: 100%;
			height: 100%;
			border-radius: 200px;
			background-color: ${props => props.theme.color[props.bgColor]};
		}
		.health-image {
			position: absolute;
			opacity: 0;
		}
		.health-image-hover {
			position: relative;
			opacity: 1;
		}
	}
	${props => props.theme.media.largeTablet} {
		::before {
			top: 82px;
			width: 116px;
			height: 116px;
		}
		:hover {
			::before {
				top: 82px;
				width: 116px;
				height: 116px;
				border-radius: 50%;
				background-color: ${props => props.theme.color.bgLight};
			}
			.health-image {
				position: relative;
				opacity: 1;
			}
			.health-image-hover {
				position: absolute;
				opacity: 0;
			}
		}
	}
	${props => props.theme.media.mobile} {
		::before {
			top: 82px;
			width: 100px;
			height: 100px;
		}
		:hover {
			::before {
				top: 82px;
				width: 100px;
				height: 100px;
			}
		}
	}
`;
