import React, { useState, useEffect, useRef } from 'react';

import { css, keyframes } from 'styled-components';
// Types
import { TContentfulPartnershipBanner } from '../../utils/types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { IGatsbyImageData } from 'gatsby-plugin-image/dist/src/components/gatsby-image.browser';

// Atoms
import { Text } from 'fatcat-ui-library/components/Atoms/Text';
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { Center } from 'fatcat-ui-library/components/Atoms/Center';
import { Wrapper } from 'fatcat-ui-library/components/Atoms/Wrapper';

const ContentfulPartnershipBanner: React.FC<TContentfulPartnershipBanner> = (props) => {
	const {
		bannerText,
		partners,
	} = props;
	const itemRef = useRef<HTMLDivElement>(null);
	const [itemWidth, setItemWidth] = useState(0);

	const renderChildren = () => {
		return (
			<Center ref={itemRef} w="auto" gap="64px" paddingX="s32" tablet={[{ gap: '32px', paddingX: 's16' }]}>
				{partners.map((partner) => {
					// shrinking images for better resolution
					const parsedData = {
						...partner.gatsbyImageData,
						width: (partner?.gatsbyImageData?.width as number) / 2,
						height: (partner?.gatsbyImageData?.height as number) / 2,
					};
					const gatsbyImage = getImage(parsedData) as IGatsbyImageData;
					return (
						<Center key={partner.file.url} w={`${parsedData.width}px`} h={`${parsedData.height}px`}>
							<GatsbyImage
								image={gatsbyImage}
								alt={partner.description || ''}
							/>
						</Center>
					);
				})}
			</Center>
		);
	};

	useEffect(() => {
		if (itemRef && itemRef.current) {
			setItemWidth(itemRef.current.clientWidth);
		}
	}, []);

	const keyframe = keyframes`
		from {
			-webkit-transform: translateX(-${itemWidth || 0}px);
			transform: translateX(-${itemWidth || 0}px);
		}
		to {
			-webkit-transform: translateX(0);
			transform: translateX(0);
		}
	`;

	return (
		<Center
			gap="40px"
			largeTablet={['column', { gap: '24px' }]}
		>
			{bannerText && (
				<Text
					fontWeight="semiBold"
					whiteSpace="nowrap"
				>
					{bannerText}
				</Text>
			)}
			<Wrapper
				overflow="hidden"
				paddingY="s10"
			>
				<Flex
					w="auto"
					h="60px"
					willChange="transform"
					styled={css`
						animation: ${keyframe} ${+(itemWidth / 33.33).toFixed(2)}s linear infinite forwards;
						&:hover {
							animation-play-state: paused;
						}
					`}
				>
					{renderChildren()}
					{renderChildren()}
					{renderChildren()}
				</Flex>
			</Wrapper>
		</Center>
	);
};
export default ContentfulPartnershipBanner;
