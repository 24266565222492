import React from 'react';
import { Properties } from 'csstype';
// import loadable from '@loadable/component';

// Types
import { TContentfulCard } from '../../utils/types';

// Components
import SimpleCard from '../../components/Molecules/Cards/SimpeCard';
import BrandReviewCard from '../../components/Molecules/Cards/BrandReviewCard';
import BlogCard from '../../components/Molecules/Cards/BlogCard';
import IconTitle from 'src/components/Molecules/Cards/IconTitle';
// const SimpleCard = loadable(() => import('../../components/Molecules/Cards/SimpeCard'));
// const BrandReviewCard = loadable(() => import('../../components/Molecules/Cards/BrandReviewCard'));

type TContentfulComponentCard = {
	card: TContentfulCard
	animationDelay?: Properties['animationDelay']
}

const ContentfulComponentCard: React.FC<TContentfulComponentCard> = (props) => {
	const {
		card,
		...restProps
	} = props;

	switch (card.type) {
		case 'simple-left': {
			return (
				<SimpleCard {...card} {...restProps} />
			);
		}
		case 'simple-centered': {
			return (
				<SimpleCard {...card} {...restProps} textCenter />
			);
		}
		case 'brand-review': {
			return (
				<BrandReviewCard {...card} {...restProps} />
			);
		}
		case 'blog': {
			return (
				<BlogCard {...card.blogPage} {...restProps} />
			);
		}
		case 'icon-title':
			return (
				<IconTitle {...card} {...restProps} />
			);
		default: return null;
	}
};

ContentfulComponentCard.defaultProps = {
	animationDelay: undefined,
};

export default ContentfulComponentCard;
