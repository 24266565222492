import React, { useCallback, useMemo } from 'react';
import { CSSProp } from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import ReactPlayer from 'react-player/youtube';

// Atoms
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { EmbedVideoWrapper, ThumbnailWrapper } from './EmbeddedVideo.atoms';
import { Center } from 'fatcat-ui-library/components/Atoms/Center';
import { Image } from 'fatcat-ui-library/components/Atoms/Image';
import { Text } from 'fatcat-ui-library/components/Atoms/Text';

import ContentfulLink from '../ContentfulLink';

// Molecules
import { Spinner } from 'src/components/Molecules/Loaders/Spinner';

// Assets
import playButton from 'src/assets/images/icons/playButton.svg';

// Utils
import { TContentfulEmbedVideo } from 'src/utils/types';
import { trackAnalytic } from 'src/utils/gtag';

export type EmbedWrapper = { fluid?: boolean, height?: CSSProp<'height'> };
type TContentfulEmbedVideoComponent = EmbedWrapper & TContentfulEmbedVideo;

const ContentfulEmbedVideo: React.FC<TContentfulEmbedVideoComponent> = (props) => {
	const [isPlaying, setIsPlaying] = React.useState(false);
	const [loadPlayer, setLoadPlayer] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(true);
	const {
		fluid,
		height,
		styled,
		title,
		videoId,
		embedVideoLink,
	} = props;
	const { allFile: { edges } } = useStaticQuery(graphql`
		query thumbnail {
			allFile(filter: {name: {regex: "/^embed-thumbnail-.+/"  }}){
				edges {
					node {
						name
						childImageSharp {
							gatsbyImageData(layout: CONSTRAINED , placeholder: BLURRED, formats: [AUTO, AVIF])
						}
					}
				}
			}
		}
	`);

	const thumbnail = useMemo(() => edges.find(({ node }: { node: { name: string } }) => node.name === `embed-thumbnail-${videoId}`)?.node, [edges, videoId]);

	const togglePlay = useCallback(() => {
		setIsPlaying(prevState => !prevState);
		setLoadPlayer(true);
		if (isPlaying) {
			trackAnalytic('video_played', {
				video_title: title,
				video_id: videoId,
			});
		}
	}, []);
	return (
		<Flex
			className="embedded-video-player"
			justifyContent="center"
			h="100%"
			styled={styled}
		>
			<Flex
				w="fit-content"
				h="fit-content"
				direction="column"
				borderRadius="40px"
				transform="translateZ(0)"
				overflow="hidden"
				boxShadow="0px 0px 36px 0px rgb(132,105,105, 0.1)"
				tablet={[{ borderRadius: '20px' }]}
			>
				<EmbedVideoWrapper fluid={fluid} height={height}>
					<ThumbnailWrapper
						isPlaying={isPlaying && !isLoading}
						onClick={togglePlay}
					>
						<GatsbyImage
							className="thumbnail"
							image={thumbnail?.childImageSharp?.gatsbyImageData}
							alt={title}
						/>
						{isLoading && isPlaying && (
							<Center position="absolute" inset="0" zIndex={6}>
								<Spinner size="50px" />
							</Center>
						)}
						<Center
							className="play-button-wrapper"
							w="fit-content"
							position="absolute"
							gap="10px"
							bottom="40px"
							right="60px"
							zIndex={5}
							tablet={[{ bottom: '20px', right: '20px' }]}
						>
							<Text
								textColor="white"
								fontWeight="semiBold"
								fontSize="s20"
								tablet={[{ fontSize: 's16' }]}
							>
								Play
							</Text>
							<Image
								className="play-button"
								loading="lazy"
								width="44px"
								height="44px"
								src={playButton}
								alt="play button"
								mobile={[{ w: '33px', h: '33px' }]}
							/>
						</Center>
						<Flex
							className="embedded-video-title"
							position="absolute"
							top="40px"
							left="40px"
							textColor="white"
							fontSize="s32"
							fontWeight="bold"
							lineHeight="s150"
							maxW="30%"
							zIndex={5}
							tablet={[{ top: '20px', left: '20px', fontSize: 's16' }]}
						>
							{title}
						</Flex>
					</ThumbnailWrapper>
					{loadPlayer && (
						<ReactPlayer
							title={title}
							playing={isPlaying}
							onEnded={togglePlay}
							url={`https://www.youtube-nocookie.com/embed/${videoId}`}
							width="100%"
							height="100%"
							controls
							onStart={() => setIsLoading(false)}
							style={{
								borderRadius: '4px', overflow: 'hidden', position: 'absolute',
							}}
						/>
					)}
				</EmbedVideoWrapper>
				{embedVideoLink?.title && (
					<Center
						paddingY="s24"
						backgroundColor="white"
						tablet={[{ paddingY: 's16' }]}
					>
						<ContentfulLink
							url={embedVideoLink.url}
							title={embedVideoLink.title}
							openInNewTab={embedVideoLink.openInNewTab}
							page={embedVideoLink.page}
							linkVariant="withArrow"
						/>
					</Center>
				)}
			</Flex>
		</Flex>
	);
};

export default ContentfulEmbedVideo;

ContentfulEmbedVideo.defaultProps = {
	fluid: false,
	height: undefined,
};
