import React from 'react';

// Types
import { TContentfulBlockWithLottieBg } from '../../utils/types';

import { parseContentfulOptions } from 'fatcat-ui-library/utils/contentful';

// Atoms
import ContentfulLottiePlayer from '../ContentfulLottiePlayer';
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { Paragraph } from 'fatcat-ui-library/components/Atoms/Paragraph';

import SplitText from '../SplitText/SplitText';

const ContentfulBlockWithLottieBg: React.FC<TContentfulBlockWithLottieBg> = (props) => {
	const {
		mobileAnimation,
		desktopAnimation,
		blockLottieText: description,
		blockLottieOptions,
	} = props;
	const option = parseContentfulOptions(blockLottieOptions);
	const padding = option.padding ? ['t48', 'r20', 'l20', 'b48', ...option.padding] : ['t48', 'b48', 'r20', 'l20'];

	return (
		<Flex
			justifyContent="center"
			position="relative"
		>
			<Flex
				hide="tablet"
				position="absolute"
				top="0"
				left="0"
				right="0"
				bottom="0"
			>
				<ContentfulLottiePlayer
					lottieFile={{ url: desktopAnimation.file.url }}
					loop
				/>
			</Flex>
			<Flex
				show="tablet"
				position="absolute"
				top="0"
				left="0"
				right="0"
				bottom="0"
			>
				<ContentfulLottiePlayer
					lottieFile={{ url: mobileAnimation.file.url }}
					loop
				/>
			</Flex>
			<Paragraph
				textAlign="center"
				maxW="550px"
				position="relative"
				zIndex={1}
				padding={padding}
				largeTablet={[...option.largeTablet]}
				tablet={[...option.tablet, { maxW: '335px', padding: ['t72', 'b72', 'r20', 'l20'] }]}
			>
				{SplitText(description.description, false)}
			</Paragraph>
		</Flex>
	);
};

export default ContentfulBlockWithLottieBg;
