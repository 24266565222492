import { Item } from 'react-use-cart';
import { TProductGatsbyImage, TProductImages } from 'src/components/Sections/Product';

/**
 * Helper for formating string reading time in blogs
 * @param minutes reding time of current blog
 */

import { TProduct } from './types';

export const getReadingTime = (minutes: number | null) => {
	if (minutes) {
		const readingTime = Math.round(minutes);
		if (readingTime === 0) {
			return '1 minute read';
		}
		return `${readingTime} minute read`;
	}
	return '';
};

/**
 * Split array in smaller chunks
 * @param arr current array
 * @param chunkSize size of splited array
 */
export const chunk = (arr: Array<any>, chunkSize: number) => {
	const res = [];
	for (let i = 0; i < arr.length; i += chunkSize) {
		const chunk = arr.slice(i, i + chunkSize);
		res.push(chunk);
	}
	return res;
};

/**
 * Format price from pruduct
 * @param product current product
 */
export const formatPrice = (product: TProduct) => {
	return `$${product.variants[0].discountedPrice.toFixed(2)}`;
};

export type TFormattedCartItem = {
	id: string
	price: number
	name: string
	subTitle: string
	currency: string
	description: string
	quantity?: number
	mode: 'one-time'|'subscribe'
	interval?: number
} & TProductImages;
/**
 * Parse product info for cart
 * @param product current product
 */
export const formatProductForCart = (product: TProductGatsbyImage, mode: 'one-time'|'subscribe' = 'one-time', interval = 0): TFormattedCartItem => {
	const productData = product.variants[0];
	return {
		id: productData.id,
		price: productData.discountedPrice || productData.price,
		name: product.name,
		subTitle: product.subTitle,
		currency: 'USD',
		images: product.images,
		description: product.description || process.env.GATSBY_FALLBACK_PRODUCT_DESCRIPTION as string,
		mode,
		interval,
	};
};

export const checkoutCart = async (
	cartProducts: Item[],
	origin: string,
	onEnd?: () => void,
	onError?: (error:Error) => void
) => {
	try {
		const response = await fetch(`${process.env.GATSBY_API_URL}/website/checkout`, {
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				variants: cartProducts.map(product => ({
					id: product.id,
					quantity: product.quantity,
					name: product.name,
					description: product.description,
					image: `${origin}${product.images[0].childImageSharp.gatsbyImageData.images.fallback?.src}`,
					mode: product.mode,
					interval: product.interval,
				})),
				success_url: `${origin}/thank-you/?success=true`,
				cancel_url: `${origin}/products/`,
			}),
		});

		if (response.ok) {
			const data = await response.json();
			window.location.href = data.session.url;
		} else {
			const error = await response.json();
			onError && onError(error);
		}
	} catch (error) {
		// handle error
	} finally {
		onEnd && onEnd();
	}
};

export const getCartItemQuantity = (cartProducts: Item[] | undefined, id: string) => {
	return cartProducts?.find(product => product.id === id)?.quantity || 0;
};

export const checkoutErrorHandler = (error: Error, products: TProductGatsbyImage, setErrorMessage: (errorMsg:string)=>void) => {
	const { message } = error;
	if (message === 'Quantity not available') {
		const { items = [] } = error;
		const missingProductsNames = getMissingProductsNames(items, products);

		const errorMessage = missingProductsNames.length === 1 ? `
							Sorry, ${missingProductsNames[0]} is currently unavailable or it has been
							reserved by another customer. Please choose another product or check back later for availability.
						` : `
							Sorry, ${missingProductsNames.join(', ').replace(/,([^,]*)$/, ' and$1')} are currently unavailable or they have been
							reserved by another customer. Please choose another product or check back later for availability.
						`;
		setErrorMessage(errorMessage);
	}
};

const getMissingProductsNames = (ids: { id: string, quantityAvailable: number }[], allProducts: TProductGatsbyImage) => {
	return ids.map(({ id }) => {
		const product = allProducts.find(product => product.variants[0].id === id);
		return product?.name;
	});
};
