import { css, DefaultTheme } from 'styled-components';

const buttonBase = css<{size: keyof DefaultTheme['buttonSize']}>`
	${props => props.size && props.theme.buttonSize[props.size]};
	${props => props.theme.textVariant.button};
	height: fit-content;
`;

export const BUTTON_VARIANT = {
	primary: css<{ buttonColor: keyof DefaultTheme['buttonColor'], size: keyof DefaultTheme['buttonSize'] }>`
		border: 1px solid ${props => props.theme.buttonColor[props.buttonColor].default};
		color: ${props => props.theme.buttonColor[props.buttonColor].text};
		background: ${props => props.theme.buttonColor[props.buttonColor].default};
		border-radius: 100px;
		${buttonBase};
		&:hover {
			background: ${props => props.theme.buttonColor[props.buttonColor].hover};
			border-color: ${props => props.theme.buttonColor[props.buttonColor].hover};
		}
		&:disabled {
			cursor: not-allowed;
			background: ${props => props.theme.buttonColor.disabled.default};
			border-color: ${props => props.theme.buttonColor.disabled.default};
			color: ${props => props.theme.buttonColor.disabled.text};
		}
	`,
	secondary: css<{ buttonColor: keyof DefaultTheme['buttonColor'], size: keyof DefaultTheme['buttonSize'] }>`
		color: ${props => props.theme.buttonColor[props.buttonColor].text};
		background: ${props => props.theme.color.transparent};
		border: 1px solid ${props => props.theme.buttonColor[props.buttonColor].default};
		border-radius: 100px;
		${buttonBase};
		&:hover {
			color: ${props => props.theme.color.white};
			background: ${props => props.theme.buttonColor[props.buttonColor].hover};
			border-color: ${props => props.theme.buttonColor[props.buttonColor].hover};
		}
		&:disabled {
			cursor: not-allowed;
			background: ${props => props.theme.buttonColor.disabled.default};
			border-color: ${props => props.theme.buttonColor.disabled.text};
			color: ${props => props.theme.buttonColor.disabled.text};
		}
	`,
	blackRounded: css`
		display: inline-flex;
		align-items: center;
		justify-content: center;
		background-color: ${props => props.theme.color.textColor};
		color: ${props => props.theme.color.white};
		border-radius: 50%;
		padding: ${props => props.theme.space.s4};
		&:hover {
			background: ${props => props.theme.color.dark};
		}
		&:disabled {
			cursor: pointer;
			background: ${props => props.theme.color.disabled};
			color: ${props => props.theme.color.white};
		}
  	`,

};

export const BUTTON_SIZE = {
	large: css`
		padding: ${props => props.theme.space.s16} ${props => props.theme.space.s32};
	`,
	medium: css`
		padding: ${props => props.theme.space.s12} ${props => props.theme.space.s24};
	`,
};
