import { Center } from 'fatcat-ui-library/components/Atoms/Center';
import React, { useState } from 'react';
import { Waypoint } from 'react-waypoint';

// Atoms
import { TContentfulFloatSection } from 'src/utils/types';
import ContentfulComponentListItems from '../ContentfulListItems';
import { FloatBannerWrapper } from './FloatingBanner.atoms';

const ContentfulFloatingBanner: React.FC<TContentfulFloatSection> = (props) => {
	const {
		cards,
		top,
		mobileTop,
	} = props;
	const [animated, setAnimated] = useState(false);

	return (
		<Waypoint
			onEnter={() => {
				setAnimated(true);
			}}
		>
			<Center
				animation={animated ? ['fadeInFromBottom', '1s ease-in-out forwards'] : undefined}
				position="relative"
				zIndex={10}
				opacity="0"
			>
				<FloatBannerWrapper top={top} mobileTop={mobileTop}>
					<ContentfulComponentListItems {...cards} />
				</FloatBannerWrapper>
			</Center>
		</Waypoint>
	);
};

export default ContentfulFloatingBanner;
