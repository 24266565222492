import React from 'react';

// Types
import { TContentfulSplitBlock } from '../../utils/types';

import SplitBlockRichText from '../RichText/SplitBlockRichText';

// Atoms
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { BlockWrapper } from './ContentfulSplitBlock.atom';
import { css } from 'styled-components';

const ContentfulSplitBlock: React.FC<TContentfulSplitBlock> = (props) => {
	const {
		size,
		firstBlock,
		firstBlockPadding,
		firstBlockPaddingDesktop,
		firstBlockPaddingTablet,
		yellowBackgroundOnFirstBlock,
		secondBlockBackground,
		secondBlock,
		reversed,
		gap,
	} = props;
	const parsedSize = size.split(' ');
	const {
		backgroundImage,
		backgroundColor,
		backgroundSize,
		backgroundRepeat,
		backgroundGradient,
		backgroundPosition,
		backgroundColorOpacity,
	} = secondBlockBackground || {};

	return (
		<Flex
			overflow="hidden"
			gap={gap || '48px'}
			direction={reversed ? 'row-reverse' : undefined}
			largeTablet={['column']}
			tablet={[{ gap: '32px' }]}
			styled={css`
				${yellowBackgroundOnFirstBlock && `
					margin-bottom: 120px;
				`}

				${p => p.theme.media.largeTablet} {
					${yellowBackgroundOnFirstBlock && `
						margin-bottom: 32px;
					`}
				}

			`}
		>
			<BlockWrapper
				reversed={reversed}
				w={yellowBackgroundOnFirstBlock ? `calc(${parsedSize[0]} + 40px)` : parsedSize[0]}
				direction="column"
				justifyContent="center"
				largeTablet={[{ w: '100%' }]}
				styled={css`
					${firstBlockPadding && `padding: ${firstBlockPadding};`}
					${p => p.theme.media.desktop}{
						${firstBlockPaddingDesktop && `padding: ${firstBlockPaddingDesktop};`}
					}
					${p => p.theme.media.largeTablet}{
						${firstBlockPaddingTablet && `padding: ${firstBlockPaddingTablet};`}
					}
					
					${yellowBackgroundOnFirstBlock && `
						background-color: #FFF8DE;
						${reversed ? `
							border-radius: 20px 0 0 20px;
						 	margin-left: -40px;
							align-items: flex-start;
						 ` : `
						 	border-radius: 0 20px 20px 0;
						 	margin-right: -40px;
							align-items: flex-end;
						 `};

						> * {
							max-width: 510px;
							width: 100%;
						}

						
					`}

					${p => p.theme.media.largeTablet} {
						${yellowBackgroundOnFirstBlock && `
							border-radius: 0;
							margin-left: 0;
							margin-bottom: -80px;
							align-items: center;
							padding: 40px 20px 120px 20px;
							> * {
								max-width: auto;
								width: 100%;
							}

							
						`}
					}

					${p => p.theme.media.tablet} {
						${yellowBackgroundOnFirstBlock && `
							padding: 40px 20px 80px 20px;
						`}
					}
				`}
			>
				{SplitBlockRichText(firstBlock)}
			</BlockWrapper>
			<BlockWrapper
				reversed={reversed}
				direction="column"
				w={yellowBackgroundOnFirstBlock ? `calc(${parsedSize[parsedSize.length - 1]} - 40px)` : parsedSize[parsedSize.length - 1]}
				justifyContent="center"
				largeTablet={[{ w: '100%' }]}
				styled={css`
					${yellowBackgroundOnFirstBlock && `
						padding: 55px 0 25px 0;
						${Flex} {
							${reversed ? `
								justify-content: flex-end;
							` : `
								justify-content: flex-start;
							`}
						}

					`}	

					${p => p.theme.media.largeTablet} {

						${yellowBackgroundOnFirstBlock && `
							padding: 0 20px;
							${Flex} {
								justify-content: center;
							}
						`}
					}

				`}
				backgroundImage={backgroundImage?.file.url}
				backgroundColor={backgroundColor}
				backgroundColorOpacity={backgroundColorOpacity && (backgroundColorOpacity / 100)}
				backgroundPosition={backgroundPosition}
				backgroundRepeat={backgroundRepeat}
				backgroundSize={backgroundSize || 'cover'}
				backgroundGradient={backgroundGradient}
			>
				{SplitBlockRichText(secondBlock)}
			</BlockWrapper>
		</Flex>
	);
};
export default ContentfulSplitBlock;
