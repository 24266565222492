import React, { ReactNode } from 'react';
import { BR } from 'fatcat-ui-library/components/Atoms/Divider';

const SplitText = (text: string, hide = true) => {
	return text.split('\n')
		.reduce((prev, curr, index) => {
			if (index > 0) {
				prev.push((<BR key={index} hide={hide ? 'tablet' : undefined} />));  // eslint-disable-line
			}
			prev.push(curr);
			return prev;
		}, [] as ReactNode[]);
};

export default SplitText;
