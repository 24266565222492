import React from 'react';

// Types
import { TContentfulBanner } from '../../utils/types';
import ContentfulHeading from '../ContentfulHeading';

// Atoms
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import ContentfulLink from '../ContentfulLink';
import { Paragraph } from 'fatcat-ui-library/components/Atoms/Paragraph';

// Utils
import { trackAction } from 'src/utils/gtag';

const ContentfulBanner: React.FC<TContentfulBanner> = (props) => {
	const {
		bannerTitle: title,
		description,
		links,
		isSectionCentered,
	} = props;
	return (
		<Flex
			direction={isSectionCentered ? 'column' : 'row'}
			alignItems="center"
			gap="32px"
			tablet={['column']}
		>
			<Flex
				direction="column"
			>
				<ContentfulHeading
					{...title}
					margin={[description ? 'b48' : 'b0']}
					textAlign={isSectionCentered ? 'center' : 'left'}
					largeTablet={[{ margin: [description ? 'b24' : 'b0'] }]}
					tablet={['textCenter']}
				/>
				{description && (
					<Paragraph
						textAlign={isSectionCentered ? 'center' : 'left'}
						tablet={['textCenter']}
					>
						{description}
					</Paragraph>
				)}
			</Flex>
			{links && links.length && (
				<Flex
					justifyContent={isSectionCentered ? 'center' : 'flex-end'}
					gap="24px"
					tablet={['column', { w: 'fit-content' }]}
				>
					{links?.map((link, index) => (
						<Flex
							key={index} // eslint-disable-line
							w="fit-content"
						>
							<ContentfulLink
								{...link}
								onChange={() => trackAction(link.title, link.url)}
							/>
						</Flex>
					))}
				</Flex>
			)}
		</Flex>
	);
};

export default ContentfulBanner;
