import React, { lazy, Suspense, useState } from 'react';

// Types
import { TContentfulLottiePlayer } from '../../utils/types';

// Atoms
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import ContentfulImage from '../ContentfulImage';
import { Waypoint } from 'react-waypoint';

const LottiePlayer = lazy(() => import('../../components/Molecules/LottiePlayer/LottiePlayer'));

const ContentfulLottiePlayer: React.FC<TContentfulLottiePlayer> = (props) => {
	const { lottieFile, loop, lottiePlaceholder } = props;
	const [play, setPlay] = useState(false);
	return (
		<Waypoint
			onEnter={() => {
				if (!play) {
					setPlay(true);
				}
			}}
		>
			<Flex justifyContent="center" position="relative">
				<Suspense>
					{typeof window !== 'undefined' && (
						<Flex
							position={lottiePlaceholder ? 'absolute' : 'static'}
							top="0"
							left="0"
							right="0"
							bottom="0"
							zIndex={1}
						>
							<LottiePlayer src={lottieFile.url} loop={loop} play={play} />
						</Flex>
					)}
				</Suspense>
				{lottiePlaceholder && (
					<Flex visibility="hidden" opacity={0}>
						<ContentfulImage {...lottiePlaceholder} />
					</Flex>
				)}
			</Flex>
		</Waypoint>
	);
};
export default ContentfulLottiePlayer;
