/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
import React from 'react';
import {
	Link as GatsbyLink,
	//  navigate
} from 'gatsby';
import { css, useTheme } from 'styled-components';
// import { useLocation } from '@reach/router';

// Types
import { TNewsletterPost } from '../../../utils/types';

// Atoms
import { Link } from 'fatcat-ui-library/components/Atoms/Link';
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { Text } from 'fatcat-ui-library/components/Atoms/Text';
import { Heading } from 'fatcat-ui-library/components/Atoms/Heading';

// Organisms
import Avatar from 'fatcat-ui-library/components/Molecules/Avatar';
import JamesImage from '../../../assets/images/james.png';
import { GatsbyImage } from 'gatsby-plugin-image';

const NewsletterCard: React.FC<TNewsletterPost> = (props) => {
	const theme = useTheme();
	// const { search } = useLocation();
	const {
		slug,
		title,
		publish_date,
		thumbnail,
		authors,
	} = props;
	return (
		<Flex
			as="article"
			borderRadius="20px"
			overflow="hidden"
			maxW="calc((100% - 20px) / 2)"
			transform="translatez(0)"
			transition="all 0.1s ease-in-out"
			largeTablet={[{ maxW: '330px' }]}
			boxShadow="0px 0px 12px 0px rgba(79,72,72, 0.1)"
			hover={[{ boxShadow: '0 0 20px 0px rgb(132, 105, 105, 0.15)', zoom: '1.05' }]}
			mobile={[{ hover: [{ zoom: '1' }] }]}
			styled={css`
				&:hover{
					.reading-time {
						color: ${theme.color.primary};
					}
				}
			`}
		>
			<Link
				w="100%"
				as={GatsbyLink}
				to={`/newsletter/${slug}/`}
			>
				<Flex
					h="100%"
					direction="column"
					padding="s8"
					backgroundColor="white"
				>
					<Flex
						position="relative"
						minH="140px"
						overflow="hidden"
						direction="column"
						transform="translateZ(0)"
						borderRadius="16px 16px 0 0"
						overflowX="hidden"
						maxH="260px"
					>
						{thumbnail && <GatsbyImage image={thumbnail.gatsbyImageData} alt={title} />}
					</Flex>
					<Flex
						padding="s20"
						direction="column"
						// border={`1px solid ${theme.color.lines}`}
						borderTop="unset"
						gap="20px"
						flexGrow="1"
						borderRadius="0px 0px 4px 4px"
					>
						<Flex
							direction="column"
							flexGrow="1"
						>
							<Heading
								as="h4"
								styled={css`
									display: -webkit-box;
									-webkit-line-clamp: 3;
									-webkit-box-orient: vertical;
									overflow: hidden;
									text-overflow: ellipsis;
								`}
							>
								{title}
							</Heading>

						</Flex>
						<Flex direction="row">
							<Avatar size="32px" image={JamesImage} />
							<Flex marginLeft="s8" direction="column">
								<Text
									fontSize="s14"
									styled={css`
										color: ${props => props.theme.color.textColor};
									`}
								>
									{authors.join(', ')}
								</Text>
								<Text
									fontSize="s12"
									styled={css`
										color: ${props => props.theme.color.textColor2};
										line-height: normal;
									`}
								>
									{new Date(publish_date * 1000).toLocaleDateString('en-US', {
										weekday: undefined,
										month: 'short',
										day: 'numeric',
										year: 'numeric',
									})}
								</Text>
							</Flex>
						</Flex>
					</Flex>
				</Flex>
			</Link>
		</Flex>
	);
};

export default NewsletterCard;
