import React from 'react';
import { css } from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';
import { useLocation } from '@reach/router';

// Types
import { TContentfulLink } from '../../utils/types';
import { TextProps } from 'fatcat-ui-library/theme/props';

// Utils
import { parseContentfulLink } from 'fatcat-ui-library/utils/contentful';

// Atoms
import { Button } from 'fatcat-ui-library/components/Atoms/Button';
import { Link } from 'fatcat-ui-library/components/Atoms/Link';
import ContentfulImage from '../ContentfulImage';

import { useMixpanel } from 'gatsby-plugin-mixpanel';
import { LuArrowRight } from 'react-icons/lu';

const ContentfulLink: React.FC<Omit<TContentfulLink, '__typename' | 'identifier'> & TextProps> = (props) => {
	const mixpanel = useMixpanel();

	const parsedLink = parseContentfulLink(props as TContentfulLink);

	const { search, pathname } = useLocation();
	const urlSearchParams = new URLSearchParams(search);
	const categoryParams = urlSearchParams.get('category');

	if (parsedLink.to.startsWith('/')) {
		parsedLink.as = GatsbyLink;
	}

	const {
		linkVariant,
		linkColor,
		buttonVariant,
		buttonColor,
		buttonSize,
		onChange,
		icon,
		show,
		asset,
		event,
		...restProps
	} = props;

	const linkProps: any = {
		as: parsedLink.as,
		to: parsedLink.as !== 'a' ? parsedLink.to : undefined,
		href: parsedLink.to,
		target: parsedLink.target,
		rel: parsedLink.to.startsWith('https') ? 'nofollow noopener' : undefined,
		show,
	};
	linkProps.onClick = onChange;
	if (event) {
		linkProps.onClick = () => {
			mixpanel.track(event);
			if (onChange) {
				onChange();
			}
		};
	}

	if (categoryParams) {
		linkProps['aria-current'] = ((categoryParams && linkProps.to?.includes(categoryParams)) || linkProps.to === pathname) ? 'page' : undefined;
	}

	switch (true) {
		case !!asset: {
			return (
				<Link
					{...linkProps}
					variant={linkVariant || 'noStyle'}
					display="inline-flex"
				>
					{asset && <ContentfulImage {...asset} />}
				</Link>
			);
		}
		case !!buttonVariant: {
			return (
				<Button
					{...linkProps}
					{...restProps}
					variant={buttonVariant || 'primary'}
					buttonColor={buttonColor}
					size={buttonSize}
				>
					{parsedLink.title}
				</Button>
			);
		}
		case linkVariant === 'withArrow': {
			return (
				<Link
					{...linkProps}
					{...restProps}
					variant="withArrow"
					linkColor={linkColor}
					display="inline-flex"
					styled={css`
						gap: 12px;
						align-items: center;
					`}
				>
					{parsedLink.title}
					{linkVariant === 'withArrow' && <LuArrowRight />}
				</Link>
			);
		}
		default: {
			return (
				<Link
					{...linkProps}
					{...restProps}
					variant={linkVariant || 'primary'}
					linkColor={linkColor}
					display="inline-flex"
					styled={css`
						gap: 12px;
						align-items: center;
					`}
				>
					{icon && (
						<ContentfulImage
							{...icon}
							maxH="16px"
						/>
					)}
					{parsedLink.title}
				</Link>
			);
		}
	}
};

export default ContentfulLink;
