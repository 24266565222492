import { css, DefaultTheme } from 'styled-components';

export const ULLIST_VARIANT = {
	base: css<{ bulletColor?: keyof DefaultTheme['color'] }>`
		li::before {
			content: "•";
			font-weight: bold;
			display: inline-block;
			line-height: ${props => props.theme.lineHeight.s150};
			margin-right: clamp(8px, 0.35em, 0.35em);
			${props => props.bulletColor && `color: ${props.theme.color[props.bulletColor]};`}
		}
	`,
};

export const OLLIST_VARIANT = {
	base: css`
		> li {
			&:before {
				line-height: ${props => props.theme.lineHeight.s150};
				font-weight: ${props => props.theme.fontWeight.medium};
			}
		}
	`,
	black: css`
		> li {
			position: relative;
			display: list-item;
			&:before {
				line-height: ${props => props.theme.lineHeight.s150};
				position: absolute;
				display: inline;
				text-align: right;
				margin-left: -30px;
				font-weight: 700;
			}
			ol > li {
				list-style: none;
				counter-increment: items;
			}
			ol > li::before {
				content: "("counter(items, lower-alpha) ") ";
			}
		}
	`,
};
