import { useEffect, useMemo, useState } from 'react';
import { useGetAllProducts } from './useGetAllProducts';

export const useGetAvailableQuantity = () => {
	const allProducts = useGetAllProducts();
	const [availableQuantity, setAvailableQuantity] = useState<Record<string, number>>({});
	const [loading, setLoading] = useState<boolean>(true);

	const variantIds = useMemo(() => allProducts.map(product => product.variants[0].id).join(','), [allProducts]);

	useEffect(() => {
		try {
			fetch(`${process.env.GATSBY_API_URL}/variants/get-availability?variantIds=${variantIds}`)
				.then(response => response.json())
				.then((data) => {
					setAvailableQuantity(data);
				});
		} catch (error) {
			setLoading(false);
		} finally {
			setLoading(false);
		}
	}, [variantIds]);

	const getAvailableQuantity = (variantId: string):number => {
		return availableQuantity[variantId];
	};

	const sortOutOfStockLast = () => {
		return allProducts.sort((a, b) => {
			const aQuantity = availableQuantity[a.variants[0].id] || 0;
			const bQuantity = availableQuantity[b.variants[0].id] || 0;
			return aQuantity === 0 || bQuantity !== 0 ? 0 : -1;
		});
	};
	const getAllOrderedByWebsiteCarouselOrder = () => {
		return allProducts
			.filter(product => product.websiteCarouselOrder !== null)
			.sort((a, b) => {
				const aOrder = a.websiteCarouselOrder;
				const bOrder = b.websiteCarouselOrder;

				return aOrder > bOrder ? 1 : -1;
			});
	};

	return {
		getAvailableQuantity,
		availableQuantity,
		getAllOrderedByWebsiteCarouselOrder,
		getAllProductsOutOfStockLast: sortOutOfStockLast,
		loading,
	};
};
