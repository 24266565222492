import React from 'react';
import { css } from 'styled-components';

// Types
import { TContentfulComponentRichText } from '../../utils/types';

// Atoms
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';

import RichText from '../RichText/RichText';

const ContentfulComponentRichText: React.FC<TContentfulComponentRichText> = ({ pageContent }) => {
	return (
		<Flex
			direction="column"
			styled={css`
				a {
					width: fit-content;
				}
				p ~ a {
					margin-bottom: 16px;
				}
			`}
		>
			{RichText(pageContent)}
		</Flex>
	);
};

export default ContentfulComponentRichText;
