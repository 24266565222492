/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { css } from 'styled-components';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

// Atoms
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { Text } from 'fatcat-ui-library/components/Atoms/Text';

import ContentfulLink from 'src/contentful/ContentfulLink';

import { graphql, useStaticQuery } from 'gatsby';
import { useGetAllProducts } from 'src/utils/hooks/useGetAllProducts';
import { Center } from 'fatcat-ui-library/components/Atoms/Center';

type TNavProduct = {
	id: string[]
	onSelect: () => void
}

type TSingleProduct = {
	image: IGatsbyImageData
	title: string
	link: string
	onClick: () => void
}

const SingleProduct: React.FC<TSingleProduct> = (props) => {
	const {
		image, title, subTitle, link, onClick,
	} = props;
	return (
		<Flex
			direction="column"
			gap="16px"
			w="170px"
			styled={css`
				a {
					height: 100%;
					white-space: normal !important;
					font-size: 1rem;
					svg {
						transition: none;
					}
				}
			`}
		>
			<Flex
				as={GatsbyLink}
				to={link}
				onClick={onClick}
				w="180px"
				borderRadius="4px"
				boxShadow="0px 0px 12px 0px rgba(79,72,72, 0.1)"
				hover={[{ boxShadow: '0 0 20px 0px rgba(212,206,228)', zoom: '1.01' }]}
				paddingY="s16"
				paddingX="s16"
				direction="column"
				styled={css`
					.gatsby-image-wrapper {
						height: 140px;
					}
				`}
			>
				<GatsbyImage
					image={image}
					alt={title}
				/>
				<Center
					padding={['t16']}
					direction="column"
					w="auto"
					styled={css`
						flex: 1;
					`}
				>
					<Text
						textColor="primary"
						fontWeight="bold"
						textAlign="center"
						display="block"
					>
						{title}
					</Text>
					<Text
						textColor="primary"
						fontSize="s12"
						textAlign="center"
						display="flex"
						styled={css`
							flex: 1;
						`}
					>
						{subTitle}
					</Text>
				</Center>
			</Flex>

			<Flex w="fit-content" onClick={onClick}>
				<ContentfulLink
					url={link}
					title="Shop now"
					openInNewTab={false}
					linkVariant="withArrow"
				/>
			</Flex>
		</Flex>
	);
};

const NavProduct: React.FC<TNavProduct> = (props) => {
	const { contentfulActiveNavbarProductPromotion: { productOne, productTwo } } = useStaticQuery(graphql`
		query {
			contentfulActiveNavbarProductPromotion (
				identifier: {eq: "Active navbar promotion"}
			) {
				id
				productTwo {
					name
				}
				productOne {
					name
				}
			}
		}
		`);
	const { onSelect } = props;
	const allProducts = useGetAllProducts();

	const featuredPr = productOne.name ? [productOne.name, productTwo.name] : [allProducts[0].name, allProducts[1].name];

	const featuredProducts = allProducts.reduce((fetProducts, product) => {
		if (product.name === featuredPr[0] || product.name === featuredPr[1]) {
			fetProducts.push({
				image: product.images[0]?.childImageSharp.gatsbyImageData,
				title: product.name,
				subTitle: product.subTitle,
				link: `/products/${product.slug}`,
			});
		}
		return fetProducts;
	}, []);

	return (
		<Flex gap="24px">{featuredProducts.map((item, id) => <SingleProduct key={id} onClick={onSelect} {...item} />)}</Flex>
	);
};

export default NavProduct;
