import styled, { keyframes } from 'styled-components';

const fade = keyframes`
	0% {
		transform: translateX(0);
		opacity: 1;
		max-height: 200px;
	}
	50% {
		opacity: 0;
		max-height: 200px;
		transform: translateX(100%);
	}
	100% {
		opacity: 0;
		max-height: 0;
		transform: translateX(100%);
	}
`;

export const CartItemsWrapper = styled.div`
	display: flex;
	width: 100%;
	padding: 16px 0px;
	flex-direction: column;
	padding-right: 32px;

	${props => props.theme.media.tablet} {
		padding-right: 16px;
	}

	.fade-enter-active {
		will-change: max-height, opacity;
		transform: translateX(0);
		opacity: 1;
		max-height: 200px;
		transition: transform 300ms, opacity 300ms, max-height 300ms;
	}
	.fade-enter-done {
		will-change: max-height, opacity;
		opacity: 1;
		max-height: 200px;
		transform: translateX(0);
	}
	.fade-exit {
		will-change: max-height, opacity;
		opacity: 1;
		max-height: 200px;
		transform: translateX(0);
	}
	.fade-exit-active {
		will-change: max-height, opacity;
		animation: ${fade} 0.3s ease-in-out forwards;
		transition: transform 300ms, opacity 300ms, max-height 300ms;
	}
	.fade-exit-done {
		will-change: max-height, opacity;
		animation: ${fade} 0.3s ease-in-out forwards;
	}
`;
