import React from 'react';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { IGatsbyImageData } from 'gatsby-plugin-image/dist/src/components/gatsby-image.browser';

// Types
import { TGatsbyImage, MediaScreens } from '../../utils/types';
import { GeneralProps } from 'fatcat-ui-library/theme/props';

// Atoms
import { Wrapper } from 'fatcat-ui-library/components/Atoms/Wrapper';
import { Image } from 'fatcat-ui-library/components/Atoms/Image';

export type TContentfulImage = {
	loading?: 'lazy' | 'eager'
	objectFit?: 'cover' | 'contain'
	fillContainer?: boolean
	image?: TGatsbyImage;
	gatsbyImageShow?: MediaScreens
	gatsbyImageHide?: MediaScreens
} & TGatsbyImage & GeneralProps;

const ContentfulImage: React.FC<TContentfulImage> = (props) => {
	const {
		image: imageProps,
		gatsbyImageShow,
		gatsbyImageHide,
	} = props;
	const image = imageProps || props;
	const {
		file,
		description,
		gatsbyImageData,
		...generalProps
	} = image;

	const { loading, objectFit, fillContainer } = props;

	const isSVG = file?.contentType === 'image/svg+xml';
	const gImageStyle = fillContainer ? { width: '100%', height: '100%' } : {};
	const gatsbyImage = getImage(gatsbyImageData) as IGatsbyImageData;
	return (
		<>
			{isSVG && (
				<Image
					w="auto"
					loading="lazy"
					height={`${file?.details.image.height}`}
					width={`${file?.details.image.width}`}
					src={file.url}
					alt={description}
					show={gatsbyImageShow}
					hide={gatsbyImageHide}
					{...generalProps}
				/>
			)}
			{!isSVG && (
				<Wrapper
					w="fit-content"
					show={gatsbyImageShow}
					hide={gatsbyImageHide}
				>
					<GatsbyImage
						image={gatsbyImage}
						alt={description || ''}
						loading={loading}
						objectFit={objectFit}
						style={gImageStyle}
					/>
				</Wrapper>
			)}
		</>
	);
};

ContentfulImage.defaultProps = {
	loading: 'lazy',
	objectFit: 'cover',
	fillContainer: false,
	image: undefined,
	gatsbyImageShow: undefined,
	gatsbyImageHide: undefined,
};

export default ContentfulImage;
