import React, { useCallback } from 'react';
import {
	useStaticQuery, graphql,
} from 'gatsby';
import { useCart } from 'react-use-cart';
import { useLocation } from '@reach/router';

import { TContentfulLink } from '../../../utils/types';

// Atoms
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { Button } from 'fatcat-ui-library/components/Atoms/Button';
import { Text } from 'fatcat-ui-library/components/Atoms/Text';

// Molecules
import Section from 'fatcat-ui-library/components/Molecules/Section';
import ContentfulLink from '../../../contentful/ContentfulLink';
import HamburgerMenu from './Molecules/HamburgerMenu/HamburgerMenu';
import NavDropdown from './Molecules/NavDropdown/NavDropdown';
import NavigationMobileMenu from './Molecules/NavigationMobileMenu/NavigationMobileMenu';
import Logo from 'src/components/Molecules/Logo/Logo';

// Organisms
import Cart from '../Cart/Cart';

// Icons
import { useAppContext } from 'src/context/appContext';
import SectionBuilder from 'src/contentful/SectionBuilder';
import { Icon } from 'fatcat-ui-library/components/Atoms/Icon';
import { LuSearch, LuShoppingCart } from 'react-icons/lu';
import { css } from 'styled-components';

const CartNumber: React.FC<{ number: number }> = ({ number }) => {
	return (
		<Text
			position="absolute"
			top="0"
			left="0"
			w="20px"
			h="20px"
			transform="translate(80%, -40%)"
			borderRadius="50%"
			border="2px solid #2A2939"
			background="#D4CEE4"
			fontSize="s12"
			fontWeight="bold"
			textAlign="center"
			styled={css`
				line-height: normal;
			`}
		>
			{number}
		</Text>
	);
};

type THeader = {
	setIsSearchOpen: (e: boolean) => void
	promoBannerSection: any
}

const Header: React.FC<THeader> = ({ setIsSearchOpen, promoBannerSection }) => {
	const { pathname } = useLocation();
	const {
		toggleCart,
		closeCart,
		navOpen,
		setNavOpen,
	} = useAppContext();
	const {
		totalUniqueItems,
	} = useCart();

	const { contentfulNavigation: { headerLinks } } = useStaticQuery(graphql`
		query HeaderMenuQuery {
			contentfulNavigation(identifier: {eq: "Main Navigation"}) {
				headerLinks {
					...LinkWithItemsFields
				}
			}
		}
	`);

	const openHamMenu = (value: boolean) => {
		setNavOpen(value);
		document.body.classList.toggle('html-block-scrolling');
		if (value) {
			closeCart();
		}
	};

	const handleCart = () => {
		toggleCart();
		if (navOpen) {
			setNavOpen(false);
			document.body.classList.remove('html-block-scrolling');
		}
	};

	const generateNavLink = useCallback((link: TContentfulLink, index: number) => {
		if (link.items) return <NavDropdown key={link.title} {...link} index={index} />;
		return <ContentfulLink key={link.title} {...link} buttonSize="nav" />;
	}, []);

	return (
		<Flex
			as="header"
			position="sticky"
			top="0"
			zIndex="100"
		>
			<Flex
				direction="column"
			>
				{SectionBuilder([promoBannerSection])}

				<Flex
					boxShadow="0px 4px 20px 0px rgb(68, 40, 40, 0.08)"
					backgroundColor="white"
					zIndex="10"
				>
					<Section
						w="wide"
						as="div"
						paddingY="s18"
						largeTablet={[{ paddingY: 's16' }]}
						mobile={[{ paddingY: 's4' }]}
					>

						<Flex
							as="nav"
							aria-label="Main header navigation"
							justifyContent="space-between"
							alignItems="center"
						>
							{pathname.includes('/404') ? <Flex display="inline-block" w="auto" show="largeTablet" /> : (
								<Flex show="largeTablet" w="fit-content" alignItems="center">
									<HamburgerMenu onChange={() => openHamMenu(!navOpen)} isOpen={navOpen} />
									<Button
										h="fit-content"
										aria-label="Search"
										paddingX="s10"
										paddingY="s8"
										title="Search"
										onClick={() => { setIsSearchOpen(true); }}
										variant="noStyle"
									>
										<Icon
											cursor="pointer"
											fontSize="s24"
											as={LuSearch}
										/>
									</Button>
								</Flex>
							)}
							<Logo w="175px" h="39px" mobile={[{ w: '130px' }]} />
							{pathname.includes('/404') ? <Flex display="inline-block" w="auto" show="largeTablet" /> : (
								<>
									<Flex
										w="fit-content"
										gap="32px"
										alignItems="center"
										hide="largeTablet"
										fontWeight="semiBold"
										desktop={[{ gap: '24px' }]}
									>
										{headerLinks.map(generateNavLink)}
										<Button
											aria-label="Search"
											title="Search"
											onClick={() => { setIsSearchOpen(true); }}
											variant="noStyle"
										>
											<Icon
												cursor="pointer"
												fontSize="s24"
												as={LuSearch}
											/>
										</Button>
										<Flex w="fit-content" position="relative">
											<Button
												aria-label="Cart"
												title="Cart"
												onClick={handleCart}
												variant="noStyle"
											>
												<Icon
													cursor="pointer"
													fontSize="s24"
													as={LuShoppingCart}
												/>
											</Button>
											{totalUniqueItems ? (<CartNumber number={totalUniqueItems} />) : null}
										</Flex>
									</Flex>
									<Flex w="85px" show="largeTablet" justifyContent="flex-end">
										<Flex
											position="relative"
											display="inline-block"
											w="auto"
										>
											<Button
												aria-label="Cart"
												title="Cart"
												onClick={handleCart}
												variant="noStyle"
											>
												<Icon
													cursor="pointer"
													fontSize="s24"
													as={LuShoppingCart}
												/>
											</Button>
											{totalUniqueItems ? (<CartNumber number={totalUniqueItems} />) : null}
										</Flex>
									</Flex>
								</>
							)}
						</Flex>
					</Section>
				</Flex>
			</Flex>
			<NavigationMobileMenu
				open={navOpen}
				headerLinks={headerLinks}
				onChange={() => openHamMenu(!navOpen)}
			/>
			<Cart />
		</Flex>
	);
};

export default Header;
