/* eslint-disable no-underscore-dangle */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// Fonts
// import './src/assets/fonts/stylesheet.css';
// Swiper
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import { GatsbyBrowser } from 'gatsby';
import { useEffect } from 'react';
import { useMixpanel } from 'gatsby-plugin-mixpanel';
import React from 'react';
import mitt from 'mitt';

type Events = {
  consent: boolean;
};

const emitter = mitt<Events>();

// @ts-ignore
if (window && window._iub) {
	// @ts-ignore
	window._iub.csConfiguration.callback = {
		onConsentGiven: () => {
			emitter.emit('consent', true);
		},
	};
}

const ConsentWrapper = (props) => {
	const mixpanel = useMixpanel();

	useEffect(() => {
		emitter.on('consent', (consent: boolean) => {
			if (consent) {
				mixpanel.opt_in_tracking();
			}
		});
	}, [mixpanel]);

	return props.element;
};

const Wrapper = (props: any) => {
	return <ConsentWrapper {...props} />;
};

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = Wrapper;

export const onRouteUpdate = ({ location, prevLocation }) => {
	if (location && location.state) window.prevLocation = prevLocation ? prevLocation.pathname : null;
};
