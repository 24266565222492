import styled, { DefaultTheme } from 'styled-components';

// Atoms
import { Wrapper } from 'fatcat-ui-library/components/Atoms/Wrapper';
import { Heading } from 'fatcat-ui-library/components/Atoms/Heading';
import { Icon } from 'fatcat-ui-library/components/Atoms/Icon';

import pill from 'src/assets/images/pill.svg';

export const AccordionTitle = styled(Heading)<{ isOpen: boolean, hoverColor: keyof DefaultTheme['color'] }>`
	background-color: ${props => (props.isOpen ? `${props.theme.color[props.hoverColor]}` : 'transparent')};
	border-radius: 50px;
	position: relative;
	&:hover,
	&:hover * {
		cursor: pointer;
	}
	&:hover {
		background-color: ${props => props.theme.color[props.hoverColor]};
		border-radius: 50px;
	}
	button {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 18px 16px;
		${props => props.theme.media.mobile} {
			padding: 8px;
		}
	}
`;

export const Arrow = styled.svg<{ isOpen: boolean }>`
	position: relative;
	width: 16px;
	height: 9px;
	margin-left: 4px;
	${props => (props.isOpen ? 'transform: rotate(0deg);' : 'transform: rotate(270deg);')};
`;

export const Plus = styled(Icon)`
	position: relative;
	width: 20px;
	height: 20px;
	min-width: 20px;
	margin-left: 4px;
`;

export const AccordionWrapper = styled(Wrapper)`
	position: relative;
`;

export const AccordionContent = styled(Wrapper) <{ isOpen: boolean }>`
	height: ${props => (props.isOpen ? 'auto' : 0)};
	max-width: 800px;
	overflow: hidden;
`;

export const AccordionCircles = styled(Wrapper)`
	&:after {
		content: '';
		position: absolute;
		width: 41px;
		height: 19px;
		left: -50px;
		background: url(${pill}) no-repeat;
		background-size: contain;
		top: 23px;
		border-radius: 30%;
		z-index: 1;
		${props => props.theme.media.largeTablet}{
			width: 28px;
			left: -40px;
		}
		${props => props.theme.media.mobile}{
			top: 13px;
		}
	}
`;

export const AccordionLines = styled(Wrapper)`
	&::after {
		content: '';
		position: absolute;
		width: 1px;
		height: calc(100% + 15px);
		background-color: #1A6B50;
		top: 26px;
		bottom: 0;
		left: -31px;
		z-index: 1;
		${props => props.theme.media.largeTablet}{
			left: -26px;
		}
	}
`;
