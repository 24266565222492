import React, { useState } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { Properties } from 'csstype';
import { Waypoint } from 'react-waypoint';
import ReactMarkdown from 'react-markdown';
// Types
import { TContentfulCard, TGatsbyImage } from '../../../utils/types';

// Atoms
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { Paragraph } from 'fatcat-ui-library/components/Atoms/Paragraph';
import { Heading } from 'fatcat-ui-library/components/Atoms/Heading';

import ContentfulImage from '../../../contentful/ContentfulImage';

type TSimpleCard = Partial<TContentfulCard & { textCenter?: boolean }>

const ImageWrapper = styled(Flex) <{ animated?: boolean }>``;

const SimpleCard: React.FC<TSimpleCard & { animationDelay?: Properties['animationDelay'] }> = (props) => {
	const theme = useTheme();
	const animations = {
		bounce: css<{ animated?: boolean }>`
			transform: scale(0);
			animation-duration: 1s;
			animation-direction: ease-in-out;
			animation-fill-mode: forwards;
			${({ animated }) => animated && css`
				animation-name: ${theme.animation.bounce};
			`}
			${theme.media.tablet} {
				animation-delay: 0.1s;
			}
		`,
	};
	const {
		image,
		title,
		description,
		textCenter,
		maxMobileImageHeight,
		imageAnimation,
		animationDelay,
	} = props;
	const [animated, setAnimated] = useState(false);
	return (
		<Waypoint
			onEnter={() => {
				setAnimated(true);
			}}
		>
			<Flex
				as="article"
				direction="column"
				gap="24px"
				maxW={textCenter ? '307px' : '235px'}
				styled={css`
					img {
						max-height: 100%;
					}
				`}
			>
				<ImageWrapper
					justifyContent={textCenter ? 'center' : undefined}
					mobile={[{ maxH: maxMobileImageHeight }]}
					styled={imageAnimation && animations[imageAnimation]}
					animated={animated}
					animationDelay={animationDelay}
				>
					<ContentfulImage {...image as TGatsbyImage} />
				</ImageWrapper>
				{title && (
					<Heading
						as="h3"
						textAlign={textCenter ? 'center' : 'left'}
					>
						{title}
					</Heading>
				)}
				<Paragraph
					textAlign={textCenter ? 'center' : 'left'}
				>
					<ReactMarkdown>{description?.description || ''}</ReactMarkdown>
				</Paragraph>
			</Flex>
		</Waypoint>
	);
};

SimpleCard.defaultProps = {
	animationDelay: undefined,
};

export default SimpleCard;
