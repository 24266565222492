import React from 'react';
import { css } from 'styled-components';

// Types
import { TContentfulAccordionWrapper } from '../../utils/types';

// Atoms
import Accordion from '../../components/Molecules/Accordion/Accordion';
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';

const ContentfulComponentAccordionWrapper = (props: TContentfulAccordionWrapper) => {
	const {
		timeline,
		accordions,
		titleHoverColor,
		isArrow,
	} = props;

	return (
		<Flex>
			<Flex
				direction="column"
				justifyContent="center"
				alignItems="center"
				alignSelf="center"
				styled={css`
					.accordion-item:last-child {
						.accordion-line {
							display: none;
						}
					}
				`}
			>
				{accordions.map(accordion => (
					<Accordion
						key={accordion.accordionTitle}
						title={accordion.accordionTitle}
						content={accordion.accordionDescription?.description}
						titleAs="h4"
						contentSize="small"
						subtitle={accordion.subtitle?.description}
						isTimeline={timeline}
						hoverColor={titleHoverColor}
						useArrow={isArrow}
					/>
				))}
			</Flex>
		</Flex>
	);
};

export default ContentfulComponentAccordionWrapper;
