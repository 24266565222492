import styled from 'styled-components';

type TNavigationMenuWrapper = {
	open: boolean
}

export const NavigationMenuWrapper = styled.nav<TNavigationMenuWrapper>`
	position: fixed;
	visibility: visible;
    transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
	background-color: ${props => props.theme.color.white};
	top: 0;
	right: 0;
	height: calc(100vh - 44px);
	overflow-y: scroll;
	margin-top: 44px;
	box-shadow: 10px 0 40px rgba(0, 0, 0, 0.1);

	${props => props.theme.media.largeTablet} {
		width: 350px;
		left: 0;
		transform: ${props => props.open ? 'translateX(0)' : 'translateX(-100%)'};

		a {
			padding: 8px 0;
		}
	}

	${props => props.theme.media.mobile} {
		width: 100%;
		box-shadow: none;
		transform: ${props => props.open ? 'translateX(0)' : 'translateX(-100%)'};
		padding: 16px 0 0;
	}
`;
