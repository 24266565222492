import styled from 'styled-components';

export const InputWrapper = styled.div`
    position: relative;
    max-height: 46px;
    button {
        position: absolute;
        top: 0;
        height: 100%;
        font-size: 24px;
        cursor: pointer;
        padding: 8px 16px;
        background: ${props => props.theme.color.primary};
        border: 1px solid ${props => props.theme.color.primary};
        font-weight: 600;
        color: ${props => props.theme.color.white};
    }

    button:first-child {
        border-radius: 24px 0 0 24px;
        left: 0;
    }

    button:last-child {
        border-radius: 0 24px 24px 0;
        right: 0;
    }

    button:disabled {
        opacity: 0.5;
        background: ${props => props.theme.color.bgLight};
        border: 1px solid ${props => props.theme.color.grey200};
        color: ${props => props.theme.color.darkerGrey};
    }
`;
