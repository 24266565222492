import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import styled from 'styled-components';

export const SliderWrapper = styled(Flex)`
    flex-direction: column;
    position: relative;


    .swiper-container {
        max-width: 100vw;
        overflow: hidden;

        -webkit-mask-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 10px, rgba(0, 0, 0, 1) calc(100% - 10px), rgba(0, 0, 0, 0));
        mask-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 10px, rgba(0, 0, 0, 1) calc(100% - 10px), rgba(0, 0, 0, 0));

        ${props => props.theme.media.mobile} {
            max-width: initial;
            margin: 0 -32px;
            margin-left: calc(50vw - 420px);
        }
    }

	.swiper {
        width: 100%;

        padding: 0 5px;
        -webkit-mask-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 10px, rgba(0, 0, 0, 1) calc(100% - 10px), rgba(0, 0, 0, 0));
        mask-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 10px, rgba(0, 0, 0, 1) calc(100% - 10px), rgba(0, 0, 0, 0));
	}

    .swiper-slide {
        display: flex;
		height: auto;
		justify-content: center;
    }

    .swiper-wrapper {
        width: 100%;
    }

	@media screen and (max-width: 1119px) {
		
        .swiper {
            width: 805px;
        }
    
	}

    .swiper-custom-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
        width: 20px;
        height: 5px;
        border-radius: 2px;
        background: ${props => props.theme.color.primary};
    }

    .swiper-custom-pagination .swiper-pagination-bullet {
        width: 5px;
        height: 5px;
        background: ${props => props.theme.color.grey};
        opacity: 1;
		transition: all 0.2s ease-in-out;
        margin-left:2px;
        margin-right:2px;
    }

    .nav-slider-button::after {
		content: none;
    }

    .swiper-button-prev {
       position: relative;
       left: 0 !important;
    }

    .swiper-button-next {
        position: relative;
        right: 0 !important;
    }

    .swiper-custom-pagination {
        height: fit-content;
        width: auto !important;
        margin-bottom: 0 !important;
    }
`;
