import { css } from 'styled-components';

export const TEXT_SIZE = {
	paragraph: css`
		font-size: ${props => props.theme.fontSize.s16};
		line-height: normal;
		font-weight: 500;
		
		strong {
			font-weight: 600;
		}
	`,
	h1: css`
		font-size: ${props => props.theme.fontSize.s42};
		line-height: normal;
	`,
	h2: css`
		font-size: ${props => props.theme.fontSize.s32};
		line-height: normal;
	`,
	h3: css`
		font-size: ${props => props.theme.fontSize.s24};
		line-height: normal;
	`,
	h4: css`
		font-size: ${props => props.theme.fontSize.s20};
		line-height: normal;
	`,
};
