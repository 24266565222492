import React, { useEffect, useState } from 'react';
import { css } from 'styled-components';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import { useAppContext } from 'src/context/appContext';

// Component
import { Wrapper } from 'fatcat-ui-library/components/Atoms/Wrapper';
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { InputField } from 'fatcat-ui-library/components/Atoms/Input';
import { Button } from 'fatcat-ui-library/components/Atoms/Button';

// Asset
import { useMixpanel } from 'gatsby-plugin-mixpanel';
import { LuSearch, LuX } from 'react-icons/lu';
import { Icon } from 'fatcat-ui-library/components/Atoms/Icon';

type TSearchProductInput = {
	setIsOpen?: (e: boolean) => void
};

const SearchProductInput: React.FC<TSearchProductInput> = ({ setIsOpen }) => {
	const mixpanel = useMixpanel();

	const { search: params } = useLocation();
	const { closeCart, navOpen, setNavOpen } = useAppContext();
	const urlSearchParams = new URLSearchParams(params);
	const searchParam = urlSearchParams.get('keyword') || '';
	const [search, setSearch] = useState(searchParam || '');

	useEffect(() => { setSearch(searchParam); }, [searchParam]);

	return (
		<Flex justifyContent="center">
			<Wrapper
				as="form"
				role="search"
				position="relative"
				maxW="530px"
				styled={css`
					button:hover ~ input {
						border-color: ${({ theme }) => theme.color.primary};
					}
				`}
				onSubmit={(e) => {
					e.preventDefault();
					mixpanel.track('Search', { keyword: search });
					if (setIsOpen) {
						setIsOpen(false);
					}
					if (navOpen) {
						setNavOpen(false);
						document.body.classList.toggle('html-block-scrolling');
					}
					closeCart();
					navigate(`/search/?keyword=${search}`);
				}}
			>

				<Icon
					position="absolute"
					left="16px"
					top="50%"
					cursor="pointer"
					fontSize="s24"
					transform="translateY(-50%)"
					pointerEvents="none"
					as={LuSearch}
				/>
				{search && (
					<Button
						type="button"
						variant="noStyle"
						borderRadius="50%"
						w="24px"
						h="24px"
						position="absolute"
						right="110px"
						top="50%"
						transform="translateY(-50%)"
						hover={[{ backgroundColorHex: '#e9d9d97d' }]}
						onClick={() => { setSearch(''); }}
						css={css`
							display: inline-flex;
							align-items: center;
							justify-content: center;
						`}
						mobile={[{ right: '94px' }]}
					>
						<Icon as={LuX} w="16px" h="auto" />
					</Button>
				)}
				<Button
					type="submit"
					position="absolute"
					right="4px"
					paddingY="s4"
					paddingX="s24"
					top="50%"
					h="44px"
					transform="translateY(-50%)"
					mobile={[{ paddingX: 's16' }]}
				>
					Search
				</Button>
				<InputField
					marginBottom="s0"
					paddingRight="s136"
					paddingLeft="s48"
					borderRadius="30px"
					borderColor="#CEC2AB"
					value={search}
					onChange={(e) => { setSearch(e.target.value); }}
					tablet={[{ paddingLeft: 's42' }]}
					mobile={[{ paddingRight: 's120' }]}
				/>
			</Wrapper>
		</Flex>
	);
};

SearchProductInput.defaultProps = {
	setIsOpen: undefined,
};

export default SearchProductInput;
