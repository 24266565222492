import React, {
	useCallback, useEffect, useMemo, useState,
} from 'react';
// import loadable from '@loadable/component';
import { useGetAllProducts } from 'src/utils/hooks/useGetAllProducts';
import { Link as GatsbyLink } from 'gatsby';

// Types
import { TContentfulProductCard } from '../../utils/types';
import { BlogProductCardWrapper, ImageWrapper } from './ContentfulProductCard.atoms';
import { itemsFormat, trackAnalytic, trackFB } from 'src/utils/gtag';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Text } from 'fatcat-ui-library/components/Atoms/Text';
import { css } from 'styled-components';
import { formatPrice, formatProductForCart, getCartItemQuantity } from 'src/utils/helpers';
import { Paragraph } from 'fatcat-ui-library/components/Atoms/Paragraph';
import Ratings from 'fatcat-ui-library/components/Molecules/Ratings';
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { Heading } from 'fatcat-ui-library/components/Atoms/Heading';
import { useMixpanel } from 'gatsby-plugin-mixpanel';
import { useCart } from 'react-use-cart';
import { useAppContext } from 'src/context/appContext';
import { Button } from 'fatcat-ui-library/components/Atoms/Button';

const ContentfulComponentProductCard: React.FC<TContentfulProductCard> = (props) => {
	const {
		productId,
		identifierName,
	} = props;

	const mixpanel = useMixpanel();
	const {
		addItem, items,
	} = useCart();
	const {
		getAvailableQuantity,
		toggleCart,
		navOpen,
		setNavOpen,
	} = useAppContext();

	const allProducts = useGetAllProducts();
	const product = allProducts.find(p => p.id === productId);

	const {
		variants,
		name,
	} = product || { variants: [{}] };
	const { id: variantId } = variants[0];
	const currentInCart = getCartItemQuantity(items, variantId);

	const [rating, setRating] = useState(0);
	const [reviewCount, setReviewCount] = useState(0);

	const availableQuantity = useMemo(() => getAvailableQuantity(variantId) - currentInCart, [currentInCart, getAvailableQuantity, variantId]);
	const isOutOfStock = availableQuantity <= 0;
	const [count, setCount] = useState(() => isOutOfStock ? 0 : 1);

	const fetchReviews = useCallback(async () => {
		fetch(`/api/fetch-reviews?id=${product.id}&page=1`, {
			method: 'GET',
		}).then(res => res.json()).then((data) => {
			setRating(data.rating);
			setReviewCount(data.total);
		});
	}, [product]);

	useEffect(() => {
		if (!product) {
			return;
		}
		fetchReviews();
	}, [fetchReviews, product]);

	const handleCart = () => {
		toggleCart();
		if (navOpen) {
			setNavOpen(false);
			document.body.classList.remove('html-block-scrolling');
		}
	};

	const addingItemsToCart = () => {
		trackAnalytic('add_to_cart', {
			items: itemsFormat(items),
			item_added: {
				id: variantId,
				name,
			},
		});
		trackFB('AddToCart', {
			content_type: 'product',
			contents: [
				{
					id: variantId,
					name,
					quantity: count,
				},
			],
			value: product.variants[0].discountedPrice.toFixed(2),
			currency: 'USD',
		});
		addItem(formatProductForCart(product), count);
		mixpanel.track('Add to Cart', {
			'Product Name': name,
			'Product ID': variantId,
			'Product Price': product.variants[0].discountedPrice.toFixed(2),
			'Product Quantity': count,
		});
	};

	const handleAddToCart = (e) => {
		e.preventDefault();
		addingItemsToCart();
		setCount(1);
		handleCart();
	};
	if (!product) {
		return null;
	}

	const formattedPrice = formatPrice(product);

	return (
		<Flex direction="column" gap="16px">
			<Heading as="h3" fontSize="s24" fontWeight="bold" textAlign="left" styled={css`line-height:normal;`}>
				{identifierName}
			</Heading>
			<BlogProductCardWrapper
				as={GatsbyLink}
				onClick={() => trackAnalytic('view_item', {
					item_id: product.id,
					item_name: product.name,
				})}
				to={`/products/${product.slug}`}
			>
				<ImageWrapper>
					<GatsbyImage image={product.images[0].childImageSharp.gatsbyImageData} alt={product.name} key={product.id} />
				</ImageWrapper>
				<Flex direction="column">
					<Flex>
						<Text
							fontWeight="bold"
							textAlign="left"
							display="block"
							fontSize="s18"
							styled={css`
								display: -webkit-box;
								-webkit-box-orient: vertical;
								overflow: hidden;
								text-overflow: ellipsis;
							`}
						>
							{product.name}
						</Text>
					</Flex>
					<Text
						fontSize="s16"
						textAlign="left"
						display="flex"
						styled={css`
							flex: 1;
						`}
					>
						{product.subTitle}
					</Text>
					<Flex alignItems="center" justifyContent="flex-start" marginBottom="s8">
						{reviewCount > 0 && rating && (
							<Ratings
								rating={rating}
								size="12px"
								maxRating={5}
								gap="4px"
							/>
						)}
						{reviewCount > 0 && (
							<Text marginRight="s16" marginLeft="s8">
								{rating.toFixed(1)}
							</Text>
						)}
						{reviewCount === 0 ? 'No' : reviewCount}
						{' '}
						{reviewCount === 1 ? 'review' : 'reviews'}
					</Flex>

					<Flex
						// marginBottom="s16"
						flexGrow={1}
						overflow="hidden"
						styled={css`
							-webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) calc(100% - 16px), rgba(0, 0, 0, 1) 100%, rgba(0, 0, 0, 0));
							mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) calc(100% - 16px), rgba(0, 0, 0, 1) 100%, rgba(0, 0, 0, 0));
						`}
					>
						<Paragraph
							fontSize="s14"
							fontWeight="normal"
						>
							{product.description}
						</Paragraph>
					</Flex>
					<Flex
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						marginTop="s16"
					>
						<Text
							fontWeight="semiBold"
							fontSize="s24"
						>
							{formattedPrice}
						</Text>
						{isOutOfStock ? (
							<Button
								paddingBottom="s0"
								h="46px"
								w="fit-content"
								disabled
								size="small"
							>
								Out of stock
							</Button>
						) : (
							<Flex
								w="fit-content"
								position="relative"
							>
								<Button
									paddingBottom="s0"
									h="46px"
									w="fit-content"
									size="small"
									variant="primary"
									onClick={handleAddToCart}
									fontWeight="semiBold"
								>
									Add to cart
								</Button>
							</Flex>

						)}
					</Flex>
				</Flex>
			</BlogProductCardWrapper>
		</Flex>
	);
};

export default ContentfulComponentProductCard;
