import { css } from 'styled-components';
import yelloFlowers from 'src/assets/images/yellowFlowers.svg';
import grayFlowers from 'src/assets/images/grayFlowers.svg';
import { CTAWrapper, InnerCTAWrapper } from 'src/contentful/ContentfulCallToAction/CallToAction.atoms';
import { Heading } from 'fatcat-ui-library/components/Atoms/Heading';
import { Paragraph } from 'fatcat-ui-library/components/Atoms/Paragraph';
import { BlockWrapper } from 'src/contentful/ContentfulSplitBlock/ContentfulSplitBlock.atom';
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';

export const RESPONSIVE = {
	bgLightBeige: css`
		background: ${props => props.theme.color.lightBeige};
	`,
	bgYellowFlower: css`
		background-image: url(${yelloFlowers});
	`,
	bgGrayFlower: css`
		background-image: url(${grayFlowers});
	`,
	bgLightGray: css`
		background: ${props => props.theme.color.bgLight};
	`,
	paddingT40: css`
		& > div {
			padding-top: 40px;
		}
	`,
	paddingT56: css`
		& > div {
			padding-top: 56px;
		}
	`,
	paddingT56B56: css`
		& > div {
			padding-top: 56px;
			padding-bottom: 56px;
		}
	`,
	paddingT48B32: css`
		& > div {
			padding-top: 48px;
			padding-bottom: 32px;
		}
	`,
	paddingB64: css`
		& > div {
			padding-bottom: 64px;
		}
	`,
	paddingB80: css`
		& > div {
			padding-bottom: 80px;
		}
	`,
	paddingB0: css`
		& > div {
			padding-bottom: 0;
		}
	`,
	paddingT0: css`
		& > div {
			padding-top: 0;
		}
	`,
	paddingB136: css`
		padding-bottom: 136px !important;
	`,
	paddingB128: css`
		padding-bottom: 128px !important;
	`,
	tabListWrapper: css`
		${props => props.theme.media.largeTablet} {
			&> div {
				padding-left: 20px;
				padding-right: 20px;

				h2 {
					margin-bottom: 8px;
					font-weight: 600;
				}

				${CTAWrapper} {
					padding: 0;
					justify-content: center;
				}

				${InnerCTAWrapper} {
					align-items: center;
					
					${Paragraph} {
						text-align: center;
					}

					${Heading} {
						display: none
					}

					${Flex} {

						justify-content: center;
						width: 100%;
					}
				}

				${BlockWrapper}:last-child {
					width: auto;
				}
			}
			
		}
		${props => props.theme.media.mobile} {
			&> div {
				

				${InnerCTAWrapper} {
					${Paragraph} {
						text-align: left !important;
					}

					${Heading} {
						display: none
					}

					${Flex} {
						justify-content: flex-start;
					}
				}
			}
			
		}
	`,
};
