import React from 'react';
import {
	Link as GatsbyLink,
	//  navigate
} from 'gatsby';
import { css, useTheme } from 'styled-components';
// import { useLocation } from '@reach/router';

// Types
import { TBlogPostCard } from '../../../utils/types';

// Atoms
import { Link } from 'fatcat-ui-library/components/Atoms/Link';
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { Text } from 'fatcat-ui-library/components/Atoms/Text';
import { Heading } from 'fatcat-ui-library/components/Atoms/Heading';

// Organisms
import ContentfulImage from '../../../contentful/ContentfulImage';

const BlogCard: React.FC<TBlogPostCard> = (props) => {
	const theme = useTheme();
	// const { search } = useLocation();
	const {
		slug,
		title,
		publishedAt,
		excerpt,
		previewImage,
		heroImage,
		fields,
		// tags,
	} = props;
	const image = previewImage || heroImage;

	// const handleTagClick = (tagName: string) => (e: any) => {
	// 	e.preventDefault();
	// 	const tag = tagName.toLowerCase();
	// 	const urlSearchParams = new URLSearchParams(search);
	// 	let currentTags = urlSearchParams.get('tags');
	// 	if (currentTags) {
	// 		if (!currentTags.includes(tag)) {
	// 			currentTags = `${currentTags} ${tag}`;
	// 			urlSearchParams.set('tags', currentTags);
	// 			urlSearchParams.delete('page');
	// 			navigate(`/blog/?${urlSearchParams.toString()}`);
	// 		}
	// 	} else {
	// 		urlSearchParams.set('tags', tag);
	// 		urlSearchParams.delete('page');
	// 		navigate(`/blog/?${urlSearchParams.toString()}`);
	// 	}
	// };

	return (
		<Flex
			as="article"
			borderRadius="20px"
			overflow="hidden"
			maxW="calc((100% - 40px) / 3)"
			transform="translatez(0)"
			transition="all 0.1s ease-in-out"
			largeTablet={[{ maxW: '330px' }]}
			boxShadow="0px 0px 12px 0px rgba(79,72,72, 0.1)"
			hover={[{ boxShadow: '0 0 20px 0px rgb(132, 105, 105, 0.15)', zoom: '1.05' }]}
			mobile={[{ hover: [{ zoom: '1' }] }]}
			styled={css`
				&:hover{
					.reading-time {
						color: ${theme.color.primary};
					}
				}
			`}
		>
			<Link
				w="100%"
				as={GatsbyLink}
				to={`/blog/${slug}/`}
			>
				<Flex
					h="100%"
					direction="column"
					padding="s8"
					backgroundColor="white"
				>
					<Flex
						position="relative"
						maxH="140px"
						minH="140px"
						overflow="hidden"
						direction="column"
						transform="translateZ(0)"
						borderRadius="16px 16px 0 0"
						overflowX="hidden"
					>
						<ContentfulImage {...image} />
					</Flex>
					<Flex
						padding="s20"
						direction="column"
						// border={`1px solid ${theme.color.lines}`}
						borderTop="unset"
						gap="20px"
						flexGrow="1"
						borderRadius="0px 0px 4px 4px"
					>
						<Flex
							direction="column"
							flexGrow="1"
						>
							<Heading
								as="h4"
								styled={css`
									display: -webkit-box;
									-webkit-line-clamp: 2;
									-webkit-box-orient: vertical;
									overflow: hidden;
									text-overflow: ellipsis;
								`}
							>
								{title}
							</Heading>
							<Text>{publishedAt}</Text>
						</Flex>
						<Flex>
							<Text
								h="fit-content"
								as="p"
								textColor="secondary"
								fontWeight="medium"
								styled={css`
									display: -webkit-box;
									-webkit-line-clamp: 3;
									-webkit-box-orient: vertical;
									overflow: hidden;
									text-overflow: ellipsis;
								`}
							>
								{excerpt}
							</Text>
						</Flex>
						{fields?.readingTime && (
							<Text
								className="reading-time"
								textColor="primary"
								fontWeight="medium"
							>
								{fields?.readingTime}
							</Text>
						)}
					</Flex>
				</Flex>
			</Link>
		</Flex>
	);
};

export default BlogCard;
