import React from 'react';

// Atoms
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { NavigationMenuWrapper } from './NavigationMobileMenu.atom';

import ContentfulLink from '../../../../../contentful/ContentfulLink';

// Types
import { TContentfulLink } from '../../../../../utils/types';

import NavigationMobileDropdown from './NavigationMobileDropdown/NavigationMobileDropdown';

type TNavigationMobileMenu = {
	open: boolean
	headerLinks: TContentfulLink[]
	onChange: () => void
}

const NavigationMobileMenu: React.FC<TNavigationMobileMenu> = (props) => {
	const {
		open,
		headerLinks,
		onChange,
	} = props;

	return (
		<NavigationMenuWrapper open={open} aria-label="Main Mobile">
			<Flex
				show="largeTablet"
				direction="column"
				backgroundColor="white"
				padding={['t96', 'l20', 'r20', 'b20']}
				mobile={[{
					padding: ['t48', 'l20', 'r20', 'b20'],
				}]}
				minH="100%"
			>
				<Flex flexGrow={1}>
					<Flex
						direction="column"
						fontWeight="semiBold"
						gap="8px"
						flexGrow={1}
					>
						{headerLinks.map((link: TContentfulLink) => {
							if (link.items) return <NavigationMobileDropdown key={link.title} {...link} onChange={onChange} />;
							return <ContentfulLink key={link.title} {...link} onChange={onChange} buttonSize="nav" linkColor="primary" />;
						})}
					</Flex>
				</Flex>
			</Flex>
		</NavigationMenuWrapper>
	);
};

export default NavigationMobileMenu;
