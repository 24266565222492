import styled from 'styled-components';
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';

export const TitleWrapper = styled(Flex)`
	cursor: pointer;
	transition: color 0.2s ease-in-out;
	/* font-weight: ${props => props.theme.fontWeight.semiBold}; */

	svg {
		stroke-width: 1.5px;
		width: 12px;
		height: auto;
	}
`;
