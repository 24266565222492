import React, { useRef } from 'react';

// Types
import {
	TContentfulCard, TContentfulComponentList, TContentfulSection, TContentfulSplitBlock,
} from '../../utils/types';
import { css } from 'styled-components';

// Atoms
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { Grid } from 'fatcat-ui-library/components/Atoms/Grid';

// Local managers
import ContentfulCard from '../ContentfulCard';
import {
	CarouselWrapper, SwiperWrapper, SwiperWrapperCarousel, Tab,
} from './ContentfulListItems.atoms';
import ContentfulSplitBlock from '../ContentfulSplitBlock';
import { Swiper, SwiperSlide, SwiperRef } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Wrapper } from 'fatcat-ui-library/components/Atoms/Wrapper';

import { Icon } from 'fatcat-ui-library/components/Atoms/Icon';
import ContentfulComponentListItemsNewsletter from './newsletter';
import SectionBuilder from '../SectionBuilder';
import { LuChevronLeft, LuChevronRight } from 'react-icons/lu';

const ContentfulComponentListItems = (props: TContentfulComponentList) => {
	const {
		display,
		direction,
		alignItems,
		justifyContent,
		gap,
		items,
		rowGap,
		gridTemplateColumns,
		mobileGrid,
		tabletGrid,
		wrap,
	} = props;

	const [currentTab, setCurrentTab] = React.useState(0);
	const sliderRef = useRef<SwiperRef>(null);

	const renderList = () => {
		if (display === 'newsletter') {
			return <ContentfulComponentListItemsNewsletter {...props} />;
			// return <div />;
		}

		if (display === 'grid') {
			// Add grid view
			return (
				<Grid
					alignItems={alignItems}
					justifyContent={justifyContent}
					justifyItems={justifyContent}
					gap={gap}
					gridTemplateColumns={gridTemplateColumns}
					largeTablet={tabletGrid ? [{ gridTemplateColumns: tabletGrid }] : []}
					mobile={mobileGrid ? [{ gridTemplateColumns: mobileGrid }] : []}
					rowGap={rowGap}
				>
					{(items || []).map((card, index) => (
						<ContentfulCard
							key={index} // eslint-disable-line
							card={card as TContentfulCard}
							animationDelay={`0.${index}s`}
						/>
					))}
				</Grid>
			);
		}
		if (display === 'tabs') {
			return (
				<Flex direction="column" marginTop="s16">
					<Flex
						direction="row"
						gap="48px"
						justifyContent="center"
						alignItems="center"
						marginBottom="s48"
						mobile={['column', {
							alignItems: 'flex-start', justifyContent: 'flex-start', gap: '24px', marginBottom: 's24',
						}]}
					>
						{(items as TContentfulSplitBlock[] || []).map((item, index) => (
							<Tab
								key={item.identifier}
								onClick={() => {
									setCurrentTab(index);
									sliderRef.current?.swiper?.slideToLoop(index);
								}}
								className={index === currentTab ? 'active' : ''}
							>
								{item.identifier}
							</Tab>
						))}
					</Flex>
					<SwiperWrapper>
						<Swiper
							modules={[Navigation, Pagination]}
							spaceBetween={32}
							slidesPerView={1}
							ref={sliderRef}
							loop
							pagination={{ clickable: true }}
							navigation={{
								nextEl: '.swiper-navigation-right',
								prevEl: '.swiper-navigation-left',
							}}
							onSlideChange={(swiper) => {
								setCurrentTab(swiper.realIndex);
							}}

						>
							{(items as TContentfulSplitBlock[] || []).map((item, index) => (
								<SwiperSlide
									key={item.identifier}
									style={{
										display: 'flex',
										justifyContent: 'center',
									}}
								>
									<Flex
										w="fit-content"
									>
										<ContentfulSplitBlock {...item} />
									</Flex>
								</SwiperSlide>
							))}
						</Swiper>
						<Wrapper
							display="none"
							largeTablet={[{ display: 'block' }]}
							styled={css`
								margin-top: -14px;
							`}
						>
							<div className="swiper-navigation swiper-navigation-left">
								<Icon as={LuChevronLeft} w="25px" h="auto" />
							</div>
							<div className="swiper-navigation swiper-navigation-right">
								<Icon as={LuChevronRight} w="25px" h="auto" />
							</div>
						</Wrapper>
					</SwiperWrapper>
				</Flex>
			);
		}
		if (display === 'swiper') {
			return (
				<Flex direction="column" marginTop="s16">
					<SwiperWrapper>
						<Swiper
							modules={[Navigation, Pagination]}
							spaceBetween={0}
							slidesPerView={1}
							ref={sliderRef}
							loop
							pagination={{ clickable: true }}
							navigation={{
								nextEl: '.swiper-navigation-right',
								prevEl: '.swiper-navigation-left',
							}}
						>
							{(items as TContentfulCard[] || []).map((item, index) => (
								<SwiperSlide
									key={index} // eslint-disable-line
									style={{
										display: 'flex',
										justifyContent: 'center',
									}}
								>
									<Flex
										w="fit-content"
									>
										<ContentfulCard
											card={item as TContentfulCard}
										/>
									</Flex>
								</SwiperSlide>
							))}
						</Swiper>
						<Wrapper
							largeTablet={[{ display: 'block' }]}
							styled={css`
								margin-top: -18px;
							`}
						>
							<div className="swiper-navigation swiper-navigation-left">
								<Icon as={LuChevronLeft} w="25px" h="auto" />
							</div>
							<div className="swiper-navigation swiper-navigation-right">
								<Icon as={LuChevronRight} w="25px" h="auto" />
							</div>
						</Wrapper>
					</SwiperWrapper>
				</Flex>
			);
		}
		if (display === 'carousel') {
			return (
				<Flex direction="column" marginTop="s16">
					<SwiperWrapperCarousel>
						<Swiper
							modules={[Autoplay]}
							spaceBetween={0}
							slidesPerView={1}
							ref={sliderRef}
							loop
							// lazy={{ loadPrevNext: true }}
							autoplay={{ delay: 5000 }}
						>
							{(items as TContentfulSection[] || []).map((item, index) => (
								<SwiperSlide
									key={index} // eslint-disable-line
									style={{
										display: 'flex',
										justifyContent: 'center',
										width: '100%',
									}}
								>
									<CarouselWrapper>
										{SectionBuilder([item])}
									</CarouselWrapper>
								</SwiperSlide>
							))}
						</Swiper>
					</SwiperWrapperCarousel>
				</Flex>
			);
		}

		const flexWrap: any = {};

		if (wrap && wrap !== 'always') {
			flexWrap[wrap] = ['wrap'];
		}

		return (
			<Flex
				direction={direction}
				alignItems={alignItems}
				justifyContent={justifyContent}
				gap={gap}
				{...flexWrap}
				wrap={wrap === 'always'}
			>
				{(items || []).map((card, index) => (
					<ContentfulCard
						key={index} // eslint-disable-line
						card={card as TContentfulCard}
						animationDelay={`0.${index}s`}
					/>
				))}
			</Flex>
		);
	};
	return renderList();
};

export default ContentfulComponentListItems;
