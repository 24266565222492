import styled from 'styled-components';
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';

export const BlockWrapper = styled(Flex) <{ reversed: boolean }>`
	& > *:first-child {
		margin-top: 0;
	}
	& > *:last-child {
		margin-bottom: 0;
	}
	& > br:last-child {
		display: none;
	}
	a {
		width: fit-content;
	}
	img {
		align-self: ${({ reversed }) => reversed ? 'flex-start' : 'flex-end'};

		${props => props.theme.media.largeTablet} {
			align-self: center;
		}
	}
	.embedded-video-player {
		justify-content: ${({ reversed }) => reversed ? 'flex-start' : 'flex-end'};
		${props => props.theme.media.largeTablet} {
			justify-content: center;
		}
		& > div {
			border-radius: 8px;
		}
		.play-button-wrapper {
			bottom: 20px;
			right: 20px;
			* {
				font-size: ${props => props.theme.fontSize.s16};
			}
		}
		.embedded-video-title {
			font-size: ${props => props.theme.fontSize.s20};
			top: 20px;
			left: 20px;
		}
	}
`;
