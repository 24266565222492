import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import styled from 'styled-components';

export const BlogProductCardWrapper = styled(Flex)`
    flex-direction: row;
    border-radius: 4px;
    overflow: hidden;
	padding: 26px;
	box-shadow: 0px 0px 12px 0px rgba(79,72,72, 0.1);
	.gatsby-image-wrapper {
		height: 228px;
	}
    margin-bottom: 24px;
    gap: 24px;
    max-height: 280px;

    ${props => props.theme.media.mobile} {
        flex-direction: column;
        max-height: 100%;
        align-items: center;
    }
`;

export const ImageWrapper = styled(Flex)`
    width: 255px;
`;
