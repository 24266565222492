// Types
import { TContentfulBase } from '../utils/types';

// Contentful component mappers
import ContentfulComponentBanner from './ContentfulBanner';
import ContentfulComponentHeading from './ContentfulHeading';
import ContentfulComponentHero from './ContentfulHero';
import ContentfulComponentListItems from './ContentfulListItems';
import ContentfulComponentPartnershipBanner from './ContentfulPartnershipBanner';
import ContentfulComponentSplitBlock from './ContentfulSplitBlock';
import ContentfulComponentTestimonialSllider from './ContentfulTestimonialSllider';
import ContentfulSpacer from './ContentfulSpacer';
import ContentfulComponentCallToAction from './ContentfulCallToAction';
import ContentfulGatsbyImage from './ContentfulImage';
import ContentfulFloatSection from './ContentfulFloatingBanner';
import ContentfulEmbedVideo from './ContentfulEmbedVideo';
import ContentfulAppBanner from './ContentfulAppBanner';
import ContentfulHomePageProductsSlider from './ContentfulProductSlider';
import ContentfulBrowseHealthConcern from './ContentfulHealthConcerns';
import ContentfulLink from './ContentfulLink';
import ContentfulText from './ContentfulText';
import ContentfulComponentAccordionWrapper from './ContentfulAccordionWrapper';
import ContentfulComponentRichText from './ContentfulRichText';
import ContentfulLottiePlayer from './ContentfulLottiePlayer';
import ContentfulComponentMarquee from './ContentfulMarquee';
import ContentfulComponentBlockWithLottieBg from './ContentfulBlockWithLottieBg';
import ContentfulProductCard from './ContentfulProductCard';

const componentObject: Record<string, any> = {
	ContentfulAppBanner,
	ContentfulBrowseHealthConcern,
	ContentfulComponentAccordionWrapper,
	ContentfulComponentBanner,
	ContentfulComponentCallToAction,
	ContentfulComponentBlockWithLottieBg,
	ContentfulComponentHeading,
	ContentfulComponentHero,
	ContentfulComponentListItems,
	ContentfulComponentMarquee,
	ContentfulComponentPartnershipBanner,
	ContentfulComponentRichText,
	ContentfulComponentSplitBlock,
	ContentfulComponentTestimonialSllider,
	ContentfulEmbedVideo,
	ContentfulFloatSection,
	ContentfulGatsbyImage,
	ContentfulHomePageProductsSlider,
	ContentfulLink,
	ContentfulLottiePlayer,
	ContentfulSpacer,
	ContentfulText,
	ContentfulProductCard,
};

const ComponentManager = (component: TContentfulBase) => {
	const { __typename: typename } = component;
	if (!componentObject[typename]) return null;
	return componentObject[typename](component);
};

export default ComponentManager;
