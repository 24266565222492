import React from 'react';

// Atoms

// Assets
import { InputWrapper } from './Counter.atoms';
import { InputField } from 'fatcat-ui-library/components/Atoms/Input';

type TCounter = {
	count: number;
	increment: () => void;
	decrement: () => void;
	incrementDisabled?: boolean;
	disabled?: boolean;
	setCount: (count: number) => void;
};

const Counter: React.FC<TCounter> = (props) => {
	const {
		count,
		increment,
		decrement,
		incrementDisabled,
		disabled,
		setCount,
	} = props;

	return (
		<InputWrapper>
			<button
				type="button"
				disabled={count === 1 || disabled}
				onClick={decrement}
			>
				-
			</button>
			<InputField
				marginBottom="s0"
				borderRadius="24px"
				h="46px"
				w="150px"
				textAlign="center"
				value={count}
				onChange={(e) => {
					const value = parseInt(e.target.value, 10);
					setCount(value);
				}}
				onFocus={(e) => {
					e.target.select();
				}}
			/>
			<button
				type="button"
				disabled={incrementDisabled || disabled}
				onClick={increment}
			>
				+
			</button>
		</InputWrapper>

	);
};

export default Counter;

Counter.defaultProps = {
	incrementDisabled: false,
	disabled: false,
};
