import { css } from 'styled-components';
import { LINK_BASE } from 'fatcat-ui-library/theme/definitions';

export const LINK_VARIANT = {
	navigation: css`
		${LINK_BASE}
		&[aria-current="page"],
		&[aria-current="page"] ~ svg {
			color: ${props => props.theme.textColor.linkColor};
		}
	`,
	withArrow: css`
		${LINK_BASE}
		font-weight: ${props => props.theme.fontWeight.semiBold};
		font-size: ${props => props.theme.fontSize.s20};
		${props => props.theme.media.tablet} {
			font-size: ${props => props.theme.fontSize.s16};
		}
	`,
	ghostly: css`
		${LINK_BASE}
		&:hover {
			opacity: 0.8;
		}
	`,
	underlineLight: css`
		${LINK_BASE};
		text-decoration: underline;
	`,
};
