import React from 'react';

// Atoms
import {
	HamburgerMenuWrapper,
	HamburgerMenuBarTop,
	HamburgerMenuBarBottom,
	HamburgerMenuBarCenter,
} from './HamburgerMenu.atoms';

type THamburgerMenu = {
	isOpen: boolean,
	onChange: () => void,
}

const HamburgerMenu: React.FC<THamburgerMenu> = ({ isOpen, onChange }) => (
	<HamburgerMenuWrapper
		isOpen={isOpen}
		onClick={onChange}
		title={isOpen ? 'Close menu' : 'Open menu'}
		aria-label={isOpen ? 'Close menu' : 'Open menu'}
	>
		<HamburgerMenuBarTop />
		<HamburgerMenuBarCenter />
		<HamburgerMenuBarBottom />
	</HamburgerMenuWrapper>
);
export default HamburgerMenu;
