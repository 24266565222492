/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { css } from 'styled-components';
// Types
import { TContentfulLink } from 'src/utils/types';

// Atoms
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { Link } from 'fatcat-ui-library/components/Atoms/Link';
import ContentfulLink from 'src/contentful/ContentfulLink';
import NavProduct from '../NavProduct/NavProduct';

// Assets

import {
	DropdownItemsContainer,
	DropdownItemsWrapper,
	DropdownWrapper,
} from './NavDropdown.atom';
import { Icon } from 'fatcat-ui-library/components/Atoms/Icon';
import { LuChevronDown } from 'react-icons/lu';

const makeDropdownItems = (items: TContentfulLink[]) => items.map(link => (<ContentfulLink key={link.title} {...link} buttonSize="nav" />));

const NavDropdown: React.FC<TContentfulLink & {index: number}> = (props) => {
	const {
		title,
		items,
		linkVariant,
		linkColor,
		page,
		url,
		index,
	} = props;
	const [isOpen, setIsOpen] = useState(false);

	const closeDropdown = () => {
		setIsOpen(false);
	};

	const openDropdown = () => {
		setIsOpen(true);
	};

	return (
		<DropdownWrapper onMouseEnter={openDropdown} closeDropdown={!isOpen}>
			<Flex
				alignItems="center"
				gap="10px"
				styled={css`svg { transform: translateY(-1px)}`}
			>
				{page || url ? (
					<ContentfulLink {...props} buttonSize="nav" />
				) : (
					<Link // eslint-disable-line
						as="span"
						tabIndex={0}
						variant={linkVariant || 'primary'}
						linkColor={linkColor}
					>
						{title}
					</Link>
				)}
				<Icon as={LuChevronDown} w="16px" h="auto" />
			</Flex>
			<DropdownItemsContainer firstItem={index === 0}>
				<DropdownItemsWrapper gap="40px">
					<Flex onClick={closeDropdown} direction="column" gap="16px" styled={css` a { font-weight: 500; }`}>
						{items && makeDropdownItems(items)}
					</Flex>
					{title === 'PRODUCTS' && <NavProduct onSelect={closeDropdown} id={['1234', '2345']} />}
				</DropdownItemsWrapper>
			</DropdownItemsContainer>
		</DropdownWrapper>
	);
};

export default NavDropdown;
