import { css } from 'styled-components';

export const SPACE = {
	s46: '46px',
	s100: '100px',
	s128: '128px',
	s146: '146px',
};

export const MEDIA = {
	desktopBig: '@media screen and (max-width: 1680px)',
};

export const SECTION_WIDTH = {
	normal: '1068px',
	wide: '1100px',
	standardDesktop: '1440px',
};

export const FONT_FAMILY = {
	main: 'Lato',
};

export const FONT_SIZE = {

};

export const LINE_HEIGHT = {
	s140: '1.4',
};

export const SectionStyle = css`
`;
