import React, { useMemo } from 'react';
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import ContentfulHeading from '../ContentfulHeading';
import ContentfulLink from '../ContentfulLink';
import { TCallToActionSection } from 'src/utils/types';
import { CTAWrapper, InnerCTAWrapper } from './CallToAction.atoms';
import SplitBlockRichText from '../RichText/SplitBlockRichText';
import { trackAction } from 'src/utils/gtag';

const ContentfulCallToAction = (props: TCallToActionSection) => {
	const {
		ctaTitle: title,
		content,
		ctaLink,
		options,
		stickToTheEdges,
		alignCenter,
		haveBottomPadding,
		whiteBackground,
	} = props;
	const containerProps = useMemo(() => options?.reduce((acc, { key, value }) => ({ ...acc, [key]: value }), {}), [options]);
	return (
		<CTAWrapper
			stickToTheEdges={stickToTheEdges}
			alignCenter={alignCenter}
			{...containerProps}
			haveBottomPadding={haveBottomPadding}
			whiteBackground={whiteBackground}
		>
			<InnerCTAWrapper alignCenter={alignCenter}>
				{title && (
					<ContentfulHeading
						{...title}
						textAlign="start"
						marginBottom="s32"
					/>
				)}
				{content && SplitBlockRichText(content)}
				{ctaLink && (
					<Flex w="fit-content" fontSize="s20">
						<ContentfulLink {...ctaLink} onChange={() => { trackAction(ctaLink.title, ctaLink.url); }} />
					</Flex>
				)}
			</InnerCTAWrapper>
		</CTAWrapper>
	);
};

export default ContentfulCallToAction;
