import styled from 'styled-components';
import { Wrapper } from 'fatcat-ui-library/components/Atoms/Wrapper';
import { Heading } from 'fatcat-ui-library/components/Atoms/Heading';
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';

export const AddToCartWrapper = styled(Wrapper)`
    background: ${props => props.theme.color.bgLight};
    padding: 24px;
    border-radius: 20px;
    margin-bottom: 16px;

    ${props => props.theme.media.mobile} {
        padding: 8px;
        ${Flex} {
            justify-content: center;
        }
        
        @media (min-height: 800px) {
            margin-bottom: 0;
            position: fixed;
            left: 0;
            box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1);
            border-radius: 20px 20px 0 0;
            z-index: 10;
            transition: bottom 0.5s ease-in-out;
            bottom: -100vh;

            &.add-to-cart-visible {
                bottom: 0;
            }
        }
    }
`;

export const DetailsList = styled.ul`
    padding: 0;
    margin: 16px 0 0 16px;
    list-style: disc;
    font-size: 12px;
    line-height: 20px;
    color: ${props => props.theme.color.textColor2};

    ${props => props.theme.media.mobile} {
        text-align: center;
        margin: 8px 0 0 0;
        line-height: normal;
        font-size: 12px;

        li {
            list-style: none;
            display: inline;
        }

        li::after {
            content: '•';
            margin: 0 8px;
        }

        li:last-child::after {
            content: '';
            margin: 0;
        }
    }

    &.condensed {
        text-align: center;
        margin: 8px 0 0 0;
        line-height: normal;
        font-size: 12px;

        li {
            list-style: none;
            display: inline;
        }

        li::after {
            content: '•';
            margin: 0 8px;
        }

        li:last-child::after {
            content: '';
            margin: 0;
        }
    }
`;

export const SwiperContainer = styled.div`
    width: 100%;
    overflow: visible;
    position: relative;

	${props => props.theme.media.mobile} {
		width: 100%;
        margin-left: 0;
        margin-right: 0;
	}
`;

export const ProductHeading = styled(Heading)`
    line-height: 1.2;
`;
