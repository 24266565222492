import { graphql, useStaticQuery } from 'gatsby';

export const useGetAllProducts = () => {
	const { allProducts: { nodes }, allFile } = useStaticQuery(graphql`
        query allProducts {
			allProducts {
				nodes {
					id
					name
					description
					slug
					subTitle
					websiteCarouselOrder
					reviews {
						rating
						count
					}
					# productInfoPdf {
					# 	url
					# }
					variants {
						id
						name
						price
						discountedPrice
						images {
							blurHash
							id
							urls {
								_2070x1200
								_1035x600
								_518x300
								_258x150
							}
						}
					}
					productHealthConcerns {
						healthConcern {
							name
						}
					}
				}
			}
			allFile(filter: {id: {regex: "/product-img/" }}) {
				nodes {
					id
					childImageSharp {
						gatsbyImageData
					}
				}
			}
		}
    `);

	return nodes.map((node) => {
		const productImages = allFile.nodes.filter(file => file.id.includes(node.id))
			.sort((a, b) => a.id.localeCompare(b.id));
		return {
			...node,
			images: productImages,
		};
	});
};
