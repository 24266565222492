import { keyframes } from 'styled-components';

const fadeInFromBottom = keyframes`
	from {
		opacity: 0;
		transform: translateY(100px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
`;

const bounce = keyframes`
	from {
		transform: scale(0);
	}
	to {
		transform: scale(1);
	}
`;

export const ANIMATIONS = {
	fadeInFromBottom,
	bounce,
};
