import React from 'react';
import { Link as GatsbyLink } from 'gatsby';

// Atoms
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { Heading } from 'fatcat-ui-library/components/Atoms/Heading';
import ContentfulImage from '../ContentfulImage';
import { HealthWrapper } from './HealthConcernItem.atoms';
// Types
import { THealthConcern } from 'src/utils/types';

const HealthConcernItem: React.FC<THealthConcern> = (props) => {
	const {
		title,
		image,
		imageOnHover,
		healthBackgroundColor,
		linkTo,
	} = props;

	return (
		<HealthWrapper
			as={GatsbyLink}
			maxW="200px"
			h="224px"
			direction="column"
			position="relative"
			padding={['t24', 'b32', 'l32', 'r32']}
			alignItems="center"
			w="fit-content"
			to={linkTo}
			bgColor={healthBackgroundColor}
			borderRadius="4px"
			largeTablet={['clearSideSpacing', { h: 'auto', w: '100%' }]}
		>
			<Flex
				className="health-image"
				position="relative"
				justifyContent="center"
				zIndex="1"
				largeTablet={['justifyContentCenter']}
			>
				<ContentfulImage {...image} paddingBottom="s24" w="140px" mobile={[{ w: '100px' }]} />
			</Flex>
			<Flex
				className="health-image-hover"
				position="absolute"
				justifyContent="center"
				opacity="0"
				zIndex="1"
				largeTablet={['justifyContentCenter']}
			>
				<ContentfulImage {...imageOnHover} paddingBottom="s24" w="140px" mobile={[{ w: '100px' }]} />
			</Flex>
			<Heading
				position="relative"
				zIndex="1"
				as="h4"
				fontWeight="semiBold"
				textAlign="center"
				maxW="144px"
			>
				{title}
			</Heading>
		</HealthWrapper>
	);
};

export default HealthConcernItem;
