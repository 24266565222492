import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import { Waypoint } from 'react-waypoint';

// Types
import { TContentfulTestimonialSllider } from 'src/utils/types';

// Utils

// Atoms
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { Center } from 'fatcat-ui-library/components/Atoms/Center';
import { Button } from 'fatcat-ui-library/components/Atoms/Button';
import { SliderWrapper } from './ContentfulTestimonialSllider.atoms';
import { Heading } from 'fatcat-ui-library/components/Atoms/Heading';
import { Paragraph } from 'fatcat-ui-library/components/Atoms/Paragraph';

// Assets
import Ratings from 'fatcat-ui-library/components/Molecules/Ratings';
import { LuChevronLeft, LuChevronRight } from 'react-icons/lu';
import { Icon } from 'fatcat-ui-library/components/Atoms/Icon';

const ContentfulTestimonialSllider: React.FC<TContentfulTestimonialSllider> = (props) => {
	const { testimonialsItems } = props;
	const [animated, setAnimated] = useState(false);
	const disabled = testimonialsItems.length <= 2;

	const updateOnBreakpoint = (swiper: any) => {
		swiper.navigation.destroy();
		swiper.navigation.init();
		swiper.navigation.update();
	};
	return (
		<Waypoint
			onEnter={() => {
				setAnimated(true);
			}}
		>
			<SliderWrapper
				maxW="950px"
				animation={animated ? ['fadeInFromBottom', '1s ease-in-out forwards'] : undefined}
				paddingLeft="s32"
				paddingRight="s32"
			>
				<Swiper
					modules={[Navigation, Autoplay]}
					watchOverflow
					allowTouchMove={!disabled}
					slidesPerView={1}
					slidesPerGroup={1}
					speed={1000}
					loop
					onBreakpoint={updateOnBreakpoint}
					onInit={updateOnBreakpoint}
					breakpoints={{
						900: {
							slidesPerView: 2,
							slidesPerGroup: 2,
						},
					}}
					autoplay={!disabled && {
						delay: 10000,
						pauseOnMouseEnter: true,
						disableOnInteraction: false,
					}}
					navigation={{
						enabled: true,
						prevEl: '.swiper-button-testimonial-prev',
						nextEl: '.swiper-button-testimonial-next',
					}}
				>
					{testimonialsItems.map((item, index) => (
						<SwiperSlide key={index}>

							<Flex
								key={index}
							>
								<Flex
									direction="column"
									h="100%"
								>
									<Flex
										marginBottom="s16"
										justifyContent="flex-start"
									>
										<Heading
											as="h3"
											textAlign="left"
										>
											&quot;
											{item.content}
											&quot;
										</Heading>
									</Flex>
									<Ratings
										rating={5}
										size="12px"
										maxRating={5}
										gap="4px"
									/>
									<Paragraph
										textAlign="left"
										size="small"
										marginTop="s8"
									>
										{item.name}
									</Paragraph>
									<Paragraph
										textAlign="left"
										textColor="secondary"
										size="small"
									>
										{item.role}
									</Paragraph>
								</Flex>
							</Flex>
						</SwiperSlide>
					))}
				</Swiper>
				{!disabled && (
					<Center w="fit-content" marginTop="s28" gap="26px">
						<Button
							variant="noStyle"
							h="fit-content"
							w="fit-content"
							aria-label="previous slide"
							className="nav-slider-button swiper-button-testimonial-prev"
							hover={[{ opacity: '0.7' }]}
						>
							<Icon as={LuChevronLeft} w="24px" h="auto" />

						</Button>
						<Button
							variant="noStyle"
							h="fit-content"
							w="fit-content"
							aria-label="next slide"
							className="nav-slider-button swiper-button-testimonial-next"
							hover={[{ opacity: '0.7' }]}
						>
							<Icon as={LuChevronRight} w="24px" h="auto" />

						</Button>
					</Center>
				)}
			</SliderWrapper>
		</Waypoint>
	);
};

export default ContentfulTestimonialSllider;
