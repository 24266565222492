import React from 'react';

// Types
import { TContentfulLink } from 'src/utils/types';

// Atoms
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { TitleWrapper } from './NavigationMobileDropdown.atom';
import ContentfulLink from 'src/contentful/ContentfulLink';

const NavigationMobileDropdown: React.FC<TContentfulLink> = (props) => {
	const {
		title,
		items,
		onChange,
	} = props;

	return (
		<Flex
			direction="column"
		>
			{/* eslint-disable-next-line react/destructuring-assignment */}
			{(props.url || props.page) && title !== 'COMPANY' && (
				<TitleWrapper
					alignItems="center"
					justifyContent="space-between"
				>
					<ContentfulLink {...props} linkColor="primary" />
				</TitleWrapper>
			)}
			{title === 'PRODUCTS' && (
				<Flex direction="column" gap="8px">
					{items && items.map(link => <ContentfulLink key={link.title} {...link} onChange={onChange} buttonSize="nav" fontWeight="medium" />)}
				</Flex>
			)}
			{title !== 'PRODUCTS' && (
				<Flex direction="column" gap="8px">
					{items && items.map(link => <ContentfulLink key={link.title} {...link} onChange={onChange} buttonSize="nav" uppercase linkColor="primary" />)}
				</Flex>
			)}
		</Flex>
	);
};

export default NavigationMobileDropdown;
