import React, { useMemo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';

// Types
import { TContentfulProductsSlider } from 'src/utils/types';
import { TProductGatsbyImage } from 'src/components/Sections/Product';

// Utils

// Atoms
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import ProductCard from 'src/components/Molecules/Cards/ProductCardV2';
import ContentfulHeading from '../ContentfulHeading';
import ContentfulText from '../ContentfulText';
import ContentfulLink from '../ContentfulLink';
import { SliderWrapper } from './ContenfulProductsSlider.atoms';
import { Button } from 'fatcat-ui-library/components/Atoms/Button';

// Assets
import { useGetAvailableQuantity } from 'src/utils/hooks/useGetAvailableQuantity';
import { Icon } from 'fatcat-ui-library/components/Atoms/Icon';
import { LuChevronLeft, LuChevronRight } from 'react-icons/lu';

const ContentfulProductsSlider: React.FC<TContentfulProductsSlider> = (props) => {
	const {
		sliderTitle, sliderText, productsLink, removeProductId,
	} = props;

	const { getAllOrderedByWebsiteCarouselOrder, loading } = useGetAvailableQuantity();
	const [isNavigationVisible, setIsNavigationVisible] = React.useState(false);

	const products = useMemo(() => loading ? [] : getAllOrderedByWebsiteCarouselOrder(), [loading, getAllOrderedByWebsiteCarouselOrder]);

	const showProducts = products
		.filter(product => product.id !== removeProductId)
		.slice(0, 6);

	const updateOnBreakpoint = (swiper: any) => {
		swiper.navigation.destroy();
		swiper.navigation.init();
		swiper.navigation.update();
		setIsNavigationVisible(showProducts.length > 4);
	};

	return (
		<SliderWrapper>
			<Flex
				direction="column"
			>
				{sliderTitle && (
					<ContentfulHeading
						{...sliderTitle}
						largeTablet={['textCenter']}
						tablet={[{ maxW: '450px' }]}
					/>
				)}
				{sliderText && (
					<ContentfulText
						{...sliderText}
						textSize="paragraph"
						marginBottom="s32"
						largeTablet={['textCenter']}
					/>
				)}

			</Flex>
			<div className="swiper-container">
				<Swiper
					modules={[Autoplay, Pagination, Navigation]}
					allowTouchMove
					itemID="products-slider"
					id="products-slider"
					speed={700}
					loop
					onBreakpoint={updateOnBreakpoint}
					onInit={updateOnBreakpoint}
					autoplay={{
						delay: 10000,
						pauseOnMouseEnter: true,
						disableOnInteraction: false,
					}}
					pagination={{
						clickable: true,
						el: '.swiper-custom-pagination',
						renderBullet: (_, className) => `<span class="${className}"></span>`,
					}}
					lazyPreloadPrevNext={4}
					navigation={{
						enabled: true,
						prevEl: '.swiper-button-prev',
						nextEl: '.swiper-button-next',
					}}
					breakpoints={{
						1120: {
							slidesPerView: 4,
							slidesPerGroup: 1,
						},
						0: {
							slidesPerView: 3,
							slidesPerGroup: 1,
						},
					}}
				>
					{showProducts.map((item: TProductGatsbyImage) => (
						<SwiperSlide key={item.id}>
							<Flex
								w="auto"
								paddingX="s16"
								paddingY="s20"
								h="calc(100% - 40px)"
							>
								<ProductCard {...item} inSwiper haveAddButton={false} />
							</Flex>
						</SwiperSlide>
					))}
				</Swiper>
			</div>
			{isNavigationVisible && (
				<Flex
					className="pag-nav-container"
					direction="column"
					alignItems="center"
				>
					<Flex
						position="relative"
						h="30px"
						justifyContent="space-around"
						alignItems="center"
						w="auto"
						marginBottom="s24"
						mobile={['fluid']}
						gap="24px"
					>
						<Button
							variant="noStyle"
							h="fit-content"
							w="fit-content"
							marginTop="s0"
							top="0"
							aria-label="previous slide"
							className="nav-slider-button swiper-button-prev"
							hover={[{ opacity: '0.7' }]}
						>
							<Icon as={LuChevronLeft} w="24px" h="auto" />

						</Button>
						<Flex
							marginBottom="s32"
							largeTablet={['justifyContentCenter']}
							className="swiper-custom-pagination"
						/>
						<Button
							variant="noStyle"
							h="fit-content"
							w="fit-content"
							marginTop="s0"
							top="0"
							aria-label="next slide"
							className="nav-slider-button swiper-button-next"
							hover={[{ opacity: '0.7' }]}
						>
							<Icon as={LuChevronRight} w="24px" h="auto" />

						</Button>
					</Flex>

				</Flex>
			)}
			{productsLink && (
				<Flex
					marginBottom="s32"
					marginTop="s24"
					textSize="paragraph"
					fontWeight="semiBold"
					justifyContent="center"
				>
					<ContentfulLink {...productsLink} />
				</Flex>
			)}
		</SliderWrapper>
	);
};

export default ContentfulProductsSlider;
