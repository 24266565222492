import { Text } from 'fatcat-ui-library/components/Atoms/Text';
import styled from 'styled-components';
import { Wrapper } from 'fatcat-ui-library/components/Atoms/Wrapper';
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { CTAWrapper } from '../ContentfulCallToAction/CallToAction.atoms';
import { ButtonBase } from 'fatcat-ui-library/components/Atoms/Button/Button';
import { Grid } from 'fatcat-ui-library/components/Atoms/Grid';
import { BlockWrapper } from '../ContentfulSplitBlock/ContentfulSplitBlock.atom';
import { RenderWrapper } from '../RichText/RichText.atom';

export const Tab = styled(Text)`
    display: block;
    position: relative;
    font-weight: 600;
    font-size: 20px;
    color: ${({ theme }) => theme.color.lightGray};
    cursor: pointer;

    ${props => props.theme.media.largeTablet} {
        display: none;
    }

    &:hover {
        color: ${({ theme }) => theme.color.primary};
    }

    &.active {
        color: ${({ theme }) => theme.color.black};
        display: block;
        &:after {
            content: '';
            position: absolute;
            bottom: -0.2em;
            left: 0;
            right: 0;
            height: 4px;
            background-color: ${({ theme }) => theme.color.primary};
            border-radius: 2px;
        }
    } 
`;

export const SwiperWrapper = styled(Wrapper)`
    position: relative;
    padding-bottom: 20px;
    
    .swiper-pagination {
        display: none;
        position: relative;
    }

    ${props => props.theme.media.largeTablet} {
        .swiper {
            width: 100%;
        }
        .swiper-slide {
            display: flex;
            justify-content: center;
        }

        .swiper-pagination {
            display: block;
        }
    }

    .swiper-pagination-bullet {
        background-color: ${props => props.theme.color.lightGray};
        height: 4px;
        width: 4px;
        margin: 0 2.5px !important;
    }

    .swiper-pagination-bullet-active {
        background-color: ${props => props.theme.color.primary};
        width: 20px;
        border-radius: 20px;
    }
    
    .swiper-navigation {
        position: absolute;
        bottom: 8px;
        cursor: pointer;
        font-size: 18px;
        svg {
            vertical-align: middle;
            margin: 0 4px;
        }


        z-index: 20;

        &.swiper-button-disabled {
            color: ${props => props.theme.color.darkerGrey};
        }

        &:not(.swiper-button-disabled):hover {
            color: ${props => props.theme.color.primary};
        }
    }

    .swiper-navigation-left {
        left: 0;
    }

    .swiper-navigation-right {
        right: 0;
    }

    .swiper-slide > div {
        margin-bottom: 32px;
    }
`;

export const SwiperWrapperCarousel = styled(SwiperWrapper)`
    display: flex;
    flex: 1;
`;

export const CarouselWrapper = styled(Flex)`
    width: 100%;

    * {
        color: ${({ theme }) => theme.color.white};
        text-align: left !important;
    }

    ${props => props.theme.media.largeTablet} {
        ${RenderWrapper} {
            > ${Flex} {
                flex-direction: row;
            }

            ${BlockWrapper} {
                &[reversed] {
                    order: 2;
                }

                width: 50%;
    
                ${CTAWrapper} {
    
                    ${Flex} {
                        align-items: flex-start;
                    }
    
                    h2 {
                        font-size: 2.6rem;
                    }
                }
            }
            ${BlockWrapper}[reversed]:last-child {
    
                order: 1;
            }
        }
    }

    ${props => props.theme.media.tablet} {
        ${BlockWrapper} {
            ${CTAWrapper} {
                h2 {
                    font-size: 2rem;
                }
            }
        }
    }
    ${props => props.theme.media.mobile} {
        ${RenderWrapper} {
            justify-content: flex-start;

            > hr {
                display: none;
            }

            > ${Flex} {
                flex-direction: column;
                gap: 0;

                ${BlockWrapper} {
                    &:first-child {
                        padding: 20px 20px 0 20px;
                        box-sizing: border-box;
                    }

                    width: 100%;

                    ${CTAWrapper} {
                        h2 {
                            font-size: 2rem;
                        }
                    }
                }
            }

            ${Grid} {
                grid-template-columns: auto auto;
                margin-bottom: 200px;
            }
        }
    }

    ${CTAWrapper} {

        
        h2 {
            font-size: 3rem;
            line-height: normal;
        }

        ${ButtonBase} {
            background-color: ${({ theme }) => theme.color.white};
            border: none;
            color: ${({ theme }) => theme.color.black};

            &:hover {
                color: ${({ theme }) => theme.color.black};
                box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5);
            }
        }
    }

    ${Grid} {
        h3 {
            font-size: 12px;
        }

        img {
            width: 32px;
        }
        
        > ${Flex} {
            gap: 10px;
        }

    }
`;
