import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import styled, { css } from 'styled-components';

export const CTAWrapper = styled(Flex) <{ stickToTheEdges: boolean, alignCenter: boolean, haveBottomPadding:boolean, whiteBackground: boolean}>`
	${props => props.theme.media.largeTablet}{
		padding-top: 0;
		margin-bottom:0;
		padding-bottom: 0px;
	}

	${props => props.alignCenter && css`
		justify-content: center;
		align-items: center;
	`}

	${props => props.whiteBackground && css`
		background-color: rgba(255,255,255,0.8);
		padding: 32px;
		border-radius: 16px;
		margin-bottom: 120px !important;
		backdrop-filter: blur(10px);

		br {
			display: block !important;
		}

		${props => props.theme.media.largeTablet} {
			border-radius: 0;
			margin-bottom: 0px !important;
			backdrop-filter: none;
		}


		width: 100%;
		${props => props.theme.media.largeTablet} {
			width: 100%;
		}
		
		${InnerCTAWrapper} {
			margin-left: 0;
			max-width: 100%;
			
			h1 {
				font-size: 48px;
			}
		}	
	`}

	${props => props.stickToTheEdges && css`
		padding-left: ${props => props.theme.space.s40};

		${props => props.theme.media.largeTablet}{
			padding-left: ${props => props.theme.space.s32};
			padding-right: ${props => props.theme.space.s32};
		}

		${props => props.theme.media.mobile}{
			padding-left: ${props => props.theme.space.s20};
			padding-right: ${props => props.theme.space.s20};
		}
	`}
	${props => props.haveBottomPadding && css`
		padding-bottom: 120px;
	`}
`;

export const InnerCTAWrapper = styled(Flex) <{ alignCenter: boolean }>`
    flex-direction: column;
	margin-left: auto;
	${props => props.theme.media.largeTablet}{
		margin-left: 0px;
	}

	${props => props.alignCenter && css`
		${props => props.theme.media.largeTablet}{
			align-items: center;
			* {
				text-align: center !important;
			}

		}
	`}
`;
