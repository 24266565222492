import React from 'react';

// Types

// Atoms
import { TContentfulText } from 'src/utils/types';
import { Paragraph } from 'fatcat-ui-library/components/Atoms/Paragraph';

const ContentfulText: React.FC<TContentfulText> = (props) => {
	const {
		text,
		...restProps
	} = props;
	return (
		<Paragraph {...restProps}>
			{text === '<br />' ? <br /> : text}
		</Paragraph>
	);
};
export default ContentfulText;
