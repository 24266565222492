import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { createGlobalStyle } from 'styled-components';

export const HTMLStyle = createGlobalStyle`
	html, body {
		color: ${props => props.theme.textColor.primary};
	}
	a, button {
		transition: all 0.2s ease-in-out;
	}
	* {
		font-family: ${props => props.theme.fontFamily.main};
	}
	body.html-block-scrolling {
		${props => props.theme.media.largeTablet} {
			overflow: hidden;
		}
	}

	body.html-modal-scrolling {
		overflow: hidden;
	}

	a, button, [role="button"], [role="link"] {
		&:focus-visible {
			outline: 2px solid ${props => props.theme.linkColor.navigation.hover};
		}
	}

	.react-modal-portal .modal {
		position: absolute;
		top: 80px;
		left: 50%;
		right: auto;
		transform: translate(-50%);
		border: none;
	}


	.react-modal-portal .modal.image-expand-modal {
		left: 100px;
		right: 100px;
		transform: translate(0%);

		${Flex} {
			justify-content: center;

			img {
				max-height: calc(100vh - 268px);
				width: 100%;
				object-fit: cover;
			}
		}
	}


	.react-modal-portal .overlay {
		position: fixed;
		inset: 0;
		background-color: rgba(0, 0, 0, 0.5);
		overflow: auto;
		z-index: 1000;
	}

	.react-modal-portal .modal.create-review {
		min-width: 600px;
	}	

	${props => props.theme.media.desktop} {
		.react-modal-portal .modal {
			max-width: 992px;
		}

		.react-modal-portal .modal.image-expand-modal {
			max-width: 100%;
			left: 20px;
			right: 20px;

			${Flex} {
				height: calc(100vh - 140px);

				img {
					max-height: calc(100vh - 268px);
				}

				${props => props.theme.media.tablet} {
					height: calc(100vh - 60px);

					img {
						max-height: calc(100vh - 188px);
					}
				}
			}

			.react-transform-wrapper {
				height: 100%;
			}

			${props => props.theme.media.mobile} {
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;

				${Flex} {
					border-radius: 0;
					height: 100%;

					img {
						max-height: calc(100vh - 64px);
					}
				}
				
			}
		}

			
	}

	${props => props.theme.media.largeTablet} {
		.react-modal-portal .modal {
			max-width: 480px;
			min-width: 450px;
			bottom: -340px;
			top: 32px;
			transform: translateX(-50%);
			margin-right: 0;
		}


		.react-modal-portal .modal.create-review {
			min-width: calc(100% - 60px);
		}	
	}

	${props => props.theme.media.mobile} {
		.react-modal-portal .modal{
			min-width: auto;
			bottom: -200px;
			left: 15px;
			right: 15px;
			transform: none;
		}

	}
`;
