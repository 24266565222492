import React from 'react';

// Font Awesome

// Atoms
import { Heading } from 'fatcat-ui-library/components/Atoms/Heading';
import { Wrapper } from 'fatcat-ui-library/components/Atoms/Wrapper';
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { Paragraph } from 'fatcat-ui-library/components/Atoms/Paragraph';
import { Icon } from 'fatcat-ui-library/components/Atoms/Icon';
import { PopupMessageAnimation } from './PopupMessage.atoms';
import { LuX } from 'react-icons/lu';

type TPopupMessage = {
	title: string,
	description?: string,
	setIsActive: (active: boolean) => void
}

const PopupMessage: React.FC<TPopupMessage> = (props) => {
	const {
		title, description, setIsActive,
	} = props;

	return (
		<>
			<Wrapper
				position="fixed"
				left="0"
				top="0"
				w="100vw"
				h="100vh"
				backgroundColor="white"
				opacity="0.1"
			/>
			<PopupMessageAnimation>
				<Flex
					w="500px"
					h="auto"
					backgroundColor="white"
					direction="column"
					alignItems="center"
					borderRadius="12px"
					gap="24px"
					boxShadow="0 0 20px 0px rgb(132, 105, 105, 0.15)"
					position="fixed"
					left="50%"
					top="50%"
					transform="translate(-50%, -50%)"
					zIndex="999"
					padding={['b48']}
					tablet={[
						{ w: '400px' },
					]}
					mobile={[
						{ w: '270px' },
						{ gap: '20px' },
					]}
				>
					<Flex
						justifyContent="flex-end"
						padding={['t16', 'r16']}
						onClick={() => setIsActive(false)}
					>
						<Wrapper
							w="20px"
							h="20px"
						>
							<Icon
								as={LuX}
								hover={['pointer', { textColor: 'secondary' }]}
								textColor="primary"
								fontSize="s24"
							/>
						</Wrapper>
					</Flex>
					<Heading
						as="h3"
						fontWeight="bold"
						textAlign="center"
						padding={['l64', 'r64']}
						mobile={[
							{ padding: ['l20', 'r20'] },
						]}
					>
						{title}
					</Heading>
					{description && (
						<Paragraph
							textAlign="center"
							padding={['l80', 'r80']}
							mobile={[
								{ textSize: 'text16' },
								{ padding: ['l64', 'r64'] },
							]}
						>
							{description}
						</Paragraph>
					)}
				</Flex>
			</PopupMessageAnimation>
		</>
	);
};

PopupMessage.defaultProps = {
	description: '',
};

export default PopupMessage;
