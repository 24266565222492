import React from 'react';

// Types
import { TContentfulAppBanner } from '../../utils/types';

// Atoms
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { Paragraph } from 'fatcat-ui-library/components/Atoms/Paragraph';
import ContentfulLink from '../ContentfulLink';
import ContentfulImage from '../ContentfulImage';
import ContentfulHeading from '../ContentfulHeading';
import SplitText from '../SplitText/SplitText';
import ContentfulLottiePlayer from '../ContentfulLottiePlayer';

// Utils
import { trackAction } from 'src/utils/gtag';

const ContentfulSplitBlock: React.FC<TContentfulAppBanner> = (props) => {
	const {
		bannerTitle,
		bannerDescription,
		bannerText,
		bannerImage,
		appLinks,
		lottiePlayer,
		callToAction,
	} = props;
	const image = {
		...bannerImage,
		gatsbyImageData: {
			...bannerImage.gatsbyImageData,
			height: bannerImage.gatsbyImageData.height / 2,
			width: bannerImage.gatsbyImageData.width / 2,
		},
	};
	return (
		<Flex
			largeTablet={['column']}
			gap="40px"
		>
			<Flex
				padding={['t64', 'b24']}
				largeTablet={['justifyContentCenter', { w: '100%', paddingY: 's0' }]}
				w="50%"
			>
				<Flex
					justifyContent="center"
					direction="column"
				>
					<ContentfulHeading {...bannerTitle} largeTablet={['textCenter']} />
					{bannerDescription && (
						<Paragraph
							margin={['b32']}
							largeTablet={['textCenter']}
						>
							{SplitText(bannerDescription.bannerDescription)}
						</Paragraph>
					)}
					<Paragraph
						fontSize="s24"
						margin={['b24']}
						largeTablet={['textCenter']}
						tablet={[{ fontSize: 's20' }]}
					>
						{bannerText}
					</Paragraph>
					<Flex
						margin={['b40']}
						largeTablet={['justifyContentCenter']}
					>
						{appLinks.map((appLink, index) => (
							<Flex
								key={appLink.title}
								marginRight={index === 0 ? 's32' : 's0'}
								w="auto"
							>
								<ContentfulLink {...appLink} onChange={() => trackAction(appLink.title, appLink.url)} />
							</Flex>
						))}
					</Flex>
					{callToAction && (
						<Flex
							largeTablet={['justifyContentCenter', { marginBottom: 's24' }]}
						>
							<ContentfulLink {...callToAction} onChange={() => trackAction(callToAction.title, callToAction.url)} />
						</Flex>
					)}
				</Flex>
			</Flex>
			<Flex
				justifyContent="right"
				largeTablet={['justifyContentCenter', { w: '100%' }]}
				w="50%"
			>
				{!lottiePlayer && <ContentfulImage objectFit="contain" {...image} />}
				{lottiePlayer && <ContentfulLottiePlayer {...lottiePlayer} />}
			</Flex>
		</Flex>
	);
};
export default ContentfulSplitBlock;
